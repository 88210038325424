import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { REPORT_ERROR_STATUSES, LAUNCHED_SEARCH_FROM, QUERY_SEARCH_TYPES } from '@constants';
import { DefaultCheckbox } from '@reusable/Table/components/defaults';
import utils from '@utils/utilities';
import searchSagaActions from '@pages/MainSearch/redux/searchSaga.actions';
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import formatRichMessage from '@utils/formatRichMessage';
import { isEmpty } from 'lodash';

export class EntityNameTemplate extends Component {
    getCompatibleCell(uncertainCell) {
        const text = uncertainCell.text;
        return { ...uncertainCell, text };
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
    }

    hasCellError = (reportDeliveryStatus) => {
        switch (reportDeliveryStatus) {
            case REPORT_ERROR_STATUSES.ERROR:
            case REPORT_ERROR_STATUSES.ERROR_REPORT_TOO_BIG:
            case REPORT_ERROR_STATUSES.ALL_FINISHED_WITH_ERRORS:
            case REPORT_ERROR_STATUSES.ZIP_GENERATED_WITH_ERRORS:
            case REPORT_ERROR_STATUSES.ZIP_NOT_GENERATED:
                return true;
            default:
                return false;
        }
    };

    render(cell) {
        const {
            entityName,
            displayName,
            prefilterQuery,
            handleToggleSelect,
            selected,
            id,
            extraProps,
            reportDeliveryStatus,
            intl,
            isReady,
            failed,
            searchQueryType,
            counts,
        } = cell.text;
        const { text } = cell;
        const { dispatch, updateEntityNameProps, blockedEntities, entityOwner } = extraProps;
        const { updatedEntities, openEditSearch, entityChangesSaved } = updateEntityNameProps;
        const { formatMessage } = intl;
        const displayEntityName = entityName === displayName ? entityName : displayName;
        const entityNameToUpdate = !prefilterQuery.length ? entityName : `${entityName} AND ${prefilterQuery}`;
        const newDisplayName = updatedEntities[id] || '';
        const displayedEntityName = newDisplayName || displayEntityName;
        const showSavedIcon = entityChangesSaved === id;

        const goToSearchResults = async () => {
            let payload = {
                searchQuery: entityName,
                prefilterQuery: utils.setCorrectPrefilterQuery(prefilterQuery),
                launchedFrom: LAUNCHED_SEARCH_FROM.SCREENING_PAGE,
                entityId: id,
                checkPreferencesAreObsolete: () => null,
                currentEntity: text,
                entityOwner: entityOwner
            };

            dispatch(searchSagaActions.runSearch(payload));
        };

        const convertUnixTime = (date) => moment.utc(date).valueOf();

        const dateCreated = cell.text.dateCreated
            ? utils.formatDateWithHoursAndMinutes(convertUnixTime(cell.text.dateCreated))
            : '-';
        const lastUpdated = cell.text.lastUpdated
            ? utils.formatDateWithHoursAndMinutes(convertUnixTime(cell.text.lastUpdated))
            : '-';

        const term = `${entityName}${prefilterQuery.length ? ` AND ${prefilterQuery}` : ''}`;
        const EntityNameTooltipContent = ReactDOMServer.renderToString(
            <div className="batch-screening-entity-name-tooltip-text">
                <p>
                    <b>{formatMessage({ id: 'BatchUpload.example.table.headers.displayName' })}: </b>
                    {newDisplayName || displayEntityName}
                </p>
                <p>
                    <b>{formatMessage({ id: 'PostFilters.Title.Term' })}: </b>
                    {term}
                </p>
                <p>
                    <b>{formatMessage({ id: 'ManageAlertsTableHeader.created' })}: </b>
                    {dateCreated}
                </p>
                <p>
                    <b>{formatMessage({ id: 'ManageAlertsTableHeader.lastUpdated' })} </b>
                    {lastUpdated}
                </p>
            </div>
        );

        const hasCellError = this.hasCellError(reportDeliveryStatus);

        const entityBlocked = (blockedEntities?.includes(id) && !isReady && isEmpty(counts)) || (isReady && failed);

        const entityBlockedPopupText = formatRichMessage({ id: 'BatchScreening.pollingError.entityBlocked' });

        return (
            <div className="grid-table-entity-name-template">
                <DefaultCheckbox onToggleSelect={handleToggleSelect} item={text} checked={selected} value={id} />
                {
                    <div
                        className="batch-screening-entity-name-container"
                        onClick={goToSearchResults}
                        onMouseEnter={() => {
                            ReactTooltip.rebuild();
                        }}
                    >
                        <div
                            className={`batch-screening-entity-name ${entityBlocked ? 'blocked' : ''}`}
                            data-tip={EntityNameTooltipContent}
                            data-for="grid-table-tooltip"
                            data-html={true} 
                        >
                            {newDisplayName || displayEntityName}
                        </div>
                    </div>
                }
                {entityBlocked && (
                    <>
                        <img
                            className="entity-blocked-icon"
                            src="/assets/ErrorIconRed.svg"
                            alt="error-icon"
                            data-tip={entityBlockedPopupText}
                            data-for="entity-blocked-tooltip"
                        />
                        <ReactTooltip
                            id="entity-blocked-tooltip"
                            type="light"
                            event="mouseenter"
                            eventOff="mouseleave"
                            border={true}
                            effect="solid"
                            className="tooltips entity-blocked-tooltip"
                            place="right"
                        />
                    </>
                )}
                {hasCellError && (
                    <span
                        className="batch-screening-error-icon la-AlertIcon"
                        data-tip={formatMessage({ id: 'BatchScreening.reportFailed.message' })}
                        data-for="grid-table-entity-name-tooltip-error"
                    />
                )}
                {showSavedIcon && <span className="changes-saved" />}
                {!showSavedIcon && isReady && (
                    <span
                        className="la-Edit"
                        data-testid={`edit-entity-name-icon-${id}`}
                        onClick={() =>
                            openEditSearch(
                                id,
                                displayedEntityName,
                                entityNameToUpdate,
                                QUERY_SEARCH_TYPES[searchQueryType]
                            )
                        }
                    />
                )}
            </div>
        );
    }
}
