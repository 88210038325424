/**
 * MAIN COMPONENT REDUX REDUCERS
 *
 * The main component reducer combines the reducers from all modules.
 *
 * @version 0.1
 */

import { combineReducers } from 'redux';
import { cloneDeep, find, findIndex, includes, isEmpty, isNil, keys, merge, pickBy } from 'lodash';
import header from '@reusable/Header/Header.reducers';
import popupMessages from '@reusable/MessagePopup/redux/MessagePopup.reducers';
import searchStatus from '@pages/MainSearch/redux/SearchStatus.reducers';
import breadcrumbs from '@reusable/Breadcrumbs/redux/Breadcrumbs.reducers';
import reportBuilder from '@pages/ReportBuilder/redux/ReportBuilder.reducers';
import backgroundMessages from '@reusable/BackgroundMessage/redux/BackgroundMessages.reducers';
import searchParams from '@MainSearch/redux/SearchParams.reducers';
import searchResults from '@MainSearch/redux/SearchResults.reducers';
import currentReport from '../ReportBuilder/redux/CurrentReport.reducers';
import articlesManager from '@MainSearch/redux/ArticlesManager.reducers';
import adHocSearch from '@reusable/AdHocSearch/redux/AdHocSearch.reducers';
import searchBar from '@reusable/SearchBar/redux/SearchBar.reducers';
import alerts from '../ManageAlerts/redux/ManageAlerts.reducers';
import selectedAlerts from '../ManageAlerts/redux/ManageSelectedAlerts.reducers';
import scrollingPage from './ScrollingPage.reducers';
import articleNavigation from '@MainSearch/redux/ArticleNavigation.reducers';
import ubo from '@StartPage/redux/Ubo.reducers';
import {
    RESET_CURRENT_ENTITY,
    RESET_LAUNCH_FROM,
    SEARCH_LAUNCHED_FROM,
    SET_ALL_ENTITIES_SELECTED,
    SET_CURRENT_ENTITY,
    SET_ENTITY_ID,
    SET_ENTITY_VIEW_COUNT,
    SET_ENTITY_VIEW_DOWNLOAD_REPORTS_POLLING,
    SET_ENTITY_VIEW_TABLE_HAS_DATA,
    SET_SHOULD_GET_ALL_VIEWS,
    SET_SNAPSHOT_DELIVERY,
    SET_UPLOAD_ENTITY_VIEW_POLLING,
    SHOULD_UPDATE_ENTITIES_COLUMNS_FROM_CONTENT_TYPES,
    UPDATE_BATCH_QUEUE,
    UPDATE_BLOCKED_ENTITIES,
    USER_SET_ENTITY_VIEW_INFORMATION,
    UPDATE_ENTITIES_ID_QUEUE,
    CLOSE_ALL_NOTIFICATIONS,
    CLOSE_NOTIFICATION,
    UPDATE_BATCH_ID,
    RESET_NOTIFICATION,
    ADD_NEW_BATCH_ID,
    SANITIZE_NOTIFICATIONS,
    UPDATE_NOTIFICATION_DISPLAY_ORDER,
} from './Main.actions';

import {
    ADD_ADMIN_PREFS_CUSTOM_NEWS_QUERY,
    ADD_COST_CODE_TO_ADMIN_LIST,
    ADD_USER_PREFS_CUSTOM_NEWS_QUERY,
    ADMIN_DISCARD_CHANGES,
    ADMIN_GET_FUZZY_MATCH,
    ADMIN_IS_FUZZY_ENABLED,
    ADMIN_IS_FUZZY_ON,
    ADMIN_IS_SUGGESTED_NAMES_ON,
    ADMIN_PREFERENCES_ADD_CONTENT_TYPE,
    ADMIN_PREFERENCES_REMOVE_CONTENT_TYPE,
    ADMIN_PREFERENCES_UPDATE_SHOW_SNAPSHOT,
    ADMIN_UPDATE_CONTENT_OPTIONS_REPORT_BUILDER,
    ADMIN_UPDATE_CONTENT_OPTIONS_RESULTS,
    ADMIN_UPDATE_EXCLUDE_NEWS_WIRES,
    ADMIN_UPDATE_EXCLUDE_NON_BUSINESS_NEWS,
    ADMIN_UPDATE_FUZZY_THRESHOLD,
    ADMIN_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT,
    ADMIN_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE,
    CHANGE_ADMIN_DATE_RANGE,
    CHANGE_ADMIN_LEGAL_SOURCE,
    CHANGE_ADMIN_PROXIMITY,
    CHANGE_ADMIN_QUERY_COMPANY,
    CHANGE_ADMIN_QUERY_PERSON,
    CHANGE_DATE_RANGE,
    CHANGE_LEGAL_SOURCE,
    CHANGE_PROXIMITY,
    CHANGE_QUERY_COMPANY,
    CHANGE_QUERY_PERSON,
    DELETE_ADMIN_CUSTOM_NEWS_QUERY,
    DELETE_COST_CODE_FROM_ADMIN_LIST,
    DELETE_CUSTOM_NEWS_QUERY,
    EDIT_ADMIN_PREFS_CUSTOM_NEWS_QUERY,
    EDIT_USER_PREFS_CUSTOM_NEWS_QUERY,
    EDIT_USER_PREFS_LN_CUSTOM_NEWS_QUERY,
    HIDE_LOGIN_HISTORY_BANNER,
    PREFERENCES_UPDATE_SHOW_SNAPSHOT,
    REMOVE_FUZZY_NAME,
    RESET_FUZZY_NAMES,
    SET_USER_PREFERENCES,
    SHOULD_UPDATE_TIMESTAMP_INFO,
    TOGGLE_ALL_FUZZY_SELECTION,
    TOGGLE_DELIVERY_OPTIONS_DISPLAY_PREFERENCE,
    TOGGLE_FUZZY_SELECTION,
    TOGGLE_MODIFIED_BY_USER,
    TOGGLE_USE_NEW_RESEARCH_SUMMARY,
    UPDATE_ADMIN_CHECKED_LANGUAGE,
    UPDATE_ADMIN_CHECKED_LANGUAGES_UPON_KEY,
    UPDATE_ADMIN_COMPANY_NEGATIVITY_LEVEL,
    UPDATE_ADMIN_COMPANY_SOURCES_SEARCH_OPTION,
    UPDATE_ADMIN_COST_CODE_IS_REQUIRED,
    UPDATE_ADMIN_COST_CODE_ONLY_FROM_COST_CODE_LIST,
    UPDATE_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE,
    UPDATE_ADMIN_LEGAL_SEARCH_NAME_PARTY_ENTITY,
    UPDATE_ADMIN_NEWS_SOURCES_SELECTION,
    UPDATE_ADMIN_PERSON_NEGATIVITY_LEVEL,
    UPDATE_ADMIN_PREFERENCES_CUSTOM_QUERY,
    UPDATE_ADMIN_PREFS_IS_DIRTY,
    UPDATE_BATCH_CONTENT_TYPES_VISIBILITY,
    UPDATE_BATCH_SCREEN_PREFS,
    UPDATE_CHECKED_LANGUAGE,
    UPDATE_CHECKED_LANGUAGES_UPON_KEY,
    UPDATE_COMPANY_NEGATIVITY_LEVEL,
    UPDATE_COMPANY_SOURCES_SEARCH_OPTION,
    UPDATE_CONTENT_OPTIONS_REPORT_BUILDER,
    UPDATE_CONTENT_OPTIONS_RESULTS,
    UPDATE_CUSTOM_NEWS_QUERY_CHECKED_STATE,
    UPDATE_EXCLUDE_NEWS_WIRES,
    UPDATE_EXCLUDE_NON_BUSINESS_NEWS,
    UPDATE_FILTER_SECTION_VISIBILITY,
    UPDATE_FROM_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE,
    UPDATE_FUZZY_NAMES,
    UPDATE_GENERAL_PAGE_SIZE,
    UPDATE_LAST_PREFERENCES_RETRIEVAL_TIMESTAMP,
    UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY,
    UPDATE_LN_CUSTOM_NEWS_QUERY_CHECKED_STATE,
    UPDATE_LOCKS,
    UPDATE_NEWS_SOURCES_SELECTION,
    UPDATE_PAGE_SIZE,
    UPDATE_PERSON_NEGATIVITY_LEVEL,
    UPDATE_PREFS_NOTICE,
    UPDATE_RISK_SCORES,
    UPDATE_STYLING_THEME,
    UPDATE_USER_COST_CODE,
    UPDATE_USER_PREFERENCES_CUSTOM_QUERY,
    UPDATE_USER_PREFERENCES_PAYLOAD,
    USER_GET_FUZZY_MATCH,
    USER_IS_FUZZY_ENABLED,
    USER_IS_FUZZY_LOADED,
    USER_IS_FUZZY_ON,
    USER_IS_SUGGESTED_NAMES_ON,
    USER_PREFERENCES_ADD_CONTENT_TYPE,
    USER_PREFERENCES_REMOVE_CONTENT_TYPE,
    USER_SET_SOURCES_INFORMATION,
    USER_UPDATE_ALERTS_SORTING,
    USER_UPDATE_FUZZY_THRESHOLD,
    USER_UPDATE_LANGUAGE,
    USER_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT,
    USER_UPDATE_PREFERENCE,
    USER_UPDATE_SEARCH_TYPE,
    USER_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE,
    USER_UPDATE_SORT,
    USER_UPDATE_TOGGLE_NEGATIVE_NEWS_VISUALISATION,
    UPDATE_EXPANDED_FILTER_SECTION,
    UPDATE_SANCTIONS_SOURCES_RISKS,
    ADMIN_UPDATE_SANCTIONS_SOURCES_RISKS,
    PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS,
    ADMIN_PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS,
} from '@UserPreferences/redux/UserPreferences.actions';
import userPreferences from '@UserPreferences/redux/UserPreferences.reduces';
import popupModel from '@reusable/PopupModal/redux/PopupModel.reducers';
import update from 'immutability-helper';
import DilQueue, { URL_FUZZY } from '@utils/promiseQueue';
import postFilterConfiguration from '@MainSearch/redux/PostFilterConfiguration.reducers';
import {
    BE_LOCK_NAMES,
    CATEGORIES_ORDER,
    CATEGORY_NAMES,
    COMPANY_SOURCES_SEARCH_OPTION,
    DEFAULT_LANGUAGE,
    FILTER_TYPES_CONSTANTS,
    GENERAL_PAGE_SIZE_KEY,
    LOCALES,
    MAP_MISSING_NEGATIVE_LANGUAGE_KEYS,
    NEGATIVE_NEWS_SELECTED_LANGUAGE,
    POLLING_MAX_CONSECUTIVE_UNCHANGED_RESPONSES,
} from '@constants';
import historyCategory from '../HistoryPage/redux/History.reducers';
import investigation from '@MainSearch/redux/Investigation.reducers';
import metrics from '@MainSearch/redux/Metrics.reducers';
import utils from '@utils/utilities';
import errorUtils from '@utils/errors/error-utils';
import categoryUtils from '@utils/categoryUtils';
import editSearch from '../StartPage/redux/EditSearch.reducers';
import suggestedNames from '@reusable/SuggestedNames/redux/SuggestedNames.reducers';
import negativeNewsVisualisation from '@MainSearch/components/negativeNewsVisualisations/redux/NegativeNewsVisualisation.reducers';
import sanctionsRisk from '@MainSearch/components/sanctionsRisk/redux/SanctionsRisk.reducers';

/**
 * GENERAL USE REDUCERS
 */

export const SET_HELP_URL_ROOT = 'SET_HELP_URL_ROOT';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_GET_PREFERENCES = 'USER_GET_PREFERENCES';
export const ADMIN_GET_PREFERENCES = 'ADMIN_GET_PREFERENCES';
export const USER_AUTHENTICATION_TYPE = 'USER_AUTHENTICATION_TYPE';
export const TOGGLE_PR = 'TOGGLE_PR';
export const TOGGLE_UBO = 'TOGGLE_UBO';
export const TOGGLE_ENTITY_VIEW_ENABLE = 'TOGGLE_ENTITY_VIEW_ENABLE';
export const TOGGLE_ENTITY_VIEW_TRIAL = 'TOGGLE_ENTITY_VIEW_TRIAL';
export const TOGGLE_IS_SPECIFIED_CUSTOMER = 'TOGGLE_IS_SPECIFIED_CUSTOMER';
export const SET_USER_OFFERINGS = 'SET_USER_OFFERINGS';
export const USER_INFO_FAIL = 'USER_INFO_FAIL';
export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const SHOW_FULL_SPINNER = 'SHOW_FULL_SPINNER';
export const HIDE_FULL_SPINNER = 'HIDE_FULL_SPINNER';
export const REMOVE_ALL_SPINNERS = 'REMOVE_ALL_SPINNERS';
const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
export const USER_CHANGE_LANGUAGE = 'USER_CHANGE_LANGUAGE';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';
export const UPDATE_ADMIN_PREFERENCES = 'UPDATE_ADMIN_PREFERENCES';
export const UPDATE_PENDING_UPLOADS = 'UPDATE_PENDING_UPLOADS';
export const SET_GOOGLE_TRANSLATE_LANGUAGE = 'SET_LANGUAGE';
export const SET_GOOGLE_TRANSLATE_STATUS = 'SET_GOOGLE_TRANSLATE_STATUS';
export const UPDATE_HISTORY_CURRENT_CATEGORY = 'UPDATE_HISTORY_CURRENT_CATEGORY';
export const SET_REPORT_CATEGORIES_ORDER = 'SET_REPORT_CATEGORIES_ORDER';
export const SET_ADMIN_REPORT_CATEGORIES_ORDER = 'SET_ADMIN_REPORT_CATEGORIES_ORDER';
export const UPDATE_CONTENT_TYPES = 'UPDATE_CONTENT_TYPES';
export const UPDATE_ADMIN_CONTENT_TYPES = 'UPDATE_ADMIN_CONTENT_TYPES';
export const UPDATE_USER_PREFS_IS_DIRTY = 'UPDATE_USER_PREFS_IS_DIRTY';
export const ADD_PR_TYPES = 'ADD_PR_LINKS';
export const TOGGLE_MARKETING_ENTITY_VIEW = 'TOGGLE_MARKETING_ENTITY_VIEW';

export const PERSON_CHECK = 'personCheck';
export const COMPANY_CHECK = 'companyCheck';
export const GENERAL_SETTINGS = 'generalSettings';
export const DELIVERY_SETTINGS = 'deliverySettings';
export const PREFERENCES_SIDE_NAVIGATION = [GENERAL_SETTINGS, PERSON_CHECK, COMPANY_CHECK, DELIVERY_SETTINGS];
const APP_MODULE = 'main.reducers';

function getAllPermissionEnabled() {
    return Object.values(BE_LOCK_NAMES);
}

export function user(state = {}, action) {
    function proccessUserPreferences(prefsValues, prefs, userInterfaceLanguage) {
        prefs.customQuery = prefsValues.customQuery || [];
        prefs.customNewsQuery = prefsValues.customNewsQuery || [];
        prefs.lnCustomNewsQueries = prefsValues.lnCustomNewsQueries || [];
        prefs.adminCustomNewsQuery = prefsValues.adminCustomNewsQuery || [];
        prefs.negativityLevel = prefsValues.negativityLevel || 'low';
        prefs.searchSpecifically = prefsValues.searchSpecifically || COMPANY_SOURCES_SEARCH_OPTION.SPECIFICALLY;
        prefs.searchNamePartyEntity = prefsValues.searchNamePartyEntity || false;
        prefs.fuzzyThreshold = prefsValues.fuzzyThreshold || 'close';
        prefs.fuzzyOn = prefsValues.fuzzyOn || false;
        prefs.suggestedNames = prefsValues.suggestedNames || false;
        prefs.sanctionRiskRating = prefsValues.sanctionRiskRating || {};
        prefs.maxDocumentsInReportCategory = prefsValues.maxDocumentsInReportCategory || 200;
        prefs.resultListPage = prefsValues.resultListPage || { expandedFiltersSection: FILTER_TYPES_CONSTANTS.CONTENT_TYPES };
        mapNewsSources(prefsValues, prefs, userInterfaceLanguage);
        return prefs;
    }

    function mapNewsSources(prefsValues, prefs, userInterfaceLanguage) {
        // if the news sources was implemented the key will be stored in preferences
        if (find(NEGATIVE_NEWS_SELECTED_LANGUAGE, ['name', prefsValues.newsSearchSources])) {
            prefs.newsSearchSources = prefsValues.newsSearchSources;
        } else {
            // if the key was not implemented get the missing key languages info
            let missingLanguageKey = find(MAP_MISSING_NEGATIVE_LANGUAGE_KEYS, ['name', prefsValues.newsSearchSources]);
            if (missingLanguageKey) {
                // let languages = missingLanguageKey.values;
                if (LOCALES['en-us'] === userInterfaceLanguage || LOCALES['en-gb'] === userInterfaceLanguage) {
                    prefs.newsSearchSources = NEGATIVE_NEWS_SELECTED_LANGUAGE.allEnglishLanguageNews.name;
                } else {
                    prefs.newsSearchSources = NEGATIVE_NEWS_SELECTED_LANGUAGE.allNewsAllLanguages.name;
                }
            } else {
                // if no mapping was provided, throw an error but store the general preference for news source so the app won't crash
                prefs.newsSearchSources = NEGATIVE_NEWS_SELECTED_LANGUAGE.allNewsAllLanguages.name;
                console.error('!!Missing mapping for the specified news source');
            }
        }
    }

    function processPreferences(prefValues, prefs, userInterfaceLanguage) {
        proccessUserPreferences(
            prefValues.companyCheck,
            prefs.companyCheck ? prefs.companyCheck : (prefs.companyCheck = {}),
            userInterfaceLanguage
        );
        proccessUserPreferences(
            prefValues.personCheck,
            prefs.personCheck ? prefs.personCheck : (prefs.personCheck = {}),
            userInterfaceLanguage
        );
    }

    function validateInterfaceLanguage(language) {
        let interfaceLanguage = DEFAULT_LANGUAGE;
        Object.keys(LOCALES).forEach((existingLanguage) => {
            if (language === existingLanguage) {
                interfaceLanguage = language;
            }
        });
        return interfaceLanguage;
    }

    function processLawSources(generalSettings) {
        let allLawSources = generalSettings.lawSources;
        let selectedLawSource = generalSettings.legalSources.defaultDateSource;
        let lawSource = generalSettings.contentTypes.find((contentType) => {
            return contentType.name === CATEGORY_NAMES.LAW_SOURCES;
        });

        allLawSources.forEach((source) => {
            generalSettings.contentTypes.forEach((contentType) => {
                if (contentType.name === source) {
                    if (lawSource.value) {
                        if (selectedLawSource === 'all') {
                            contentType.value = true;
                        } else {
                            contentType.value = false;
                            if (lawSource.value) {
                                if (contentType.name === selectedLawSource) {
                                    contentType.value = true;
                                }
                            }
                        }
                    } else {
                        contentType.value = false;
                    }
                }
            });
        });
    }

    function validateContentTypes(contentTypes) {
        let tempContentTypes = [];
        const isUboEnabled = state.appSettings.uboEnabled;
        const userOfferings = cloneDeep(state.userOfferings);

        Object.keys(CATEGORIES_ORDER).forEach((category) => {
            contentTypes.forEach((contentType) => {
                const isInOfferingsPackage = utils.filterContentTypesByOfferings(userOfferings, contentType);

                if (CATEGORIES_ORDER[category].key === contentType.name) {
                    if (isInOfferingsPackage || contentType.name === CATEGORY_NAMES.DNB) {
                        tempContentTypes.push(contentType);
                    }
                }
            });
        });

        if (!isUboEnabled) {
            tempContentTypes = tempContentTypes.filter((contentType) => !categoryUtils.isDnbCategory(contentType.name));
        }

        return tempContentTypes;
    }

    switch (action.type) {
        case SET_HELP_URL_ROOT:
            if (!action.payload || isEmpty(action.payload)) {
                return state;
            }
            return Object.assign({}, state, { helpUrlRoot: action.payload });

        case USER_AUTHENTICATION_TYPE:
            if (
                !action.payload ||
                isEmpty(action.payload) ||
                !action.payload.authenticationInformation ||
                action.payload.alertsOn === undefined ||
                action.payload.prsOn === undefined ||
                !action.payload.publicRecordsApplicationUrl
            ) {
                return state;
            } else {
                let { wamLogin, wamLogout, wamAciCode, wamSignIn, customerUi } =
                    action.payload.authenticationInformation;
                if (!wamLogin || !wamLogout || !wamAciCode || !wamSignIn || !customerUi) {
                    return state;
                }
            }

            let newSettings = Object.assign({}, state.appSettings, action.payload);
            newSettings.prsOn = false;

            return Object.assign({}, state, { appSettings: newSettings });

        case TOGGLE_PR:
            // double equality checks for undefined and null (in this case)
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + TOGGLE_PR,
                    {
                        prsOn: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { appSettings: { prsOn: { $set: action.payload } } });

        case ADD_PR_TYPES:
            // double equality checks for undefined and null (in this case)
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADD_PR_TYPES,
                    {
                        prsOn: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { appSettings: { publicRecordTypes: { $set: action.payload } } });

        case TOGGLE_UBO:
            // double equality checks for undefined and null (in this case)
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + TOGGLE_UBO,
                    {
                        uboEnabled: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { appSettings: { uboEnabled: { $set: action.payload } } });

        case TOGGLE_ENTITY_VIEW_ENABLE:
            // double equality checks for undefined and null (in this case)
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    `Missing parameter in ${TOGGLE_ENTITY_VIEW_ENABLE}`,
                    {
                        isBatchReportsEnabled: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { appSettings: { isBatchReportsEnabled: { $set: action.payload } } });

        case TOGGLE_ENTITY_VIEW_TRIAL:
            if (action.payload === undefined) {
                errorUtils.logAppError(
                    `Missing parameter in ${TOGGLE_ENTITY_VIEW_TRIAL}`,
                    {
                        isMultipleEntitiesTrial: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { appSettings: { isMultipleEntitiesTrial: { $set: action.payload } } });

        case TOGGLE_IS_SPECIFIED_CUSTOMER:
            if (action.payload === undefined) {
                errorUtils.logAppError(
                    `Missing parameter in ${TOGGLE_IS_SPECIFIED_CUSTOMER}`,
                    {
                        isSpecifiedCustomer: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { appSettings: { isSpecifiedCustomer: { $set: action.payload } } });

        case TOGGLE_MARKETING_ENTITY_VIEW:
            if (action.payload === undefined) {
                errorUtils.logAppError(
                    `Missing parameter in ${TOGGLE_MARKETING_ENTITY_VIEW}`,
                    {
                        shouldDisplayEntityViewMarketingInfo: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, {
                appSettings: { shouldDisplayEntityViewMarketingInfo: { $set: action.payload } },
            });

        case USER_INFO_SUCCESS: {
            if (!action.payload || isEmpty(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            let prefs = action.payload.preferences;

            //for some reason, in general settings we got a plain array that could corrupt preferences
            if (prefs && prefs.generalSettings && !prefs.generalSettings.contentTypes) {
                delete prefs.generalSettings.contentTypes;
            }

            let metadataLanguage;
            if (!state.preferences.metadataLanguage) {
                metadataLanguage = !state.preferences.language ? '' : state.preferences.language.split('-')[0];
            } else {
                metadataLanguage = state.preferences.metadataLanguage;
            }

            let loginHistoryData = Object.assign(
                {},
                {
                    loginHistoryLink: action.payload.loginHistoryLink,
                    lastLoginDate: action.payload.lastLoginDate,
                    showLoginHistoryBanner: !!action.payload.lastLoginDate,
                }
            );

            //TODO: refactor the merging to add dynamically all the props (with ...)
            return merge(
                {},
                state,
                {
                    isLoggedIn: true,
                    preferences: prefs,
                    name: action.payload.user,
                    email: action.payload.email,
                    isAnonymized: action.payload.isAnonymized,
                    isMIP: action.payload.isMip,
                    maxNumberOfArticles: action.payload.maxNumberOfArticles,
                    maxNumberOfConsecutiveCallsForPolling:
                        action.payload.maxNumberOfConsecutiveCallsForPolling ||
                        POLLING_MAX_CONSECUTIVE_UNCHANGED_RESPONSES,
                    maxNumberOfHistoryEntries: action.payload.maxNumberOfHistoryEntries,
                    maxNumberOfReports: action.payload.maxNumberOfReports,
                    normalReportDeliveryAvailabilityDays: action.payload.normalReportDeliveryAvailabilityDays,
                    prReportDeliveryAvailabilityDays: action.payload.prReportDeliveryAvailabilityDays,
                    useDocumentsCountEndpoint: action.payload.useDocumentsCountEndpoint,
                    useNewHighlightForDocsByList: action.payload.useNewHighlightForDocsByList,
                    areCombinedReportsAvailable: action.payload.areCombinedReportsAvailable,
                    useOptimizedSearch: action.payload.useOptimizedSearch,
                    useNewResearchSummary: action.payload.useNewResearchSummaryFeature,
                    useNewResearchSummaryFeature: action.payload.useNewResearchSummaryFeature,
                    isBrazilianOwnershipEnabled: true,
                    googleAnonymizedURL: action.payload.googleAnonymizedURL,
                    preferencesRefreshPeriod: action.payload.preferencesRefreshPeriod,
                    reportSnippetsPageSize: action.payload.reportSnippetsPageSize,
                    userId: action.payload.userId,
                    userPermId: action.payload.userPermId,
                    userPermIdUnhashed: action.payload.userPermIdUnhashed,
                    customerName: action.payload.customerName,
                    customerId: action.payload.customerId,
                    permissions: action.payload.permissions,
                    timezone: action.payload.timezone,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                    loginHistoryData: loginHistoryData,
                    subjectAndIndustryAvailable: action.payload.subjectAndIndustryAvailable,
                    disablePendoGuides: action.payload.disablePendoGuides,
                    newFuzzyEnabled: action.payload.newFuzzyEnabled,
                    suggestedNamesEnabled: action.payload.suggestedNamesEnabled,
                    adminPermissionChanged: action.payload.adminPermissionChanged,
                    newsExcludeTogglesEnabled: action.payload.newsExcludeTogglesEnabled,
                    language: action.payload.language,
                    lastPreferencesUpdateInfo: {},
                    useNegativeNewsVisualisationsFeature: action.payload.useNegativeNewsVisualisationsFeature,
                    customerClass: action.payload.customerClass,
                    useSnapshotDelivery: action.payload.useSnapshotDelivery,
                    showEsgRatings: action.payload.showEsgRatings,
                    enforceAlertsLimit: action.payload.enforceAlertsLimit,
                    sanctionsAndWatchListsPreferences: action.payload.sanctionsAndWatchListsPreferences,
                    sanctionsAndWatchListsSnapshot: action.payload.sanctionsAndWatchListsSnapshot,
                    screeningEntityCommentsLimit: action.payload.screeningEntityCommentsLimit,
                    screeningEntityShareViewEnabled: action.payload.screeningEntityShareViewEnabled,
                    diligencePlusMarketingOverlay: action.payload.diligencePlusMarketingOverlay,
                },
                {
                    preferences: {
                        metadataLanguage: metadataLanguage,
                        isDirty: false,
                    },
                }
            );
        }

        case SET_USER_OFFERINGS: {
            if (!action.payload || action.payload == []) {
                return state;
            }

            return merge({}, state, { userOfferings: action.payload });
        }

        case USER_UPDATE_LANGUAGE: {
            if (!action.payload || isEmpty(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            let timeStamp = Date.now();

            return merge({}, state, { preferences: { language: action.payload, languageLastUpdated: timeStamp } });
        }

        case USER_UPDATE_SEARCH_TYPE: {
            if (!action.payload || isEmpty(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            return merge({}, state, { preferences: { generalSettings: { searchType: action.payload } } });
        }

        case USER_UPDATE_SORT: {
            if (!action.payload || isEmpty(action.payload)) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_UPDATE_SORT,
                    { "userPreferences 'sort' parameter value: ": action.payload },
                    APP_MODULE
                );
                return state;
            }

            return merge({}, state, { preferences: { generalSettings: { sort: action.payload } } });
        }

        case USER_UPDATE_TOGGLE_NEGATIVE_NEWS_VISUALISATION: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_UPDATE_TOGGLE_NEGATIVE_NEWS_VISUALISATION,
                    { "userPreferences 'isTrendsExpanded' parameter value: ": action.payload },
                    APP_MODULE
                );
                return state;
            }

            return merge({}, state, { preferences: { generalSettings: { isTrendsExpanded: action.payload } } });
        }

        case UPDATE_FILTER_SECTION_VISIBILITY: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_FILTER_SECTION_VISIBILITY,
                    { "userPreferences 'isFilterSectionCollapsed' parameter value: ": action.payload },
                    APP_MODULE
                );

                return state;
            }

            return merge({}, state, { preferences: { generalSettings: { isFilterSectionCollapsed: action.payload } } });
        }

        case UPDATE_BATCH_CONTENT_TYPES_VISIBILITY: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_BATCH_CONTENT_TYPES_VISIBILITY,
                    { "userPreferences 'isBatchContentTypesCollapsed' parameter value: ": action.payload },
                    APP_MODULE
                );

                return state;
            }

            return merge({}, state, {
                preferences: { generalSettings: { isBatchContentTypesCollapsed: action.payload } },
            });
        }

        case UPDATE_BATCH_SCREEN_PREFS: {
            if (!action || !action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_BATCH_CONTENT_TYPES_VISIBILITY,
                    { "userPreferences 'screeningEntity' parameter value: ": action.payload },
                    APP_MODULE
                );

                return state;
            }

            return merge({}, state, { preferences: { screeningEntity: action.payload } });
        }

        case UPDATE_STYLING_THEME: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_STYLING_THEME,
                    { "userPreferences 'isDarkMode' parameter value: ": action.payload },
                    APP_MODULE
                );
                return state;
            }

            return merge({}, state, { preferences: { generalSettings: { isDarkMode: action.payload } } });
        }

        case USER_UPDATE_ALERTS_SORTING: {
            const { sortBy, direction } = action.payload;

            if (!action.payload || isEmpty(action.payload) || !sortBy || !direction) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_UPDATE_ALERTS_SORTING,
                    { "userPreferences.generalSettings 'alertsOptions' parameter value: ": action.payload },
                    APP_MODULE
                );
                return state;
            }

            return merge({}, state, {
                preferences: { generalSettings: { alertsOptions: { sorting: { sortBy, direction } } } },
            });
        }

        case USER_IS_FUZZY_ENABLED: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_IS_FUZZY_ENABLED,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            let isFuzzyEnabled = action.payload;
            return merge({}, state, { preferences: { generalSettings: { isFuzzyEnabled: isFuzzyEnabled } } });
        }

        case USER_IS_FUZZY_ON: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_IS_FUZZY_ENABLED,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            let isFuzzyEnabled = action.payload;
            return update(state, { preferences: { personCheck: { fuzzyOn: { $set: isFuzzyEnabled } } } });
        }

        case USER_IS_SUGGESTED_NAMES_ON: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_IS_SUGGESTED_NAMES_ON,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            let isSuggestedNamesOn = action.payload;
            return update(state, { preferences: { personCheck: { suggestedNames: { $set: isSuggestedNamesOn } } } });
        }

        case ADMIN_IS_FUZZY_ENABLED: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_IS_FUZZY_ENABLED,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            let isFuzzyEnabled = action.payload;
            return merge({}, state, { adminPreferences: { generalSettings: { isFuzzyEnabled: isFuzzyEnabled } } });
        }

        case ADMIN_IS_FUZZY_ON: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_IS_FUZZY_ON,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            let isFuzzyEnabled = action.payload;
            return update(state, { adminPreferences: { personCheck: { fuzzyOn: { $set: isFuzzyEnabled } } } });
        }

        case ADMIN_IS_SUGGESTED_NAMES_ON: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_IS_SUGGESTED_NAMES_ON,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            let isSuggestedNamesOn = action.payload;
            return update(state, {
                adminPreferences: { personCheck: { suggestedNames: { $set: isSuggestedNamesOn } } },
            });
        }

        case USER_IS_FUZZY_LOADED: {
            if (action.payload !== false && action.payload !== true) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_IS_FUZZY_LOADED,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            let isFuzzyLoaded = action.payload;
            return merge({}, state, { preferences: { generalSettings: { isFuzzyLoaded: isFuzzyLoaded } } });
        }

        case USER_GET_FUZZY_MATCH: {
            if (!action.payload || isNil(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            let fuzzyMatch = action.payload;
            return merge({}, state, { preferences: { generalSettings: { fuzzyMatch: fuzzyMatch } } });
        }

        case USER_UPDATE_FUZZY_THRESHOLD: {
            if (!action.payload || isNil(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            let fuzzyMatch = action.payload;
            return update(state, { preferences: { personCheck: { fuzzyThreshold: { $set: fuzzyMatch } } } });
        }

        case ADMIN_GET_FUZZY_MATCH: {
            if (!action.payload || isNil(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            let fuzzyMatch = action.payload;
            return merge({}, state, { adminPreferences: { generalSettings: { fuzzyMatch: fuzzyMatch } } });
        }

        case ADMIN_UPDATE_FUZZY_THRESHOLD: {
            if (!action.payload || isNil(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            let fuzzyMatch = action.payload;
            return update(state, { adminPreferences: { personCheck: { fuzzyThreshold: { $set: fuzzyMatch } } } });
        }

        case UPDATE_PREFS_NOTICE: {
            if (action.payload !== false && action.payload !== true) {
                console.error(UPDATE_PREFS_NOTICE, action.payload);
                return state;
            }

            let prefsUpdatedByAdmin = action.payload;
            return merge({}, state, { preferences: { prefsUpdatedByAdmin: prefsUpdatedByAdmin } });
        }

        case UPDATE_LOCKS: {
            if (!action.payload || isNil(action.payload) || isEmpty(action.payload)) {
                return state;
            }
            let userLock = action.payload;
            return merge({}, state, { adminPreferences: { userLocks: userLock } });
        }

        case UPDATE_USER_PREFERENCES_PAYLOAD: {
            if (
                !action.payload ||
                isNil(action.payload) ||
                isEmpty(action.payload) ||
                !action.payload.name ||
                !action.payload.userPreferences ||
                !action.payload.permissions
            ) {
                return state;
            }

            return update(state, { preferences: { payload: { $set: action.payload } } });
        }

        case USER_GET_PREFERENCES: {
            if (
                !action.payload ||
                isNil(action.payload) ||
                isEmpty(action.payload) ||
                !action.payload.name ||
                !action.payload.userPreferences ||
                !action.payload.permissions
            ) {
                return state;
            } else if (action.payload && action.payload.userPreferences) {
                for (let key in action.payload.userPreferences) {
                    if (isNil(action.payload.userPreferences[key])) {
                        return state;
                    }
                }
            }

            let prefsValues = action.payload.userPreferences;
            let userLocks = action.payload.permissions ? action.payload.permissions : [];
            if (userLocks.length === 0) {
                userLocks = getAllPermissionEnabled();
            }
            let prefs = cloneDeep(state.preferences) || {};
            let contentTypes = prefsValues.generalSettings.contentTypes;
            let validContentTypes = validateContentTypes(contentTypes);
            let userInterfaceLanguage = validateInterfaceLanguage(prefsValues.language);
            const lastUserPreferencesRetrieval = Date.now();
            processPreferences(prefsValues, prefs, userInterfaceLanguage);

            let generalSettings = Object.assign({}, prefs.generalSettings, {
                contentTypes: validContentTypes,
                showSnapshot: prefsValues.generalSettings.showSnapshot,
                newsSources: prefsValues.generalSettings.newsSources,
                companySources: prefsValues.generalSettings.companySources,
                legalSources: prefsValues.generalSettings.legalSources,
                isFuzzyEnabled: prefsValues.generalSettings.enableFuzzy,
                pageSize: prefsValues.generalSettings.pageSize,
                generalPageSize:
                    parseInt(localStorage.getItem('generalPageSize')) ||
                    parseInt(state.preferences.generalSettings.generalPageSize),
                fuzzyMatch: prefsValues.generalSettings.fuzzyMatch,
                searchType: prefsValues.generalSettings.searchType,
                sort: prefsValues.generalSettings.sort,
                selectedCostCode: prefsValues.generalSettings.selectedCostCode,
                isCostCodeRequired: prefsValues.generalSettings.isCostCodeRequired,
                chooseOnlyFromCostCodesList: prefsValues.generalSettings.chooseOnlyFromCostCodesList,
                costCodesList: prefsValues.generalSettings.costCodesList || [],
                proximity: prefsValues.generalSettings.proximity,
                searchNamePartyEntity: prefsValues.generalSettings.legalSources.searchNamePartyEntity || false,
                alertsOptions: prefsValues.generalSettings.alertsOptions,
                excludeNonBusinessNews: prefsValues.generalSettings.excludeNonBusinessNews,
                excludeNewsWires: prefsValues.generalSettings.excludeNewsWires,
                isTrendsExpanded: prefsValues.generalSettings.isTrendsExpanded,
                isFilterSectionCollapsed: prefsValues.generalSettings.isFilterSectionCollapsed,
                isDarkMode: prefsValues.generalSettings.isDarkMode,
                displayRiskScores: prefsValues.generalSettings.displayRiskScores,
                isBatchContentTypesCollapsed: prefsValues.generalSettings.isBatchContentTypesCollapsed,
                activeAlertsLimit: prefsValues.generalSettings.activeAlertsLimit,
                screeningEntity: prefsValues.screeningEntity,
                sanctionRiskRating: prefsValues.sanctionRiskRating,
                entityUploadLimit: prefsValues.generalSettings.entityUploadLimit,
                sanctionsSources: prefsValues.generalSettings.sanctionsSources,
                excludeLowQualityDocuments: prefsValues.generalSettings.excludeLowQualityDocuments,
            });
            //@TODO remove this function after the refactoring for Legal Sources is finalized
            processLawSources(generalSettings);
            prefs = Object.assign(
                {},
                prefs,
                { generalSettings: generalSettings },
                { lastUserPreferencesRetrieval },
                { contentsOptions: prefsValues.contentsOptions },
                { resultsContentOptions: prefsValues.resultsContentOptions },
                { modifiedByUser: prefsValues.modifiedByUser || {} },
                { showDeliveryOptions: prefsValues.showDeliveryOptions },
                { resultsListShowDeliveryOptions: prefsValues.resultsListShowDeliveryOptions },
                { startEachArticleOnNewPage: prefsValues.startEachArticleOnNewPage },
                { screeningEntity: prefsValues.screeningEntity },
                { language: userInterfaceLanguage },
                { prefsUpdatedByAdmin: prefsValues.prefsUpdatedByAdmin },
                { userLocks: utils.convertUserLocksArrToObj(userLocks) },
                { sanctionRiskRating: prefsValues.sanctionRiskRating },
                { maxDocumentsInReportCategory: prefsValues.maxDocumentsInReportCategory },
                { resultListPage: prefsValues.resultListPage || { expandedFiltersSection: FILTER_TYPES_CONSTANTS.CONTENT_TYPES } }
            );

            prefs.payload = action.payload;
            return Object.assign({}, state, { preferences: prefs });
        }

        case SET_USER_PREFERENCES: {
            if (!action.payload || isNil(action.payload) || isEmpty(action.payload)) {
                return state;
            }

            let prefsValues = action.payload;
            return Object.assign({}, state, { preferences: prefsValues });
        }

        case ADMIN_GET_PREFERENCES: {
            if (
                !action.payload ||
                isNil(action.payload) ||
                isEmpty(action.payload) ||
                !action.payload.name ||
                !action.payload.adminPreferences ||
                !action.payload.permissions
            ) {
                return state;
            } else if (action.payload && action.payload.adminPreferences) {
                for (let key in action.payload.adminPreferences) {
                    if (isNil(action.payload.adminPreferences[key])) {
                        return state;
                    }
                }
            }

            let prefsValues = action.payload.adminPreferences;
            let userLocks = action.payload.permissions ? action.payload.permissions : [];
            let prefs = cloneDeep(state.preferences) || {};
            let contentTypes = prefsValues.generalSettings.contentTypes;
            let validContentTypes = validateContentTypes(contentTypes);

            processPreferences(prefsValues, prefs);
            let generalSettings = Object.assign({}, prefs.generalSettings, {
                contentTypes: validContentTypes,
                showSnapshot: prefsValues.generalSettings.showSnapshot,
                newsSources: prefsValues.generalSettings.newsSources,
                companySources: prefsValues.generalSettings.companySources,
                legalSources: prefsValues.generalSettings.legalSources,
                isFuzzyEnabled: prefsValues.generalSettings.enableFuzzy,
                pageSize: prefsValues.generalSettings.pageSize,
                fuzzyMatch: prefsValues.generalSettings.fuzzyMatch,
                costCodesList: prefsValues.generalSettings.costCodesList
                    ? prefsValues.generalSettings.costCodesList.sort()
                    : [],
                isCostCodeRequired: prefsValues.generalSettings.isCostCodeRequired,
                chooseOnlyFromCostCodesList: prefsValues.generalSettings.chooseOnlyFromCostCodesList,
                proximity: prefsValues.generalSettings.proximity,
                searchNamePartyEntity: prefsValues.generalSettings.legalSources.searchNamePartyEntity || false,
                excludeNonBusinessNews: prefsValues.generalSettings.excludeNonBusinessNews,
                excludeNewsWires: prefsValues.generalSettings.excludeNewsWires,
                isTrendsExpanded: prefsValues.generalSettings.isTrendsExpanded,
                isFilterSectionCollapsed: prefsValues.generalSettings.isFilterSectionCollapsed,
                isDarkMode: prefsValues.generalSettings.isDarkMode,
                isBatchContentTypesCollapsed: prefsValues.generalSettings.isBatchContentTypesCollapsed,
                activeAlertsLimit: prefsValues.generalSettings.activeAlertsLimit,
                sanctionRiskRating: prefsValues.sanctionRiskRating,
                resultListPage: prefsValues.resultListPage,
                sanctionsSources: prefsValues.generalSettings.sanctionsSources,
                excludeLowQualityDocuments: prefsValues.generalSettings.excludeLowQualityDocuments,
            });
            //@TODO remove this function after the refactoring for Legal Sources is finalized
            processLawSources(generalSettings);
            prefs = Object.assign(
                {},
                prefs,
                { contentsOptions: prefsValues.contentsOptions },
                { resultsContentOptions: prefsValues.resultsContentOptions },
                { startEachArticleOnNewPage: prefsValues.startEachArticleOnNewPage },
                { generalSettings: generalSettings },
                { userLocks: utils.convertUserLocksArrToObj(userLocks) },
                { usersInPreferenceGroup: action.payload.usersInPreferenceGroup },
                { sanctionRiskRating: prefsValues.sanctionRiskRating },
                { maxDocumentsInReportCategory: prefsValues.maxDocumentsInReportCategory },
                { resultListPage: prefsValues.resultListPage }
            );
            return Object.assign({}, state, { adminPreferences: prefs });
        }

        case UPDATE_USER_PREFERENCES: {
            let prefs = Object.assign({}, state.preferences, action.payload);
            return Object.assign({}, state, { isLoggedIn: true }, { preferences: prefs });
        }

        case ADMIN_DISCARD_CHANGES: {
            let adminPrefs = cloneDeep(state.adminPreferences);
            let payload = cloneDeep(action.payload);
            return Object.assign({}, state, { isLoggedIn: true }, { adminPreferences: { ...adminPrefs, ...payload } });
        }

        case UPDATE_USER_PREFERENCES_CUSTOM_QUERY: {
            let customQuery = action.payload.customQuery;
            let segment = action.payload.segment;

            return merge({}, state, { isLoggedIn: true }, { preferences: { [segment]: { customQuery } } });
        }

        case UPDATE_ADMIN_PREFERENCES_CUSTOM_QUERY: {
            let customQuery = action.payload.customQuery;
            let segment = action.payload.segment;

            return merge({}, state, { isLoggedIn: true }, { adminPreferences: { [segment]: { customQuery } } });
        }

        case USER_PREFERENCES_REMOVE_CONTENT_TYPE: {
            let contentTypes = state.preferences.generalSettings.contentTypes.slice(0);
            contentTypes.forEach((item) => {
                action.payload.forEach((contentType) => {
                    if (item.name === contentType) {
                        item.value = false;
                        item.reportOrder = contentTypes.length;
                    }
                });
            });
            return merge({}, state, { preferences: { generalSettings: { contentTypes } } });
        }

        case ADMIN_PREFERENCES_REMOVE_CONTENT_TYPE: {
            let contentTypes = state.adminPreferences.generalSettings.contentTypes.slice(0);
            contentTypes.forEach((item) => {
                action.payload.forEach((contentType) => {
                    if (item.name === contentType) {
                        item.value = false;
                        item.reportOrder = contentTypes.length;
                    }
                });
            });
            return merge({}, state, { adminPreferences: { generalSettings: { contentTypes } } });
        }

        case USER_PREFERENCES_ADD_CONTENT_TYPE: {
            let contentTypes = cloneDeep(state.preferences.generalSettings.contentTypes);
            contentTypes.forEach((item) => {
                action.payload.forEach((contentType) => {
                    if (item.name === contentType) {
                        item.value = true;
                        item.reportOrder = contentTypes.length;
                    }
                });
            });
            const newContentTypes = utils.sortContentTypesByOrder(contentTypes);
            return update(state, { preferences: { generalSettings: { contentTypes: { $set: newContentTypes } } } });
        }

        case ADMIN_PREFERENCES_ADD_CONTENT_TYPE: {
            let contentTypes = cloneDeep(state.adminPreferences.generalSettings.contentTypes);
            contentTypes.forEach((item) => {
                action.payload.forEach((contentType) => {
                    if (item.name === contentType) {
                        item.value = true;
                        item.reportOrder = contentTypes.length;
                    }
                });
            });
            const newContentTypes = utils.sortContentTypesByOrder(contentTypes);
            return update(state, {
                adminPreferences: { generalSettings: { contentTypes: { $set: newContentTypes } } },
            });
        }

        case SET_REPORT_CATEGORIES_ORDER: {
            let reportContentTypes = action.payload;
            return update(state, { preferences: { generalSettings: { contentTypes: { $set: reportContentTypes } } } });
        }

        case SET_ADMIN_REPORT_CATEGORIES_ORDER: {
            let reportContentTypes = action.payload;
            return update(state, {
                adminPreferences: { generalSettings: { contentTypes: { $set: reportContentTypes } } },
            });
        }

        case CHANGE_DATE_RANGE: {
            const { dateRange, section } = action.payload;
            return merge({}, state, { preferences: { generalSettings: { [section]: { dateRange } } } });
        }

        case CHANGE_ADMIN_DATE_RANGE: {
            const { dateRange, section } = action.payload;
            return merge({}, state, { adminPreferences: { generalSettings: { [section]: { dateRange } } } });
        }

        case CHANGE_PROXIMITY: {
            const proximity = action.payload;
            return merge({}, state, { preferences: { generalSettings: { proximity } } });
        }

        case CHANGE_ADMIN_PROXIMITY: {
            const proximity = action.payload;
            return merge({}, state, { adminPreferences: { generalSettings: { proximity } } });
        }

        case CHANGE_LEGAL_SOURCE: {
            const { source, section } = action.payload;
            return merge({}, state, { preferences: { generalSettings: { [section]: { defaultDateSource: source } } } });
        }

        case CHANGE_ADMIN_LEGAL_SOURCE: {
            const { source, section } = action.payload;
            return merge({}, state, {
                adminPreferences: { generalSettings: { [section]: { defaultDateSource: source } } },
            });
        }

        case PREFERENCES_UPDATE_SHOW_SNAPSHOT: {
            let showSnapshot = action.payload;
            return update(state, { preferences: { generalSettings: { showSnapshot: { $set: showSnapshot } } } });
        }

        case ADMIN_PREFERENCES_UPDATE_SHOW_SNAPSHOT: {
            let showSnapshot = action.payload;
            return update(state, { adminPreferences: { generalSettings: { showSnapshot: { $set: showSnapshot } } } });
        }

        case UPDATE_GENERAL_PAGE_SIZE: {
            let generalPageSize = action.payload;
            localStorage.setItem(GENERAL_PAGE_SIZE_KEY, generalPageSize);
            return update(state, { preferences: { generalSettings: { generalPageSize: { $set: generalPageSize } } } });
        }

        case UPDATE_PAGE_SIZE: {
            let pageSize = action.payload;
            return update(state, { preferences: { generalSettings: { pageSize: { $set: pageSize } } } });
        }

        case USER_INFO_FAIL:
            return Object.assign({}, state, { isLoggedIn: false }, action.payload);

        case USER_LOGOUT_SUCCESS:
            return merge({}, state, action.payload, {
                isLoggedIn: false,
                preferences: { articlesLanguageFilter: 'all' },
            });

        case USER_LOGOUT_FAIL:
            let isLoggedIn = state.isLoading;
            if (isLoggedIn) {
                return state;
            } else {
                return Object.assign({}, state, { isLoggedIn: false }, action.payload);
            }

        case USER_CHANGE_LANGUAGE:
            return Object.assign({}, state, { language: action.payload });

        case UPDATE_PENDING_UPLOADS:
            return Object.assign({}, state, {
                pendingUpload: { count: action.payload.count, uploadId: action.payload.uploadId },
            });

        case SET_GOOGLE_TRANSLATE_LANGUAGE:
            let prefs = state.preferences;
            prefs.metadataLanguage = action.payload;
            return Object.assign({}, state, { preferences: prefs });

        case SET_GOOGLE_TRANSLATE_STATUS:
            let preferences = state.preferences;
            preferences.googleTranslateStatus = action.payload;
            return Object.assign({}, state, { preferences: preferences });

        case CHANGE_QUERY_PERSON: {
            let oldCustomQuery = state.preferences.personCheck.customQuery;
            let newCustomQuery;
            if (oldCustomQuery) {
                newCustomQuery = oldCustomQuery
                    .filter((item) => {
                        return item.language !== action.payload[0].language;
                    })
                    .concat(action.payload);
            } else {
                newCustomQuery = action.payload;
            }

            return update(state, { preferences: { personCheck: { customQuery: { $set: newCustomQuery } } } });
        }

        case CHANGE_ADMIN_QUERY_PERSON: {
            let oldCustomQuery = state.adminPreferences.personCheck.customQuery;
            let newCustomQuery;
            if (oldCustomQuery) {
                newCustomQuery = oldCustomQuery
                    .filter((item) => {
                        return item.language !== action.payload[0].language;
                    })
                    .concat(action.payload);
            } else {
                newCustomQuery = action.payload;
            }

            return update(state, { adminPreferences: { personCheck: { customQuery: { $set: newCustomQuery } } } });
        }

        case CHANGE_QUERY_COMPANY: {
            let oldCustomQuery = state.preferences.companyCheck.customQuery;
            let newCustomQuery;

            if (oldCustomQuery) {
                newCustomQuery = oldCustomQuery
                    .filter((item) => {
                        return item.language !== action.payload[0].language;
                    })
                    .concat(action.payload);
            } else {
                newCustomQuery = action.payload;
            }
            return update(state, { preferences: { companyCheck: { customQuery: { $set: newCustomQuery } } } });
        }

        case CHANGE_ADMIN_QUERY_COMPANY: {
            let oldCustomQuery = state.adminPreferences.companyCheck.customQuery;
            let newCustomQuery;

            if (oldCustomQuery) {
                newCustomQuery = oldCustomQuery
                    .filter((item) => {
                        return item.language !== action.payload[0].language;
                    })
                    .concat(action.payload);
            } else {
                newCustomQuery = action.payload;
            }
            return update(state, { adminPreferences: { companyCheck: { customQuery: { $set: newCustomQuery } } } });
        }

        case UPDATE_PERSON_NEGATIVITY_LEVEL: {
            return update(state, { preferences: { personCheck: { negativityLevel: { $set: action.payload } } } });
        }

        case UPDATE_ADMIN_PERSON_NEGATIVITY_LEVEL: {
            return update(state, { adminPreferences: { personCheck: { negativityLevel: { $set: action.payload } } } });
        }

        case UPDATE_COMPANY_NEGATIVITY_LEVEL: {
            return update(state, { preferences: { companyCheck: { negativityLevel: { $set: action.payload } } } });
        }

        case UPDATE_ADMIN_COMPANY_NEGATIVITY_LEVEL: {
            return update(state, { adminPreferences: { companyCheck: { negativityLevel: { $set: action.payload } } } });
        }

        case UPDATE_CHECKED_LANGUAGE: {
            let checkedLanguage = action.payload.language;
            let customQuery = [];

            if (action.payload.type === COMPANY_CHECK) {
                customQuery = state.preferences.companyCheck.customQuery;
            } else if (action.payload.type === PERSON_CHECK) {
                customQuery = state.preferences.personCheck.customQuery;
            }

            if (customQuery && customQuery.length > 0) {
                customQuery = customQuery.map((language) => {
                    if (checkedLanguage === language.language) {
                        language.checked = action.payload.isChecked;
                    }

                    return language;
                });
            }

            return update(state, { preferences: { [action.payload.type]: { customQuery: { $set: customQuery } } } });
        }

        case UPDATE_ADMIN_CHECKED_LANGUAGE: {
            let checkedLanguage = action.payload.language;
            let customQuery = [];

            if (action.payload.type === COMPANY_CHECK) {
                customQuery = state.adminPreferences.companyCheck.customQuery;
            } else if (action.payload.type === PERSON_CHECK) {
                customQuery = state.adminPreferences.personCheck.customQuery;
            }

            if (customQuery && customQuery.length > 0) {
                customQuery = customQuery.map((language) => {
                    if (checkedLanguage === language.language) {
                        language.checked = action.payload.isChecked;
                    }
                    return language;
                });
            }

            return update(state, {
                adminPreferences: { [action.payload.type]: { customQuery: { $set: customQuery } } },
            });
        }

        case UPDATE_NEWS_SOURCES_SELECTION: {
            return update(state, {
                preferences: { [action.payload.type]: { newsSearchSources: { $set: action.payload.selection } } },
            });
        }

        case UPDATE_ADMIN_NEWS_SOURCES_SELECTION: {
            return update(state, {
                adminPreferences: { [action.payload.type]: { newsSearchSources: { $set: action.payload.selection } } },
            });
        }

        case UPDATE_CHECKED_LANGUAGES_UPON_KEY: {
            let customQuery = [];
            let availableQueryLanguages = cloneDeep(state.preferences.generalSettings.availableQueryLanguages);

            if (action.payload.type === COMPANY_CHECK) {
                customQuery = cloneDeep(state.preferences.companyCheck.customQuery);
            } else if (action.payload.type === PERSON_CHECK) {
                customQuery = cloneDeep(state.preferences.personCheck.customQuery);
            }

            if (customQuery && customQuery.length > 0) {
                customQuery.forEach((language) => {
                    language.checked =
                        action.payload.languages.indexOf(language.language) > -1 &&
                        availableQueryLanguages.indexOf(language.language) > -1;
                });
            }

            return update(state, { preferences: { [action.payload.type]: { customQuery: { $set: customQuery } } } });
        }

        case UPDATE_CUSTOM_NEWS_QUERY_CHECKED_STATE: {
            let { segment, queryName, checked } = action.payload;
            let customNewsQuery = state.preferences[segment]
                ? cloneDeep(state.preferences[segment].customNewsQuery)
                : [];
            if (customNewsQuery && customNewsQuery.length > 0) {
                customNewsQuery.forEach((query) => {
                    if (query.name === queryName) {
                        query.checked = checked;
                    }
                });
            }

            return update(state, { preferences: { [segment]: { customNewsQuery: { $set: customNewsQuery } } } });
        }

        case UPDATE_LN_CUSTOM_NEWS_QUERY_CHECKED_STATE: {
            const { segment, queryName, checked } = action.payload;
            const customNewsQuery = state.preferences[segment]
                ? cloneDeep(state.preferences[segment].lnCustomNewsQueries)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                const toggledLnCustomQuery = customNewsQuery.find((query) => query.name === queryName);

                if (toggledLnCustomQuery) {
                    toggledLnCustomQuery.checked = checked;
                }
            }

            return update(state, { preferences: { [segment]: { lnCustomNewsQueries: { $set: customNewsQuery } } } });
        }

        case UPDATE_FROM_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE: {
            let { segment, queryName, checked } = action.payload;
            let customNewsQuery = state.preferences[segment]
                ? cloneDeep(state.preferences[segment].adminCustomNewsQuery)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                customNewsQuery.forEach((query) => {
                    if (query.name === queryName) {
                        query.checked = checked;
                    }
                });
            }

            return update(state, { preferences: { [segment]: { adminCustomNewsQuery: { $set: customNewsQuery } } } });
        }

        case UPDATE_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE: {
            let { segment, queryName, checked } = action.payload;
            let customNewsQuery = state.adminPreferences[segment]
                ? cloneDeep(state.adminPreferences[segment].adminCustomNewsQuery)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                customNewsQuery.forEach((query) => {
                    if (query.name === queryName) {
                        query.checked = checked;
                    }
                });
            }

            return update(state, {
                adminPreferences: { [segment]: { adminCustomNewsQuery: { $set: customNewsQuery } } },
            });
        }

        case DELETE_CUSTOM_NEWS_QUERY: {
            let { segment, queryName } = action.payload;
            let customNewsQuery = state.preferences[segment]
                ? cloneDeep(state.preferences[segment].customNewsQuery)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                customNewsQuery.forEach((query) => {
                    if (query.name === queryName) {
                        query.isDeleted = true;
                        query.query = '';
                        query.title = '';
                        query.checked = false;
                    }
                });
            }

            return update(state, { preferences: { [segment]: { customNewsQuery: { $set: customNewsQuery } } } });
        }

        case DELETE_ADMIN_CUSTOM_NEWS_QUERY: {
            let { segment, queryName } = action.payload;
            let customNewsQuery = state.adminPreferences[segment]
                ? cloneDeep(state.adminPreferences[segment].adminCustomNewsQuery)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                customNewsQuery.forEach((query) => {
                    if (query.name === queryName) {
                        query.isDeleted = true;
                        query.query = '';
                        query.title = '';
                        query.checked = false;
                    }
                });
            }

            return update(state, {
                adminPreferences: { [segment]: { adminCustomNewsQuery: { $set: customNewsQuery } } },
            });
        }

        case EDIT_USER_PREFS_CUSTOM_NEWS_QUERY: {
            let { segment, query, queryName, queryTitle } = action.payload;

            let customNewsQuery = state.preferences[segment]
                ? cloneDeep(state.preferences[segment].customNewsQuery)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                customNewsQuery.forEach((customQuery) => {
                    if (customQuery.name === queryName) {
                        customQuery.query = query;
                        customQuery.title = queryTitle;
                    }
                });
            }

            return update(state, { preferences: { [segment]: { customNewsQuery: { $set: customNewsQuery } } } });
        }
        case EDIT_USER_PREFS_LN_CUSTOM_NEWS_QUERY: {
            const { segment, query, queryName, queryTitle } = action.payload;
            const customNewsQuery = state.preferences[segment]
                ? cloneDeep(state.preferences[segment].lnCustomNewsQueries)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                const modifiedLnCustomQuery = customNewsQuery.find((lnQuery) => lnQuery.name === queryName);

                if (modifiedLnCustomQuery) {
                    modifiedLnCustomQuery.query = query;
                    modifiedLnCustomQuery.title = queryTitle;
                }
            }

            return update(state, { preferences: { [segment]: { lnCustomNewsQueries: { $set: customNewsQuery } } } });
        }

        case EDIT_ADMIN_PREFS_CUSTOM_NEWS_QUERY: {
            let { segment, query, queryName, queryTitle } = action.payload;

            let customNewsQuery = state.adminPreferences[segment]
                ? cloneDeep(state.adminPreferences[segment].adminCustomNewsQuery)
                : [];

            if (customNewsQuery && customNewsQuery.length > 0) {
                customNewsQuery.forEach((customQuery) => {
                    if (customQuery.name === queryName) {
                        customQuery.query = query;
                        customQuery.title = queryTitle;
                    }
                });
            }

            return update(state, {
                adminPreferences: { [segment]: { adminCustomNewsQuery: { $set: customNewsQuery } } },
            });
        }

        case ADD_USER_PREFS_CUSTOM_NEWS_QUERY: {
            let { segment, query, queryTitle } = action.payload;

            let customNewsQuery = state.preferences[segment]
                ? cloneDeep(state.preferences[segment].customNewsQuery)
                : [];

            let indexOfFirstDeletedQuery = findIndex(customNewsQuery, ['isDeleted', true]);
            let firstDeletedQuery = customNewsQuery[indexOfFirstDeletedQuery];

            customNewsQuery.splice(indexOfFirstDeletedQuery, 1);

            firstDeletedQuery.title = queryTitle;
            firstDeletedQuery.query = query;
            firstDeletedQuery.checked = true;
            firstDeletedQuery.isDeleted = false;

            customNewsQuery.push(firstDeletedQuery);

            return update(state, { preferences: { [segment]: { customNewsQuery: { $set: customNewsQuery } } } });
        }

        case ADD_ADMIN_PREFS_CUSTOM_NEWS_QUERY: {
            let { segment, query, queryTitle } = action.payload;

            let customNewsQuery = state.adminPreferences[segment]
                ? cloneDeep(state.adminPreferences[segment].adminCustomNewsQuery)
                : [];

            let indexOfFirstDeletedQuery = findIndex(customNewsQuery, ['isDeleted', true]);
            let firstDeletedQuery = customNewsQuery[indexOfFirstDeletedQuery];

            customNewsQuery.splice(indexOfFirstDeletedQuery, 1);

            firstDeletedQuery.title = queryTitle;
            firstDeletedQuery.query = query;
            firstDeletedQuery.checked = true;
            firstDeletedQuery.isDeleted = false;

            customNewsQuery.push(firstDeletedQuery);

            return update(state, {
                adminPreferences: { [segment]: { adminCustomNewsQuery: { $set: customNewsQuery } } },
            });
        }

        case UPDATE_ADMIN_CHECKED_LANGUAGES_UPON_KEY: {
            let customQuery = [];
            let availableQueryLanguages = cloneDeep(state.preferences.generalSettings.availableQueryLanguages);

            if (action.payload.type === COMPANY_CHECK) {
                customQuery = cloneDeep(state.adminPreferences.companyCheck.customQuery);
            } else if (action.payload.type === PERSON_CHECK) {
                customQuery = cloneDeep(state.adminPreferences.personCheck.customQuery);
            }

            customQuery.forEach((language) => {
                language.checked =
                    action.payload.languages.indexOf(language.language) > -1 &&
                    availableQueryLanguages.indexOf(language.language) > -1;
            });

            return update(state, {
                adminPreferences: { [action.payload.type]: { customQuery: { $set: customQuery } } },
            });
        }

        case UPDATE_USER_COST_CODE: {
            return update(state, { preferences: { generalSettings: { selectedCostCode: { $set: action.payload } } } });
        }

        case UPDATE_ADMIN_COST_CODE_IS_REQUIRED: {
            return update(state, {
                adminPreferences: { generalSettings: { isCostCodeRequired: { $set: action.payload } } },
            });
        }

        case UPDATE_ADMIN_COST_CODE_ONLY_FROM_COST_CODE_LIST: {
            return update(state, {
                adminPreferences: { generalSettings: { chooseOnlyFromCostCodesList: { $set: action.payload } } },
            });
        }

        case ADD_COST_CODE_TO_ADMIN_LIST: {
            let costCodesList = cloneDeep(state.adminPreferences.generalSettings.costCodesList);
            if (costCodesList && costCodesList.length > 0) {
                let tempCostCodesList = utils.createArrayWithEmptyValues(costCodesList);

                if (tempCostCodesList && tempCostCodesList.length === 0) {
                    costCodesList.unshift(action.payload);
                    return update(state, {
                        adminPreferences: { generalSettings: { costCodesList: { $set: costCodesList } } },
                    });
                } else {
                    let index = costCodesList.findIndex((costCode) => {
                        return costCode === ' ';
                    });
                    return update(state, {
                        adminPreferences: { generalSettings: { costCodesList: { [index]: { $set: action.payload } } } },
                    });
                }
            } else {
                costCodesList.unshift(action.payload);
                return update(state, {
                    adminPreferences: { generalSettings: { costCodesList: { $set: costCodesList } } },
                });
            }
        }

        case DELETE_COST_CODE_FROM_ADMIN_LIST: {
            let index = state.adminPreferences.generalSettings.costCodesList.indexOf(action.payload);

            if (index > -1) {
                let costCodesList = cloneDeep(state.adminPreferences.generalSettings.costCodesList);
                costCodesList[index] = ' ';
                return update(state, {
                    adminPreferences: { generalSettings: { costCodesList: { $set: costCodesList } } },
                });
            } else {
                return state;
            }
        }

        case HIDE_LOGIN_HISTORY_BANNER: {
            let loginHistoryData = cloneDeep(state.loginHistoryData);
            loginHistoryData.showLoginHistoryBanner = false;

            return update(state, { loginHistoryData: { $set: loginHistoryData } });
        }

        case UPDATE_CONTENT_TYPES: {
            return update(state, { preferences: { generalSettings: { contentTypes: { $set: action.payload } } } });
        }

        case UPDATE_ADMIN_CONTENT_TYPES: {
            return update(state, { adminPreferences: { generalSettings: { contentTypes: { $set: action.payload } } } });
        }

        case UPDATE_USER_PREFS_IS_DIRTY: {
            return update(state, { preferences: { isDirty: { $set: action.payload } } });
        }

        case UPDATE_ADMIN_PREFS_IS_DIRTY: {
            return update(state, { adminPreferences: { isDirty: { $set: action.payload } } });
        }

        case UPDATE_ADMIN_COMPANY_SOURCES_SEARCH_OPTION: {
            let option = action.payload.option;
            return update(state, { adminPreferences: { companyCheck: { searchSpecifically: { $set: option } } } });
        }

        case UPDATE_COMPANY_SOURCES_SEARCH_OPTION: {
            let option = action.payload.option;
            return update(state, { preferences: { companyCheck: { searchSpecifically: { $set: option } } } });
        }

        case UPDATE_ADMIN_LEGAL_SEARCH_NAME_PARTY_ENTITY: {
            let option = action.payload.option;
            return update(state, {
                adminPreferences: { generalSettings: { legalSources: { searchNamePartyEntity: { $set: option } } } },
            });
        }

        case UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY: {
            const option = action.payload.option;
            return update(state, {
                preferences: { generalSettings: { legalSources: { searchNamePartyEntity: { $set: option } } } },
            });
        }

        case USER_SET_SOURCES_INFORMATION: {
            return update(state, { sourcesInformation: { $set: action.payload } });
        }

        case USER_UPDATE_PREFERENCE: {
            const { preference, value } = action.payload;
            return update(state, { preferences: { [preference]: { $set: value } } });
        }

        case ADMIN_UPDATE_CONTENT_OPTIONS_REPORT_BUILDER: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_UPDATE_CONTENT_OPTIONS_REPORT_BUILDER,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }

            let { key, checked } = action.payload;
            let contentsOptions = cloneDeep(state.adminPreferences.contentsOptions);
            contentsOptions[key] = checked;
            return update(state, { adminPreferences: { contentsOptions: { $set: contentsOptions } } });
        }

        case UPDATE_CONTENT_OPTIONS_REPORT_BUILDER: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_CONTENT_OPTIONS_REPORT_BUILDER,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            let { key, checked } = action.payload;
            let contentsOptions = cloneDeep(state.preferences.contentsOptions);
            contentsOptions[key] = checked;
            return update(state, { preferences: { contentsOptions: { $set: contentsOptions } } });
        }
        case ADMIN_UPDATE_CONTENT_OPTIONS_RESULTS: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_UPDATE_CONTENT_OPTIONS_RESULTS,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            let { key, checked } = action.payload;
            let contentsOptions = cloneDeep(state.adminPreferences.resultsContentOptions);
            contentsOptions[key] = checked;
            return update(state, { adminPreferences: { resultsContentOptions: { $set: contentsOptions } } });
        }

        case UPDATE_CONTENT_OPTIONS_RESULTS: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_CONTENT_OPTIONS_RESULTS,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            let { key, checked } = action.payload;
            let contentsOptions = cloneDeep(state.preferences.resultsContentOptions);
            contentsOptions[key] = checked;
            return update(state, { preferences: { resultsContentOptions: { $set: contentsOptions } } });
        }

        case ADMIN_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            let { checked } = action.payload;
            return update(state, { adminPreferences: { startEachArticleOnNewPage: { $set: checked } } });
        }

        case USER_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            let { checked } = action.payload;
            return update(state, { preferences: { startEachArticleOnNewPage: { $set: checked } } });
        }

        case ADMIN_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { adminPreferences: { maxDocumentsInReportCategory: { $set: action.payload } } });
        }

        case USER_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + USER_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            return update(state, { preferences: { maxDocumentsInReportCategory: { $set: action.payload } } });
        }

        case TOGGLE_MODIFIED_BY_USER: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + TOGGLE_MODIFIED_BY_USER,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }

            let { status, key } = action.payload;

            return update(state, { preferences: { modifiedByUser: { [key]: { $set: status } } } });
        }

        case TOGGLE_DELIVERY_OPTIONS_DISPLAY_PREFERENCE: {
            if (action.payload == undefined) {
                errorUtils.logAppError(
                    'Missing parameter in ' + TOGGLE_DELIVERY_OPTIONS_DISPLAY_PREFERENCE,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            let { status, key } = action.payload;

            return update(state, { preferences: { [key]: { $set: status } } });
        }

        case UPDATE_EXCLUDE_NON_BUSINESS_NEWS: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_EXCLUDE_NON_BUSINESS_NEWS,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            const isExcludeNonBusinessNews = action.payload;
            return update(state, {
                preferences: { generalSettings: { excludeNonBusinessNews: { $set: isExcludeNonBusinessNews } } },
            });
        }

        case ADMIN_UPDATE_EXCLUDE_NON_BUSINESS_NEWS: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_UPDATE_EXCLUDE_NON_BUSINESS_NEWS,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            const isExcludeNonBusinessNews = action.payload;
            return update(state, {
                adminPreferences: { generalSettings: { excludeNonBusinessNews: { $set: isExcludeNonBusinessNews } } },
            });
        }

        case UPDATE_EXCLUDE_NEWS_WIRES: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_EXCLUDE_NEWS_WIRES,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            const isExcludeNewsWires = action.payload;
            return update(state, {
                preferences: { generalSettings: { excludeNewsWires: { $set: isExcludeNewsWires } } },
            });
        }

        case ADMIN_UPDATE_EXCLUDE_NEWS_WIRES: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + ADMIN_UPDATE_EXCLUDE_NEWS_WIRES,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );
                return state;
            }

            const isExcludeNewsWires = action.payload;
            return update(state, {
                adminPreferences: { generalSettings: { excludeNewsWires: { $set: isExcludeNewsWires } } },
            });
        }

        case TOGGLE_USE_NEW_RESEARCH_SUMMARY: {
            if (typeof action.payload !== 'boolean') {
                errorUtils.logAppError(
                    'Missing parameter in ' + TOGGLE_USE_NEW_RESEARCH_SUMMARY,
                    { 'toggle state': action.payload },
                    APP_MODULE
                );

                return state;
            }

            const status = action.payload;

            return update(state, { useNewResearchSummary: { $set: status } });
        }

        case SHOULD_UPDATE_TIMESTAMP_INFO: {
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + SHOULD_UPDATE_TIMESTAMP_INFO,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            const lastPreferencesUpdateInfo = action.payload;
            return update(state, { lastPreferencesUpdateInfo: { $set: lastPreferencesUpdateInfo } });
        }

        case UPDATE_LAST_PREFERENCES_RETRIEVAL_TIMESTAMP: {
            if (!action.payload) {
                errorUtils.logAppError(
                    'Missing parameter in ' + UPDATE_LAST_PREFERENCES_RETRIEVAL_TIMESTAMP,
                    {
                        actionPayload: action.payload,
                    },
                    APP_MODULE
                );
                return state;
            }
            const lastUserPreferencesRetrieval = action.payload;
            return update(state, {
                preferences: { lastUserPreferencesRetrieval: { $set: lastUserPreferencesRetrieval } },
            });
        }

        case UPDATE_RISK_SCORES: {
            if (!action.payload) {
                return state;
            }

            return update(state, {
                preferences: {
                    generalSettings: {
                        displayRiskScores: { $set: action.payload },
                    },
                },
            });
        }

        case UPDATE_EXPANDED_FILTER_SECTION: {
            const expandedFiltersSection = action.payload;

            return update(state, {
                preferences: {
                    resultListPage: { expandedFiltersSection: { $set: expandedFiltersSection } }
                },
            });
        }
        case UPDATE_SANCTIONS_SOURCES_RISKS: {
            const sanctionsSources = action.payload;

            return update(state, {
                preferences: {
                    generalSettings: { sanctionsSources: { $set: sanctionsSources } }
                },
            });
        }
        case ADMIN_UPDATE_SANCTIONS_SOURCES_RISKS: {
            const sanctionsSources = action.payload;

            return update(state, {
                adminPreferences: {
                    generalSettings: { sanctionsSources: { $set: sanctionsSources } }
                },
            });
        }
        case PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS: {
            let excludeLowQualityDocuments = action.payload;
            return update(state, { preferences: { generalSettings: { excludeLowQualityDocuments: { $set: excludeLowQualityDocuments } } } });
        }
        case ADMIN_PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS: {
            let excludeLowQualityDocuments = action.payload;
            return update(state, { adminPreferences: { generalSettings: { excludeLowQualityDocuments: { $set: excludeLowQualityDocuments } } } });
        }
        default:
            return state;
    }
}

export function spinners(state = false, action) {
    // Check if the specified spinner exists and if not, set it to "mainSpinner"
    let spinner = typeof action.payload === 'string' && state[action.payload] ? action.payload : 'mainSpinner',
        spinnerChanges = {};

    spinnerChanges[spinner] = null;

    switch (action.type) {
        case SHOW_SPINNER: {
            if (spinner === 'mainSpinner') {
                /* The main spinner is the only one that has a "count" property. The count property is used so that
                 * asynchronous hide spinner commands close the spinner only in case the count decreases to 0.
                 * Thus, the issue of the loading spinner getting closed too early is prevented.
                 */
                spinnerChanges[spinner] = {
                    count: state.mainSpinner.count + 1,
                };
            } else {
                spinnerChanges[spinner] = true;
            }

            return Object.assign({}, state, spinnerChanges);
        }

        case HIDE_SPINNER: {
            if (spinner === 'mainSpinner') {
                spinnerChanges[spinner] = {
                    count: state.mainSpinner.count === 0 ? 0 : state.mainSpinner.count - 1,
                };
            } else {
                spinnerChanges[spinner] = false;
            }

            return Object.assign({}, state, spinnerChanges);
        }

        case REMOVE_ALL_SPINNERS:
            return { mainSpinner: { count: 0 }, fullPageSpinner: false };

        case SHOW_FULL_SPINNER:
            return Object.assign({}, state, { fullPageSpinner: true });

        case HIDE_FULL_SPINNER:
            return Object.assign({}, state, { fullPageSpinner: false });

        default:
            return state;
    }
}

export function fuzzyNames(state = null, action) {
    switch (action.type) {
        case UPDATE_FUZZY_NAMES: {
            return action.payload;
        }
        case RESET_FUZZY_NAMES:
            let queue = DilQueue();
            queue.abortCallByUrl(URL_FUZZY);
            return { list: [], query: '' };

        case REMOVE_FUZZY_NAME: {
            let fuzzyNames = Object.assign([], state.list);
            let index = fuzzyNames.indexOf(action.payload);
            if (index > -1) {
                fuzzyNames.splice(index, 1);
            }
            return Object.assign({}, state, { list: fuzzyNames });
        }

        case TOGGLE_FUZZY_SELECTION: {
            let fuzzyNames = cloneDeep(state.list);
            let { fuzzyName, selected, clickedIndex } = action.payload;
            // gets all indexes of fuzzy names with the name matching the clicked fuzzy
            let indexes = keys(pickBy(fuzzyNames, { name: fuzzyName.name }));
            // if the clicked index is found in de list of possible indexes, mark the clicked fuzzy as selected
            if (includes(indexes, clickedIndex.toString())) {
                fuzzyNames[clickedIndex].selected = selected;
            }
            return update(state, { list: { $set: fuzzyNames } });
        }

        case TOGGLE_ALL_FUZZY_SELECTION: {
            let fuzzyNames = cloneDeep(state.list);
            let { visibleFuzzyNames, selected } = action.payload;

            for (let i = 0; i < visibleFuzzyNames.length; i++) {
                if (fuzzyNames[i]) {
                    fuzzyNames[i].selected = selected;
                }
            }

            return update(state, { list: { $set: fuzzyNames } });
        }
        default:
            return state;
    }
}

export function searchState(state = null, action) {
    switch (action.type) {
        case SEARCH_LAUNCHED_FROM:
            return {
                ...state,
                launchedFrom: action.payload,
            };
        case RESET_LAUNCH_FROM:
            return {
                ...state,
                launchedFrom: action.payload,
            };
        case SET_ENTITY_ID:
            return {
                ...state,
                entityId: action.payload,
            };
        case SET_ALL_ENTITIES_SELECTED:
            return {
                ...state,
                allEntitiesSelected: action.payload,
            };
        case SHOULD_UPDATE_ENTITIES_COLUMNS_FROM_CONTENT_TYPES:
            return {
                ...state,
                shouldUpdateEntitiesColumnsFromContentTypes: action.payload,
            };
        case SET_SNAPSHOT_DELIVERY:
            return {
                ...state,
                snapshotDelivery: action.payload,
            };
        default:
            return state;
    }
}

export function entityViewInformation(state = null, action) {
    switch (action.type) {
        case USER_SET_ENTITY_VIEW_INFORMATION: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                entitiesCountNeedsUpdate: action.payload,
            };
        }
        case UPDATE_BATCH_QUEUE: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                batchQueue: action.payload,
            };
        }
        case UPDATE_BLOCKED_ENTITIES: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                blockedEntities: action.payload,
            };
        }
        case SET_SHOULD_GET_ALL_VIEWS: {
            if (action.payload === null || action.payload === undefined) {
                return state;
            }
            return {
                ...state,
                shouldGetAllViews: action.payload,
            };
        }
        case SET_CURRENT_ENTITY: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                currentEntity: action.payload,
            };
        }
        case RESET_CURRENT_ENTITY: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                currentEntity: action.payload,
            };
        }
        case SET_UPLOAD_ENTITY_VIEW_POLLING: {
            return {
                ...state,
                uploadPollingActive: action.payload,
            };
        }
        case SET_ENTITY_VIEW_TABLE_HAS_DATA: {
            return {
                ...state,
                tableHasData: action.payload,
            };
        }
        case SET_ENTITY_VIEW_COUNT: {
            if (action.payload === null || action.payload === undefined) {
                return state;
            }
            return {
                ...state,
                entitiesCount: action.payload,
            };
        }
        case SET_ENTITY_VIEW_DOWNLOAD_REPORTS_POLLING: {
            return {
                ...state,
                downloadReportsPollingActive: action.payload,
            };
        }
        case UPDATE_ENTITIES_ID_QUEUE: {
            if (!action.payload) {
                return state;
            }
            return {
                ...state,
                entitiesIdQueue: action.payload,
            };
        }
        default:
            return state;
    }
}

export function notificationStatus(state = null, action) {
    switch (action.type) {
        case ADD_NEW_BATCH_ID: {
            if (!action.payload || Object.keys(action.payload).length === 0 || !action.payload.keyInTheStore) {
                console.error(`${ADD_NEW_BATCH_ID} action has no payload or payload is empty.`)
                return state;
            }
            return {
                ...state,
                [action.payload.keyInTheStore]: {
                    batchIdsList: {
                        ...state[action.payload.keyInTheStore]?.batchIdsList,
                        [action.payload.batchId]: action.payload.data,
                    },
                    hidden: false,
                },
            };
        }
        case SANITIZE_NOTIFICATIONS: {
            if (!action.payload || Object.keys(action.payload).length === 0){
                return state;
            }

            return {
                ...state,
                ...action.payload
            }; 
        }
        case RESET_NOTIFICATION: {
            if (!action.payload?.keyInTheStore) {
                console.error(`${RESET_NOTIFICATION} action has no payload or payload is empty.`)
                return state;
            }
            return {
                ...state,
                [action.payload.keyInTheStore]: {
                    batchIdsList: {},
                    hidden: true,
                }
            };
        }
        case UPDATE_BATCH_ID: {
            if (!action.payload || Object.keys(action.payload).length === 0 || !action.payload.keyInTheStore) {
                console.error(`${UPDATE_BATCH_ID} action with key has no payload or payload is empty.`)
                return state;
            }
            
            return {
                ...state,
                [action.payload.keyInTheStore]: {
                    batchIdsList: {
                        ...state[action.payload.keyInTheStore]?.batchIdsList,
                        [action.payload.batchId]: {
                            ...state[action.payload.keyInTheStore]?.batchIdsList[action.payload.batchId],
                            ...action.payload.data
                        },
                    },
                    hidden: false,
                },
            };
        }
        case CLOSE_ALL_NOTIFICATIONS: {
            if (!action.payload) {
                return state;
            }

            const newState = cloneDeep(state);
            for (let notificationType in newState) {
                if(action.payload.notificationsToClose?.includes(notificationType)) {
                    newState[notificationType].hidden = true;
                }
            }
            return {
                ...state,
                ...newState
            }
        }
        case CLOSE_NOTIFICATION: {
            const notificationType = action.payload;

            if(!notificationType || !state[notificationType]) {
                return state;
            }
            return {
                ...state,
                [notificationType]: {
                    ...state[notificationType],
                    hidden: true,
                }
            }
        }
        default:
            return state;
    }
}

export function notificationDisplayOrder(state={}, action) {
    switch(action.type){
        case UPDATE_NOTIFICATION_DISPLAY_ORDER: {
            const newOrder = action.payload;
            return {
                ...state,
                order: newOrder
            }
        }
        default:
            return state;
    }
}

const baseReducer = combineReducers({
    user,
    header,
    spinners,
    popupMessages,
    searchStatus,
    searchState,
    breadcrumbs,
    backgroundMessages,
    searchParams,
    // added this because searchResults is not recognised in the Jest test.
    // import serachResults is not working in tests, but if we would use import {searchResults} it would work
    // TODO: search for a proper solution in the future
    searchResults: searchResults || ((state = {}) => state),
    editSearch,
    currentReport,
    reportBuilder,
    articlesManager,
    userPreferences,
    popupModel,
    scrollingPage,
    fuzzyNames,
    suggestedNames,
    alerts,
    postFilterConfiguration: postFilterConfiguration || ((state = {}) => state),
    adHocSearch,
    searchBar,
    selectedAlerts,
    historyCategory,
    investigation,
    metrics,
    articleNavigation,
    ubo,
    negativeNewsVisualisation,
    sanctionsRisk,
    entityViewInformation,
    notificationStatus,
    notificationDisplayOrder,
});

export default baseReducer;
