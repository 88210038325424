import React, { Fragment } from 'react';
import withAutosave from '../HOC/WithAutosave';
import { FE_LOCK_NAMES } from '@constants';
import { FormattedMessage } from 'react-intl';
import AdminLock from '@reusable/AdminLock/AdminLock';

const SearchResults = (props) => {
    const handleShowSnapshot = (event) => {
        props.handleAutosave(props.handleShowSnapshot, [event]);
    };

    const handleExclusionOfLowQualityDocuments = (event) => {
        props.handleAutosave(props.handleExclusionOfLowQualityDocuments, [event]);
    };

    return (
        <Fragment>
            <div className="container-section">
                <div className="user-preferences-category-container__row">
                    <h3 className="user-preferences-category-container__topic user-preferences-search-results">
                        <FormattedMessage id="UserPreferences_topic_show_snapshot" />
                    </h3>
                    {props.autoSaveMessage}
                    {(props.userIsAdmin || props.searchResultsLock) && (
                        <AdminLock
                            lockName={FE_LOCK_NAMES.searchResults}
                            onClickLock={props.onClickLock}
                            isSectionLock={true}
                            isLocked={props.searchResultsLock}
                            isDisabled={!props.userIsAdmin && props.searchResultsLock}
                        />
                    )}
                </div>

                <div className="toggle-snapshot">
                    <div className="custom-toggle">
                        <div
                            className={
                                !props.userIsAdmin && props.searchResultsLock
                                    ? 'custom-toggle disabled'
                                    : 'custom-toggle'
                            }
                        >
                            <input
                                id={'user-preferences-show-snapshot'}
                                className="custom-toggle-checkbox"
                                type="checkbox"
                                title={'Show Snapshot results view'}
                                checked={props.showSnapshot === true ? 'checked' : ''}
                                onChange={handleShowSnapshot}
                                data-testid={`user-preferences-show-snapshot-${props.showSnapshot ? 'enabled' : 'disabled'}`}
                            />
                            <label htmlFor={'user-preferences-show-snapshot'} className="custom-toggle-label"></label>
                        </div>
                    </div>
                    <span>
                        <FormattedMessage id={'UserPreferences_show_snapshot'} />
                    </span>
                </div>

                <div className="toggle-exclude-low-quality-documents">
                    <div className="custom-toggle">
                        <div
                            className={
                                !props.userIsAdmin && props.searchResultsLock
                                    ? 'custom-toggle disabled'
                                    : 'custom-toggle'
                            }
                        >
                            <input
                                id={'user-preferences-exclude-low-quality-documents'}
                                className="custom-toggle-checkbox"
                                type="checkbox"
                                title={'Exclude low quality documents'}
                                checked={props.excludeLowQualityDocuments === true ? 'checked' : ''}
                                onChange={handleExclusionOfLowQualityDocuments}
                                data-testid={`user-preferences-exclude-low-quality-documents-${props.excludeLowQualityDocuments ? 'enabled' : 'disabled'}`}
                            />
                            <label htmlFor={'user-preferences-exclude-low-quality-documents'} className="custom-toggle-label"></label>
                        </div>
                    </div>
                    <span>
                        <FormattedMessage id={'UserPreferences_exclude_low_quality_documents'} />
                    </span>
                </div>
            </div>
        </Fragment>
    );
};

export default withAutosave()(SearchResults);
