import React from 'react';
import {
    BREADCRUMBS,
    CATEGORIES_ORDER,
    CATEGORY_NAMES,
    DELIVERY_CONTENT_OPTIONS,
    START_ARTICLE_ON_NEW_PAGE,
    USER_PREFERENCES_SECTIONS,
    LEGAL_SOURCES_ALL_OF_THEM,
    COMPANY_CHECK_NEWS_LANGUAGES_SELECTION,
    PERSON_CHECK_NEWS_LANGUAGES_SELECTION,
    HELP_URLS,
    USER_PREFERENCES_PAGE_TITLE,
    SANCTIONS_WATCHLISTS_SECTION,
    COMPONENT_OFFSET,
} from '@constants';
import { COMPANY_CHECK, DELIVERY_SETTINGS, GENERAL_SETTINGS, PERSON_CHECK } from '../Main/Main.reducers';
import userPreferenceApi from './UserPreferencesApi.api';
import utils from '@utils/utilities';
import breadcrumbsActions from '@reusable/Breadcrumbs/redux/Breadcrumbs.actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import actions from './redux/UserPreferences.actions';
import backgroundActions from '@reusable/BackgroundMessage/redux/BackgroundMessages.actions';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import { bindActionCreators, compose } from 'redux';
import { cloneDeep, debounce, findIndex, uniqBy } from 'lodash';
import LeftSideNavigation from './components/LeftSideNavigation';
import GeneralSettingsContainer from './components/GeneralSettingContainer';
import ConfirmationModal from '@reusable/ConfirmationModal/ConfirmationModal';
import PopupModal from '@reusable/PopupModal/PopupModal.index';
import BreadCrumbsModel from '@utils/breadCrumbBuilder';
import mainActions from '@api/Common.actions';
import { FormattedMessage, injectIntl } from 'react-intl';
import categoryUtils from '@utils/categoryUtils';
import PersonCheck from './components/PersonCheck';
import CompanyCheck from './components/CompanyCheck';
import xhr from '@utils/xhr';
import formatRichMessage from '@utils/formatRichMessage';
import DilQueue, { queueSettings } from '@utils/promiseQueue';
import AdminLockContextProvider from '@reusable/AdminLock/context/AdminLockContextProvider';
import { withAppContext } from '@utils/contexts';
import DeliverySettings from './components/DeliverySettings';
import withPreferenceRefresh from '../Main/hoc/withPreferenceRefresh';

export class UserPreferences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            confirmationModalTitle: '',
            confirmationModalDescription: '',
            personAvailableLanguages: [],
            companyAvailableLanguages: [],
            shouldRefreshPrefs: true,
            personNegativityLevels: [],
            companyNegativityLevels: [],
            sanctionsSectionOffset: null,
        };

        this.handleScrollToPage = this.handleScrollToPage.bind(this);
        this.debounceScrolling = this.debounceScrolling.bind(this);
        this.getSearchSources = this.getSearchSources.bind(this);
        this.handleSourceSelection = this.handleSourceSelection.bind(this);
        this.handleShowSnapshot = this.handleShowSnapshot.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.changeDateRange = this.changeDateRange.bind(this);
        this.showPopupModal = this.showPopupModal.bind(this);
        this.closePopupModal = this.closePopupModal.bind(this);
        this.handleSelectFuzzyMatch = this.handleSelectFuzzyMatch.bind(this);
        this.handleFuzzyEnabled = this.handleFuzzyEnabled.bind(this);
        this.setReportBuilderContentOrder = this.setReportBuilderContentOrder.bind(this);
        this.changeProximity = this.changeProximity.bind(this);
        this.changeQueryPerson = this.changeQueryPerson.bind(this);
        this.changeQueryCompany = this.changeQueryCompany.bind(this);
        this.changeLegalSource = this.changeLegalSource.bind(this);
        this.handleShowSourcesWarningModal = this.handleShowSourcesWarningModal.bind(this);
        this.getReportBuilderSources = this.getReportBuilderSources.bind(this);
        this.globalEventHandler = [];
        this.handleUMError = this.handleUMError.bind(this);
        this.setLeftNavigationTopOffset = this.setLeftNavigationTopOffset.bind(this);
        this.handleUserClickNotice = this.handleUserClickNotice.bind(this);
        this.autosave = this.autosave.bind(this);
        this.setPageDirtiness = this.setPageDirtiness.bind(this);
        this.savePreferences = this.savePreferences.bind(this);
        this.changeMade = this.changeMade.bind(this);
        this.checkCustomNewsSourceAvailability = this.checkCustomNewsSourceAvailability.bind(this);
        this.getAvailableQueries = this.getAvailableQueries.bind(this);
        this.getCustomNewsDisabledStatus = this.getCustomNewsDisabledStatus.bind(this);
        this.onDeliverySettingsChange = this.onDeliverySettingsChange.bind(this);
        this.handleMaxDocumentsPerCategoryInReport = this.handleMaxDocumentsPerCategoryInReport.bind(this);
        this.handleExclusionOfLowQualityDocuments = this.handleExclusionOfLowQualityDocuments.bind(this);
    }

    setBreadcrumbs() {
        let items = this.props.breadcrumbs.items;
        let breadcrumbs = new BreadCrumbsModel.Builder(BREADCRUMBS.PREFERENCES).build().breadcrumbs;
        if (items && JSON.stringify(items) !== JSON.stringify(breadcrumbs)) {
            this.props.updateBreadcrumbs(breadcrumbs);
        }
    }

    registerEvents(target, type, callback) {
        target.addEventListener(type, callback);
        this.globalEventHandler.push({ target, type, callback });
    }

    unregisterEvents() {
        this.globalEventHandler.forEach((event) => {
            event.target.removeEventListener(event.type, event.callback);
        });
    }

    UNSAFE_componentWillMount() {
        this.originalPreferences = cloneDeep(this.props.allPreferences);
        this.unregisterLeaveHook = this.props.router.setRouteLeaveHook(
            this.props.route,
            this.routerWillLeave.bind(this)
        );
        const legalSources = [LEGAL_SOURCES_ALL_OF_THEM, ...this.props.lawSources];
        const legal = this.props.sources.find((source) => source.name === CATEGORY_NAMES.LAW_SOURCES);
        const personAvailableLanguages = cloneDeep(
            this.filterAvailableLanguages(this.props.personLanguages, this.props.availableQueryLanguages)
        );
        const companyAvailableLanguages = cloneDeep(
            this.filterAvailableLanguages(this.props.companyLanguages, this.props.availableQueryLanguages)
        );
        const personNegativityLevels = this.props.personNegativityLevels;
        const companyNegativityLevels = this.props.companyNegativityLevels;

        this.setState({
            isLegalChecked: legal ? legal.value : false,
            legalSource: this.props.lawSource,
            legalSources,
            personAvailableLanguages,
            companyAvailableLanguages,
            personNegativityLevels,
            companyNegativityLevels,
        });

        this.modifiedSections = [];
        this.dirtyInterval = 0;
    }

    componentDidMount() {
        document.title = USER_PREFERENCES_PAGE_TITLE;

        window.scrollTo(0, 0);
        this.scrollToSection();
        this.setBreadcrumbs();
        this.setLeftNavigationTopOffset();
        this.registerEvents(document, 'scroll', debounce(this.debounceScrolling, 100));
        this.updatePreferencesIfNeeded();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.personLanguages !== this.props.personLanguages) {
            const personAvailableLanguages = cloneDeep(
                this.filterAvailableLanguages(this.props.personLanguages, this.props.availableQueryLanguages)
            );

            this.setState({
                personAvailableLanguages,
            });
        }

        if (prevProps.companyLanguages !== this.props.companyLanguages) {
            const companyAvailableLanguages = cloneDeep(
                this.filterAvailableLanguages(this.props.companyLanguages, this.props.availableQueryLanguages)
            );

            this.setState({
                companyAvailableLanguages,
            });
        }

        if (prevProps.personNegativityLevels !== this.props.personNegativityLevels) {
            this.setState({
                personNegativityLevels: this.props.personNegativityLevels,
            });
        }

        if (prevProps.companyNegativityLevels !== this.props.companyNegativityLevels) {
            this.setState({
                companyNegativityLevels: this.props.companyNegativityLevels,
            });
        }
    }

    componentWillUnmount() {
        this.unregisterEvents();
        clearInterval(this.dirtyInterval);
        this.dirtyInterval = 0;
        if (typeof this.unregisterLeaveHook === 'function') {
            this.unregisterLeaveHook();
        }
    }

    setLeftNavigationTopOffset() {
        // eslint-disable-next-line
        const element = this.refs.generalSettings;
        let topOffset = element.offsetTop;

        this.setState({
            leftNavigationTop: topOffset,
        });
    }

    updatePreferencesIfNeeded = () => {
        return this.props.checkForUpdatesAndUpdatePreferences(this.originalPreferences.payload);
    };

    changeMade(changedSection) {
        if (!this.props.context.isUserMIP) {
            this.modifiedSections.push(changedSection);
            this.setPageDirtiness();
            clearInterval(this.dirtyInterval);
            this.dirtyInterval = setInterval(() => {
                this.autosave();
            }, 2000);
        }
    }

    autosave() {
        if (this.props.isDirty && !this.props.context.isUserMIP) {
            this.setState({
                modifiedSections: this.modifiedSections,
                modifPrefsNumber: this.modifiedSections.length,
                autosaveHasError: false,
            });
            this.modifiedSections = [];
            // save the changed props as they were before saving, so the comparison with future modified props, to be made to this ones not the ones in state
            this.modifiedPreferences = this.props.allPreferences;
            clearInterval(this.dirtyInterval);

            this.savePreferences()
                .then(() => {
                    this.setCurrentModifiedPrefs(false);
                })
                .catch(() => {
                    this.showErrorMessage('UserPreferences_Notifications.errorUpdatePreferencesMessage');
                    this.handleUMError();
                    this.setCurrentModifiedPrefs(true);
                });
        } else {
            clearInterval(this.dirtyInterval);
        }
    }

    setCurrentModifiedPrefs = (hasError) => {
        let currentModPrefs = cloneDeep(this.state.modifiedSections);
        currentModPrefs.splice(0, this.state.modifPrefsNumber);
        this.originalPreferences = cloneDeep(this.modifiedPreferences);
        this.props.updateIsDirty(false);

        this.setState({
            modifiedSections: currentModPrefs,
            autosaveHasError: hasError,
        });
    };

    setPageDirtiness() {
        this.props.checkIsDirty(this.modifiedSections.length > 0, this.originalPreferences);
    }

    routerWillLeave() {
        let queue = DilQueue();
        let preferencesSaveCall = queue
            .executionPipe()
            .find((item) => item.data.url.indexOf(queueSettings.maximPriorityUrl) > -1);

        if (this.props.isDirty && !preferencesSaveCall && !this.props.context.isUserMIP) {
            this.savePreferences()
                .then(() => this.setPageDirtiness())
                .catch((err) => {
                    switch (err.status) {
                        case 403:
                            xhr.handleAllErrors(err);
                            break;
                        default:
                            this.showErrorMessage('UserPreferences_Notifications.errorUpdatePreferencesMessage');
                    }
                });
        }
        return true;
    }

    savePreferences() {
        //strip out content from prefereces to post back
        let prefCopy = utils.prepareUserPreferencesForSave(cloneDeep(this.props.allPreferences));
        let userLocks = utils.convertUserLocksObjToArr(prefCopy.userLocks);
        delete prefCopy.userLocks;
        delete prefCopy.payload;

        return this.props
            .checkForUpdatesAndUpdatePreferences(this.props.allPreferences.payload, this.state.shouldRefreshPrefs)
            .then((response) => {
                this.setState({ shouldRefreshPrefs: false });
                if (response === null) {
                    return userPreferenceApi
                        .updateUserPreference({ permissions: userLocks, userPreferences: prefCopy })
                        .then(() => utils.updateUserPreferencesPayloadAndTimestamp())
                        .finally(() => this.setState({ shouldRefreshPrefs: true }));
                }

                this.setState({ shouldRefreshPrefs: true });
            });
    }

    scrollToSection() {
        const sectionParam = utils.getUrlQueryParam('section');

        if (sectionParam) {
            this.handleScrollToPage(sectionParam);
        }
    }

    debounceScrolling() {
        let elements = [GENERAL_SETTINGS, COMPANY_CHECK, PERSON_CHECK, DELIVERY_SETTINGS];
        let item,
            activeTab = { name: GENERAL_SETTINGS, visible: 0 },
            HEADER_HEIGHT = 64,
            FOOTER_HEIGHT = 69,
            PAGE_HEIGHT = document.body.offsetHeight;
        // eslint-disable-next-line
        var refs = this.refs;
        elements.forEach((element) => {
            item = refs[element];
            if (!item) {
                return;
            }
            let visibleTop,
                visibleBottom,
                top = item.getBoundingClientRect().top,
                height = item.getBoundingClientRect().height,
                bottom = item.getBoundingClientRect().bottom;
            if (top > HEADER_HEIGHT && top < PAGE_HEIGHT - FOOTER_HEIGHT) {
                //into view
                visibleTop = top;
            } else if (top < HEADER_HEIGHT) {
                //over header
                visibleTop = bottom > HEADER_HEIGHT ? HEADER_HEIGHT : 0;
            } else {
                //under footer
                visibleTop = 0;
            }
            if (bottom < PAGE_HEIGHT - FOOTER_HEIGHT && bottom > HEADER_HEIGHT) {
                //bottom into view
                visibleBottom = bottom;
            } else if (bottom < HEADER_HEIGHT) {
                //over header
                visibleBottom = 0;
            } else {
                //under footer
                visibleBottom = bottom - height > PAGE_HEIGHT - FOOTER_HEIGHT ? 0 : PAGE_HEIGHT - FOOTER_HEIGHT;
            }

            if (activeTab.visible < (visibleBottom - visibleTop) / height) {
                activeTab.name = element;
                activeTab.visible = (visibleBottom - visibleTop) / height;
            }
        });

        this.props.setActiveTab(activeTab.name);
    }

    setSanctionsSectionOffset = (offsetTop) => {
        this.setState(
            {
                sanctionsSectionOffset: offsetTop,
            },
            () => {
                this.handleScrollToPage(SANCTIONS_WATCHLISTS_SECTION);
            }
        );
    };

    handleScrollToPage(element) {
        /* eslint-disable */
        if (element === GENERAL_SETTINGS) {
            const element = this.refs.generalSettings;
            window.scrollTo(0, element.offsetTop - COMPONENT_OFFSET);
        } else if (element === COMPANY_CHECK) {
            const element = this.refs.companyCheck;
            window.scrollTo(0, element.offsetTop - COMPONENT_OFFSET);
        } else if (element === PERSON_CHECK) {
            const element = this.refs.personCheck;
            window.scrollTo(0, element.offsetTop - COMPONENT_OFFSET);
        } else if (element === DELIVERY_SETTINGS) {
            const element = this.refs.deliverySettings;
            window.scrollTo(0, element.offsetTop - COMPONENT_OFFSET);
        } else if (element === PERSON_CHECK_NEWS_LANGUAGES_SELECTION) {
            const element = this.refs.personCheck;
            window.scrollTo(0, element.offsetTop + COMPONENT_OFFSET);
        } else if (element === COMPANY_CHECK_NEWS_LANGUAGES_SELECTION) {
            const element = this.refs.companyCheck;
            window.scrollTo(0, element.offsetTop + COMPONENT_OFFSET);
        }

        if (element === SANCTIONS_WATCHLISTS_SECTION) {
            const element = this.refs.generalSettings;
            window.scrollTo(0, element.offsetTop + this.state.sanctionsSectionOffset);
        }
        /* eslint-enable */
    }

    handleShowSnapshot(event) {
        let showSnapshot = event.target.checked;
        this.props.updateSnapshotVisibility(showSnapshot);
    }

    handleExclusionOfLowQualityDocuments(event) {
        let excludeLowQualityDocuments = event.target.checked;
        this.props.updateExclusionOfLowQualityDocuments(excludeLowQualityDocuments);
    }

    showErrorMessage(errorMessage) {
        utils.showNotificationsMessage({
            messageText: errorMessage,
            messageType: 'system-error',
        });
    }

    getSearchSources() {
        let sources = this.getCustomNewsDisabledStatus();

        let sourceItems = [];
        sources.forEach((item) => {
            const category = categoryUtils.getCategory(item.name);
            if (category && categoryUtils.isParent(item.name)) {
                sourceItems.push({
                    key: item.name,
                    checked: item.value,
                    name: category.name,
                    show: item.name !== CATEGORY_NAMES.PUBLIC_RECORDS,
                    disabled: item.disabled,
                    order: category.order,
                    isNew: category.isNew,
                    hasInformationIcon: categoryUtils.hasInformationIcon(item.name),
                });
            }
        });

        //deduplicate if is necessary content types and return uniq items
        sourceItems = uniqBy(sourceItems, 'key');
        return utils.sortByProperty(sourceItems, 'order');
    }

    checkCustomNewsSourceAvailability() {
        let tempContentTypes = cloneDeep(this.props.sources);

        let availableQueries = this.getAvailableQueries();

        tempContentTypes.forEach((contentType) => {
            if (contentType.name === CATEGORIES_ORDER.customNews.key) {
                this.handleSourceSelection(availableQueries !== undefined, contentType.name);
            }
        });

        return tempContentTypes;
    }

    getAvailableQueries() {
        let customNewsQueries = [...this.props.personCustomNewsQuery, ...this.props.companyCustomNewsQuery];
        let lnPersonCustomNews = this.props.personLnCustomNewsQuery;
        let lnCompanyCustomNews = this.props.companyLnCustomNewsQuery;
        let adminPersonCustomNews = this.props.adminPersonCustomNewsQuery;
        let adminCompanyCustomNews = this.props.adminCompanyCustomNewsQuery;

        if (lnPersonCustomNews && lnCompanyCustomNews) {
            customNewsQueries.push(...lnPersonCustomNews, ...lnCompanyCustomNews);
        }

        if (adminCompanyCustomNews && adminPersonCustomNews) {
            customNewsQueries.push(...adminPersonCustomNews, ...adminCompanyCustomNews);
        }
        return customNewsQueries.find(
            (customQuery) =>
                customQuery.checked &&
                (customQuery.isDeleted === false || !customQuery.isDeleted) &&
                customQuery.query &&
                customQuery.query.length > 0
        );
    }

    getCustomNewsDisabledStatus() {
        let tempContentTypes = cloneDeep(this.props.sources);
        let availableQueries = this.getAvailableQueries();

        tempContentTypes.forEach((contentType) => {
            if (contentType.name === CATEGORIES_ORDER.customNews.key) {
                contentType.disabled = availableQueries === undefined;
                contentType.value = contentType.value && availableQueries !== undefined;
            }
        });

        return tempContentTypes;
    }

    getReportBuilderSources() {
        let contentTypes = this.getCustomNewsDisabledStatus();

        // filter out legal sources
        contentTypes = contentTypes.filter((item) => {
            let isLawSources = this.props.lawSources.indexOf(item.name);
            return isLawSources === -1;
        });
        contentTypes = utils.sortByProperty(contentTypes, 'reportOrder');
        let disabledContentType = contentTypes.filter((item) => {
            return item.value === false;
        });
        contentTypes = contentTypes.filter((item) => {
            return item.value !== false;
        });
        contentTypes.forEach((item, newOrder) => {
            item.reportOrder = newOrder;
        });

        contentTypes = contentTypes.concat(disabledContentType);
        contentTypes.forEach((item, newOrder) => {
            item.reportOrder = newOrder;
        });

        return contentTypes;
    }

    handleSourceSelection(isSourceChecked, sourceName) {
        const isValidState = this.handleShowSourcesWarningModal(isSourceChecked);
        if (!isValidState) return false;
        //get all sources
        let sources = [sourceName];
        if (sourceName === CATEGORY_NAMES.LAW_SOURCES) {
            sources = sources.concat(this.props.lawSources);
        }

        if (isSourceChecked !== true) {
            this.props.deleteUserPreferencesContentType(sources);
        } else {
            this.props.addUserPreferencesContentType(sources);
        }
    }

    handleShowSourcesWarningModal(isSourceChecked) {
        const checkedSources = this.props.sources.filter((source) => source.value === true);

        let isValid = true;
        if (isSourceChecked !== true) {
            if (checkedSources.length <= 1) {
                this.setState({
                    confirmationModalTitle: 'UserPreferences.popup.sourceSelection',
                    confirmationModalDescription: 'UserPreferences.popup.notificationMessage',
                    showEditModal: true,
                });
                isValid = false;
            }
        }
        return isValid;
    }

    setReportBuilderContentOrder(orderedContentTypes = []) {
        let contentTypes = cloneDeep(this.props.sources) || [];
        let legalSources = contentTypes.filter((item) => {
            return this.props.lawSources.indexOf(item.name) > -1;
        });

        //get lawSourcesIndex
        let lawSourceIndex = findIndex(orderedContentTypes, (item) => {
            return item.name === CATEGORY_NAMES.LAW_SOURCES;
        });
        //insert legalSources items after lawSource to help at resource summary
        orderedContentTypes.splice(lawSourceIndex + 1, 0, ...legalSources);
        //reorder the content before save
        orderedContentTypes.forEach((item, newOrder) => {
            item.reportOrder = newOrder;
        });

        if (orderedContentTypes.length !== contentTypes.length) {
            this.showErrorMessage('UserPreferences_Notifications.errorUpdatePreferencesMessage');
            this.handleUMError();
        } else {
            this.props.updateContentOrderForReportBuilder(orderedContentTypes);
            if (!this.state.userIsAdmin && !this.props.context.isUserMIP) {
                this.changeMade(USER_PREFERENCES_SECTIONS[DELIVERY_SETTINGS].REPORT_BUILDER);
            }
        }
    }

    handleFuzzyEnabled(event) {
        let isFuzzyEnabled = event.target.checked;
        this.props.updateFuzzyFunctionality(isFuzzyEnabled);

        if (!isFuzzyEnabled && this.props.fuzzyList.length > 0) {
            this.props.resetFuzzyNames();
        }
    }

    handleFuzzyToggle = (event) => {
        let isFuzzyEnabled = event.target.checked;
        this.props.updateFuzzyToggle(isFuzzyEnabled);
    };

    handleSuggestedNamesToggle = (event) => {
        let isSuggestedNamesEnabled = event.target.checked;
        this.props.updateSuggestedNamesToggle(isSuggestedNamesEnabled);
    };

    onDeliverySettingsChange(key, checked) {
        switch (key) {
            case DELIVERY_CONTENT_OPTIONS.researchSummary:
                this.props.updateContentOptions(key, !checked);
                break;
            case START_ARTICLE_ON_NEW_PAGE:
                this.props.updateShowDocumentOnNewPage(key, !checked);
                break;
            default:
                console.warn('No key provided for delivery settings change');
        }
    }

    handleMaxDocumentsPerCategoryInReport(value) {
        this.props.updateMaxDocumentsPerCategoryInReport(value);
    }

    handleSelectFuzzyMatch(event) {
        let fuzzyMatch = event.target.value;
        this.props.updateFuzzyMatch(fuzzyMatch);
    }

    handleSelectFuzzyThreshold = (event) => {
        let fuzzyMatch = event.target.value;
        this.props.updateFuzzyThreshold(fuzzyMatch);
    };

    changeQueryPerson(queryLanguage, queryPerson) {
        let newQueryPersonArr = [
            {
                language: queryLanguage,
                negativityString: queryPerson,
            },
        ];
        this.props.updateQueryPerson(newQueryPersonArr);
    }

    changeQueryCompany(queryLanguage, queryCompany) {
        let newQueryCompanyArr = [
            {
                language: queryLanguage,
                negativityString: queryCompany,
            },
        ];
        this.props.updateQueryCompany(newQueryCompanyArr);
    }

    changeDateRange(range, section) {
        this.props.updateDateRange(range, section);
    }

    changeProximity(proximity) {
        this.props.updateProximity(proximity);
    }

    handleUMError() {
        userPreferenceApi
            .getUserPreferences()
            .then((response) => {
                this.props.getUserPreferencesValues(response.body);
            })
            .catch(() => {
                utils.showNotificationsMessage({
                    messageText: 'General_CoreFunctionality_Error_error.500Title',
                    messageType: 'system-error',
                });
            });
    }

    changeLegalSource(source, section) {
        this.props.updateLegalSource(source, section);

        let legalSources = [CATEGORY_NAMES.LAW_SOURCES];
        legalSources = legalSources.concat(this.props.lawSources);

        if (source === 'all') {
            this.props.addUserPreferencesContentType(legalSources);
        } else {
            this.props.deleteUserPreferencesContentType(legalSources);
            this.props.addUserPreferencesContentType([CATEGORY_NAMES.LAW_SOURCES, source]);
        }
    }

    closeModal() {
        this.setState({
            showEditModal: false,
            confirmationModalTitle: '',
            confirmationModalDescription: '',
        });
    }

    closePopupModal() {
        this.setState({
            popupModalVisible: false,
        });
    }

    showPopupModal() {
        this.setState({
            popupModalVisible: true,
        });
    }

    handleUserClickNotice() {
        this.props.updatePrefsNotice(false);
    }

    updatePersonNegativityLevel = (segment, value, checked) => {
        const isValidSelection = this.handleShowNegativityLevelsWarningModal(checked, segment);

        if (isValidSelection) {
            const negativityLevel = [...this.props.personCheck.negativityLevel];

            if (checked) {
                negativityLevel.push(value);
            } else {
                const negativityIndex = negativityLevel.indexOf(value);
                negativityLevel.splice(negativityIndex, 1);
            }

            this.props.updatePersonNegativityLevel(negativityLevel);
        }

        return isValidSelection;
    };

    updateCompanyNegativityLevel = (segment, value, checked) => {
        const isValidSelection = this.handleShowNegativityLevelsWarningModal(checked, segment);

        if (isValidSelection) {
            const negativityLevel = [...this.props.companyCheck.negativityLevel];

            if (checked) {
                negativityLevel.push(value);
            } else {
                const negativityIndex = negativityLevel.indexOf(value);
                negativityLevel.splice(negativityIndex, 1);
            }

            this.props.updateCompanyNegativityLevel(negativityLevel);
        }

        return isValidSelection;
    };

    changeExcludeNonBusinessNews = (excludeNonBusinessNewsOption) => {
        const isExcludeNonBusinessNews = excludeNonBusinessNewsOption.target.checked;
        this.props.updateExcludeNonBusinessNews(isExcludeNonBusinessNews);
    };

    changeExcludeNewsWires = (excludeNewsWiresOption) => {
        const isExcludeNewsWires = excludeNewsWiresOption.target.checked;
        this.props.updateExcludeNewsWires(isExcludeNewsWires);
    };

    handleNegativeNewsLanguageSelection = (segment, language, checked) => {
        const isValidSelection = this.handleShowLanguageWarningModal(checked, segment);

        if (isValidSelection) {
            this.props.updateCheckedLanguage(segment, language, checked);
        }

        return isValidSelection;
    };

    handleShowLanguageWarningModal = (isChecked, segment) => {
        if (isChecked) {
            return true;
        }
        const languages =
            segment === PERSON_CHECK ? this.state.personAvailableLanguages : this.state.companyAvailableLanguages;
        const checkedLanguages = languages.filter((language) => language.checked);

        if (checkedLanguages.length <= 1) {
            this.setState({
                confirmationModalTitle: 'UserPreferences.popup.negativeLanguageSelection.title',
                confirmationModalDescription: 'UserPreferences.popup.negativeLanguageSelection.message',
                showEditModal: true,
            });
            return false;
        }

        return true;
    };

    filterAvailableLanguages = (languages, availableLanguages) =>
        languages && languages.filter((languageSettings) => availableLanguages.indexOf(languageSettings.language) > -1);

    handleShowNegativityLevelsWarningModal = (isChecked, segment) => {
        if (isChecked) {
            return true;
        }

        const checkedNegativityLevels =
            segment === PERSON_CHECK ? this.state.personNegativityLevels : this.state.companyNegativityLevels;

        if (checkedNegativityLevels.length <= 1) {
            this.setState({
                confirmationModalTitle: 'UserPreferences.negativeTerms.negativityLevels.popup.header',
                confirmationModalDescription: 'UserPreferences.negativeTerms.negativityLevels.popup.body',
                showEditModal: true,
            });

            return false;
        }

        return true;
    };

    render() {
        let buttons = [
            {
                text: formatRichMessage({ id: 'General_CoreFunctionality_UIText_general.ok' }, this.props.intl),
                primary: true,
                click: this.closeModal,
            },
        ];

        let searchSources = this.getSearchSources() || [];
        let reportBuilderCategories = this.getReportBuilderSources();
        // @TODO add results list
        let deliverySettings = {
            includeResearchSummary:
                this.props.deliveryIncludeResearchSummaryReport && this.props.deliveryIncludeResearchSummaryResults,
            startEachArticleOnNewPage: this.props.startEachArticleOnNewPage,
        };
        const customNewsSearchHelpUrl = utils.getHelpUrl(this.props.user, HELP_URLS.CUSTOM_NEWS_SEARCH_URL.key);

        return (
            <AdminLockContextProvider>
                {
                    // eslint-disable-next-line
                    <div className="user-preferences-page" ref={'user-preference-page'}>
                        {this.props.popupModel.isVisible && <PopupModal closeButtonHandler={this.closePopupModal} />}
                        <h1 className="user-preferences-header notranslate">
                            <span className="user-preferences-header-name">
                                <FormattedMessage id="HeaderFooterNav_CoreFunctionality_Dropdown_nav.user.userPreferences" />
                            </span>
                        </h1>
                        <LeftSideNavigation
                            handleScrollToPage={this.handleScrollToPage}
                            isActiveTab={this.props.isActiveTab}
                            top={this.state.leftNavigationTop}
                        />
                        {
                            // eslint-disable-next-line
                            <div className="user-preferences" ref={'user-preference'}>
                                {this.state.showEditModal && (
                                    <ConfirmationModal closeButtonHandler={this.closeModal} buttons={buttons}>
                                        <h2 className="modal-header">
                                            <FormattedMessage id={this.state.confirmationModalTitle} />
                                        </h2>
                                        <div className="modal-description font-body">
                                            <FormattedMessage id={this.state.confirmationModalDescription} />
                                        </div>
                                    </ConfirmationModal>
                                )}
                                <div className="user-preferences-block" id={GENERAL_SETTINGS} ref={GENERAL_SETTINGS}>
                                    <GeneralSettingsContainer
                                        containerName={GENERAL_SETTINGS}
                                        generalSettings={this.props.generalSettings}
                                        sources={searchSources}
                                        handleSourceSelection={this.handleSourceSelection}
                                        handleShowSnapshot={this.handleShowSnapshot}
                                        handleUpdateSanctionsAuthoritiesList={this.handleUpdateSanctionsAuthoritiesList}
                                        handleUpdateSanctionsDateRange={this.handleUpdateSanctionsDateRange}
                                        handleUpdateSanctionsKeywords={this.handleUpdateSanctionsKeywords}
                                        changeDateRange={this.changeDateRange}
                                        changeProximity={this.changeProximity}
                                        changeLegalSource={this.changeLegalSource}
                                        changeCostCode={this.props.changeCostCode}
                                        showSnapshot={this.props.showSnapshot}
                                        proximities={this.props.proximities}
                                        proximity={this.props.proximity}
                                        legalSources={this.state.legalSources}
                                        legalSource={this.state.legalSource}
                                        costCode={this.props.selectedCostCode}
                                        costCodes={this.props.costCodesList}
                                        chooseOnlyFromCostCodesList={this.props.chooseOnlyFromCostCodesList}
                                        isCostCodeRequired={this.props.isCostCodeRequired}
                                        userLocks={this.props.userLocks}
                                        modifiedSections={this.state.modifiedSections}
                                        arePreferencesSaving={this.props.isDirty}
                                        autosaveHasError={this.state.autosaveHasError}
                                        changeMade={this.changeMade}
                                        updateSearchNamePartyEntity={this.props.updateSearchNamePartyEntity}
                                        sourcesInformation={this.props.sourcesInformation}
                                        excludeNonBusinessNews={this.props.excludeNonBusinessNews}
                                        changeExcludeNonBusinessNews={this.changeExcludeNonBusinessNews}
                                        excludeNewsWires={this.props.excludeNewsWires}
                                        changeExcludeNewsWires={this.changeExcludeNewsWires}
                                        newsExcludeTogglesEnabled={this.props.newsExcludeTogglesEnabled}
                                        userOfferings={this.props.userOfferings}
                                        sanctionRiskRating={this.props.sanctionRiskRating}
                                        setSanctionsSectionOffset={this.setSanctionsSectionOffset}
                                        updateSanctionsSourcesRisks={this.props.updateSanctionsSourcesRisks}
                                        authoritiesTreePreferences={this.props.authoritiesTreePreferences}
                                        getSanctionsDefaultPreferences={userPreferenceApi.getDefaultUserSanctionsPreferences}
                                        handleExclusionOfLowQualityDocuments={this.handleExclusionOfLowQualityDocuments}
                                        excludeLowQualityDocuments={this.props.excludeLowQualityDocuments}
                                    />
                                </div>

                                <div className="user-preferences-block" id={PERSON_CHECK} ref={PERSON_CHECK}>
                                    <PersonCheck
                                        userLocks={this.props.userLocks}
                                        intl={this.props.intl}
                                        settings={this.props.personCheck}
                                        updateNegativityLevel={this.updatePersonNegativityLevel}
                                        updateNewsSourcesSelection={this.props.updateNewsSourcesSelection}
                                        handleNegativeNewsLanguageSelection={this.handleNegativeNewsLanguageSelection}
                                        updateCheckedLanguagesUponKey={this.props.updateCheckedLanguagesUponKey}
                                        languages={this.state.personAvailableLanguages}
                                        customNewsQuery={this.props.personCustomNewsQuery}
                                        lnCustomNewsQueries={this.props.personLnCustomNewsQuery}
                                        checkCustomNewsQuery={this.props.checkCustomNewsQuery}
                                        checkLnCustomNewsQuery={this.props.checkLnCustomNewsQuery}
                                        checkAdminCustomNewsQuery={this.props.checkAdminCustomNewsQuery}
                                        saveChanges={this.changeQueryPerson}
                                        handleFuzzyEnabled={this.handleFuzzyEnabled}
                                        handleSelectFuzzyMatch={this.handleSelectFuzzyMatch}
                                        handleSelectFuzzyThreshold={this.handleSelectFuzzyThreshold}
                                        fuzzyMatch={this.props.fuzzyMatch}
                                        isFuzzyEnabled={this.props.isFuzzyEnabled}
                                        negativityLevels={this.props.negativityLevels}
                                        modifiedSections={this.state.modifiedSections}
                                        arePreferencesSaving={this.props.isDirty}
                                        autosaveHasError={this.state.autosaveHasError}
                                        changeMade={this.changeMade}
                                        checkCustomNewsSourceAvailability={this.checkCustomNewsSourceAvailability}
                                        adminCustomNewsQuery={this.props.adminPersonCustomNewsQuery}
                                        fuzzyThreshold={this.props.fuzzyThreshold}
                                        fuzzyOn={this.props.fuzzyOn}
                                        newFuzzyEnabled={this.props.newFuzzyEnabled}
                                        suggestedNamesEnabled={this.props.suggestedNamesEnabled}
                                        handleFuzzyToggle={this.handleFuzzyToggle}
                                        handleSuggestedNamesToggle={this.handleSuggestedNamesToggle}
                                        suggestedNames={this.props.suggestedNames}
                                        setSuccessBackgroundMessages={this.props.setSuccessBackgroundMessages}
                                        clearBackgroundMessages={this.props.clearBackgroundMessages}
                                        setPopupModel={this.props.setPopupModel}
                                        customNewsSearchHelpUrl={customNewsSearchHelpUrl}
                                    />
                                </div>

                                <div className="user-preferences-block" id={COMPANY_CHECK} ref={COMPANY_CHECK}>
                                    <CompanyCheck
                                        userLocks={this.props.userLocks}
                                        intl={this.props.intl}
                                        settings={this.props.companyCheck}
                                        updateNegativityLevel={this.updateCompanyNegativityLevel}
                                        updateNewsSourcesSelection={this.props.updateNewsSourcesSelection}
                                        updateCheckedLanguagesUponKey={this.props.updateCheckedLanguagesUponKey}
                                        handleNegativeNewsLanguageSelection={this.handleNegativeNewsLanguageSelection}
                                        languages={this.state.companyAvailableLanguages}
                                        customNewsQuery={this.props.companyCustomNewsQuery}
                                        lnCustomNewsQueries={this.props.companyLnCustomNewsQuery}
                                        checkCustomNewsQuery={this.props.checkCustomNewsQuery}
                                        checkLnCustomNewsQuery={this.props.checkLnCustomNewsQuery}
                                        checkAdminCustomNewsQuery={this.props.checkAdminCustomNewsQuery}
                                        saveChanges={this.changeQueryCompany}
                                        negativityLevels={this.props.negativityLevels}
                                        modifiedSections={this.state.modifiedSections}
                                        arePreferencesSaving={this.props.isDirty}
                                        autosaveHasError={this.state.autosaveHasError}
                                        changeMade={this.changeMade}
                                        checkCustomNewsSourceAvailability={this.checkCustomNewsSourceAvailability}
                                        adminCustomNewsQuery={this.props.adminCompanyCustomNewsQuery}
                                        updateCompanySourcesSearchOption={this.props.updateCompanySourcesSearchOption}
                                        setSuccessBackgroundMessages={this.props.setSuccessBackgroundMessages}
                                        clearBackgroundMessages={this.props.clearBackgroundMessages}
                                        setPopupModel={this.props.setPopupModel}
                                        customNewsSearchHelpUrl={customNewsSearchHelpUrl}
                                    />
                                </div>

                                <div className="user-preferences-block" id={DELIVERY_SETTINGS} ref={DELIVERY_SETTINGS}>
                                    <DeliverySettings
                                        categories={reportBuilderCategories}
                                        deliverySettings={deliverySettings}
                                        maxDocumentsPerCategoryInReport={this.props.maxDocumentsInReportCategory}
                                        setReportBuilderContentOrder={this.setReportBuilderContentOrder}
                                        handleToggleDeliverySettings={this.onDeliverySettingsChange}
                                        handleMaxDocumentsPerCategoryInReport={this.handleMaxDocumentsPerCategoryInReport}
                                        userLocks={this.props.userLocks}
                                        modifiedSections={this.state.modifiedSections}
                                        arePreferencesSaving={this.props.isDirty}
                                        autosaveHasError={this.state.autosaveHasError}
                                        changeMade={this.changeMade}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                }
            </AdminLockContextProvider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        allPreferences: state.user.preferences,
        isDirty: state.user.preferences.isDirty,
        companyCheck: state.user.preferences.companyCheck,
        personCheck: state.user.preferences.personCheck,
        sources: state.user.preferences.generalSettings.contentTypes,
        lawSources: state.user.preferences.generalSettings.lawSources,
        lawSource: state.user.preferences.generalSettings.legalSources.defaultDateSource,
        negativityLevels: state.user.preferences.generalSettings.negativityLevel,
        availableQueryLanguages: state.user.preferences.generalSettings.availableQueryLanguages,
        activeTab: state.userPreferences.activeTab,
        generalSettings: state.user.preferences.generalSettings,
        proximity: state.user.preferences.generalSettings.proximity,
        proximities: state.user.preferences.generalSettings.proximities,
        showSnapshot: state.user.preferences.generalSettings.showSnapshot,
        isFuzzyEnabled: state.user.preferences.generalSettings.isFuzzyEnabled,
        fuzzyMatch: state.user.preferences.generalSettings.fuzzyMatch,
        fuzzyList: state.fuzzyNames.list,
        userLocks: state.user.preferences.userLocks,
        searchType: state.searchParams.searchType,
        popupModel: state.popupModel,
        breadcrumbs: state.breadcrumbs,
        isActiveTab: state.userPreferences.activeTab,
        personLanguages: state.user.preferences.personCheck.customQuery,
        companyLanguages: state.user.preferences.companyCheck.customQuery,
        personCustomNewsQuery: state.user.preferences.personCheck.customNewsQuery,
        personLnCustomNewsQuery: state.user.preferences.personCheck.lnCustomNewsQueries,
        companyCustomNewsQuery: state.user.preferences.companyCheck.customNewsQuery,
        companyLnCustomNewsQuery: state.user.preferences.companyCheck.lnCustomNewsQueries,
        adminPersonCustomNewsQuery: state.user.preferences.personCheck.adminCustomNewsQuery,
        adminCompanyCustomNewsQuery: state.user.preferences.companyCheck.adminCustomNewsQuery,

        selectedCostCode: state.user.preferences.generalSettings.selectedCostCode,
        costCodesList: state.user.preferences.generalSettings.costCodesList,
        chooseOnlyFromCostCodesList: state.user.preferences.generalSettings.chooseOnlyFromCostCodesList,
        isCostCodeRequired: state.user.preferences.generalSettings.isCostCodeRequired,
        publicRecordsOn: state.user.appSettings.prsOn,
        sourcesInformation: state.user.sourcesInformation,
        deliveryIncludeResearchSummaryReport: state.user.preferences.contentsOptions.shouldContainResearchSummary,
        deliveryIncludeResearchSummaryResults:
            state.user.preferences.resultsContentOptions.shouldContainResearchSummary,
        startEachArticleOnNewPage: state.user.preferences.startEachArticleOnNewPage,
        fuzzyThreshold: state.user.preferences.personCheck.fuzzyThreshold,
        fuzzyOn: state.user.preferences.personCheck.fuzzyOn,
        newFuzzyEnabled: state.user.newFuzzyEnabled,
        suggestedNamesEnabled: state.user.suggestedNamesEnabled,
        suggestedNames: state.user.preferences.personCheck.suggestedNames,
        excludeNonBusinessNews: state.user.preferences.generalSettings.excludeNonBusinessNews,
        excludeNewsWires: state.user.preferences.generalSettings.excludeNewsWires,
        newsExcludeTogglesEnabled: state.user.newsExcludeTogglesEnabled,
        userPermId: state.user.userPermIdUnhashed,
        preferencesRefreshPeriod: state.user.preferencesRefreshPeriod,
        lastPreferencesUpdateInfo: state.user.lastPreferencesUpdateInfo,
        lastUserPreferencesRetrieval: state.user.preferences.lastUserPreferencesRetrieval,
        personNegativityLevels: state.user.preferences.personCheck.negativityLevel,
        companyNegativityLevels: state.user.preferences.companyCheck.negativityLevel,
        userOfferings: state.user.userOfferings,
        sanctionRiskRating: state.user.preferences.sanctionRiskRating,
        maxDocumentsInReportCategory: state.user.preferences.maxDocumentsInReportCategory,
        authoritiesTreePreferences: state.user.preferences.generalSettings.sanctionsSources,
        excludeLowQualityDocuments: state.user.preferences.generalSettings.excludeLowQualityDocuments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateBreadcrumbs: breadcrumbsActions.updateBreadcrumbs,
            getUserPreferencesValues: actions.getUserPrefencesValues,
            addUserPreferencesContentType: actions.addUserPreferencesContentType,
            deleteUserPreferencesContentType: actions.deleteUserPreferencesContentType,
            updateDateRange: actions.updateDateRange,
            updateProximity: actions.updateProximity,
            updateQueryPerson: actions.updateQueryPerson,
            updateQueryCompany: actions.updateQueryCompany,
            updateLegalSource: actions.updateLegalSource,
            updateSnapshotVisibility: actions.updateSnapshotVisibility,
            updateFuzzyMatch: actions.updateFuzzyMatch,
            updateFuzzyThreshold: actions.updateFuzzyThreshold,
            updateFuzzyFunctionality: actions.updateFuzzyFunctionality,
            updateFuzzyToggle: actions.updateFuzzyToggle,
            updateSuggestedNamesToggle: actions.updateSuggestedNamesToggle,
            updatePrefsNotice: actions.updatePrefsNotice,
            updatePersonNegativityLevel: actions.updatePersonNegativityLevel,
            updateCompanyNegativityLevel: actions.updateCompanyNegativityLevel,
            setActiveTab: actions.setActiveTab,
            updateNewsSourcesSelection: actions.updateNewsSourcesSelection,
            updateCheckedLanguage: actions.updateCheckedLanguage,
            updateCheckedLanguagesUponKey: actions.updateCheckedLanguagesUponKey,
            checkCustomNewsQuery: actions.updateCustomNewsQueryCheckedState,
            checkLnCustomNewsQuery: actions.updateLnCustomNewsQueryCheckedState,
            checkAdminCustomNewsQuery: actions.updateAdminCreatedCustomNewsQueryCheckedState,
            deleteCustomNewsQuery: actions.deleteCustomNewsQuery,
            changeCostCode: actions.changeCostCode,
            updateIsDirty: actions.updateIsDirty,
            checkIsDirty: actions.checkIsDirty,
            resetFuzzyNames: actions.resetFuzzyNames,
            updateContentOrderForReportBuilder: mainActions.updateContentOrderForReportBuilder,
            updateCompanySourcesSearchOption: actions.updateCompanySourcesSearchOption,
            updateSearchNamePartyEntity: actions.updateSearchNamePartyEntity,
            updateContentOptions: actions.updateContentOptions,
            updateShowDocumentOnNewPage: actions.updateShowDocumentOnNewPage,
            updateExcludeNonBusinessNews: actions.updateExcludeNonBusinessNews,
            updateExcludeNewsWires: actions.updateExcludeNewsWires,
            updateSanctionsRiskToggle: actions.updateSanctionsRiskToggle,
            setSuccessBackgroundMessages: backgroundActions.setSuccessBackgroundMessages,
            clearBackgroundMessages: backgroundActions.clearBackgroundMessages,
            setPopupModel: popupModelActions.setPopupModel,
            updateMaxDocumentsPerCategoryInReport: actions.updateMaxDocumentsPerCategoryInReport,
            updateSanctionsSourcesRisks: actions.updateSanctionsSourcesRisks,
            updateExclusionOfLowQualityDocuments: actions.preferencesUpdateExcludeLowQualityDocuments
        },
        dispatch
    );
};

export { UserPreferences as TestUserPreferences };
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl,
    withAppContext,
    withPreferenceRefresh
)(UserPreferences);
