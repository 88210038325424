import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceBy, isNumber } from 'lodash';
import {
    ACTION_TYPES,
    ALERT_COMMENTS_MAX_CHARS,
    BATCH_SCREENING_DROPDOWN_MESSAGES,
    BATCH_SCREENING_ERROR_MESSAGE,
    BATCH_SCREENING_PAGE_SIZE,
    COMPANY_SEARCH_QUERY_TYPE,
    DEFAULT_DELIVERY_OPTIONS,
    DISPLAY_RISK_SCORES,
    DOWNLOAD_DEFAULT_FILENAME,
    DOWNLOAD_SNAPSHOT_DEFAULT_FILENAME,
    ENTITIES_COMMENTS_MAX_CHARS,
    entitiesTableFocus,
    KEYBOARD_KEYS,
    MODAL_TYPE,
    MULTIPLE_ENTITIES_COMMENTS,
    MULTIPLE_ENTITIES_ENTITY_NAME,
    MULTIPLE_ENTITIES_NONE_SELECTED_VIEW,
    MULTIPLE_ENTITIES_PREFIX_FILENAME,
    POLLING_STATUS,
    QUERY_SEARCH_TYPES,
    REPORT_CONTENT_TYPES,
    SEARCH_ENTITY_INITIAL_VALUE,
    SORT_DIRECTION,
    TOOLBAR_ACTIONS,
    ENTITY_VIEW_CREATED_DATE,
    ENTITY_VIEW_LAST_UPDATE_DATE,
    ROUTES,
    NOTIFICATION_STORE_KEYS,
} from '@constants';
import useStateWithPrev from '@scripts/hooks/useStateWithPrev';
import GridTable from '@reusable/GridTable/GridTable';
import EntityViewApi from '../../api/EntityViewApi';
import EmptyTableTemplate from './components/EmptyTable/EmptyTable';
import {
    disableTableScroll,
    enableTableScroll,
    generateEditEntityPayload,
    getColumnMap,
    getColumns,
    getDefaultColumnsPreferences,
    getParamsForUrl,
    getRowStructure,
    getSelectedItemsCount,
    getSelectionPayload,
    getTooltipMessage,
    isSelectAllChecked,
    onlyLoadedEntitiesAreSelected,
    updateCommentsListOnAdd,
    updateCommentsListOnEdit,
} from './helpers/batchHelpers';
import { FormattedMessage } from 'react-intl';
import { updateAllEntitiesInContextCount as updateAllEntitiesInContextCountAsync } from '../../EntityViewHelper';

import utils from 'scripts/utils/utilities';
import costCodeUtils from 'scripts/utils/costCodeUtils';
import notificationService from '@utils/notificationService';
import PopupModel from '@reusable/PopupModal/PopupBuilder';
import popupModelActions from '@reusable/PopupModal/redux/PopupModel.actions';
import PopupModal from '@reusable/PopupModal/PopupModal.index';

import withConfirmationModal from '@reusable/Modal/hoc/withConfirmationModal';
import { withRouter } from 'react-router';
import ActionButton from './components/ActionButton/ActionButton';
import ReactTooltip from 'react-tooltip';

import Dropdown from '@reusable/Dropdown/Dropdown';
import CommentsSection from '@reusable/CommentsSection/CommentsSection';
import mainActions from '@pages/Main/Main.actions';
import searchParamsActions from '@MainSearch/redux/SearchParams.actions';
import CreateViewFilter from './components/CreateViewFilter/CreateViewFilter';
import SearchInput from '@pages/EntityView/components/EntityViewGridTable/components/SearchInput/SearchInput.jsx';
import usePrevious from 'scripts/hooks/usePrevious';
import useEntities from '@pages/EntityView/hooks/useEntities';
import useTablePreferences from '@pages/EntityView/hooks/useTablePreferences';
import useViews from '@pages/EntityView/hooks/useViews';
import snackbarUtils from '@reusable/SnackbarWithAutohide/snackbarUtils';
import categoryUtils from '@utils/categoryUtils';
import ManageColumns from './components/ManageColumns/ManageColumns';

export const BATCH_SCREENING_ERRORS = {
    COMMENTS: {
        LIMIT_REACHED: 'CommensSection.limitReached.error',
        ACTION_FAILED: 'General_CoreFunctionality_Error_general.saveError',
    },
};

const displayDefaultError = () => {
    utils.showNotificationsMessage({
        messageText: 'BatchScreening.generalError.message',
        messageType: 'system-error',
    });
};

const EntityViewTable = (props) => {
    const { views, setViews, handleEntityViewApiError = displayDefaultError } = props;
    // hooks
    const dispatch = useDispatch();
    const displayRiskScores = useSelector((state) => state.user.preferences.generalSettings.displayRiskScores);
    const sortingInfo = useSelector((state) => state.user.preferences.screeningEntity);
    const screeningEntityCommentsLimit = useSelector((state) => state.user.screeningEntityCommentsLimit);
    const shouldDisplayRiskScore = displayRiskScores === DISPLAY_RISK_SCORES.SHOW;
    const entitiesNeedUpdate = useSelector((state) => state.entityViewInformation.entitiesCountNeedsUpdate);
    const tableHasData = useSelector((state) => state.entityViewInformation.tableHasData);
    const entityViewCount = useSelector((state) => state.entityViewInformation.entitiesCount);
    const uploadPollingActive = useSelector((state) => state.entityViewInformation.uploadPollingActive);
    const prevUploadPollingActive = usePrevious(uploadPollingActive);
    const blockedEntities = useSelector((state) => state.entityViewInformation.blockedEntities);
    const timezone = useSelector((state) => state.user.timezone);
    const language = useSelector((state) => state.user.preferences.language);
    const userEmail = useSelector((state) => state.user.email);
    const startEachArticleOnNewPage = useSelector((state) => state.user.preferences.startEachArticleOnNewPage);
    const billingId = useSelector((state) => state.investigation.billingId);
    const generalSettings = useSelector((state) => state.user.preferences.generalSettings);
    const userPreferencesContentTypes = useSelector((state) => state.user.preferences.generalSettings.contentTypes);
    const showPopupModal = useSelector((state) => state.popupModel.isVisible);
    const popupModalType = useSelector((state) => state.popupModel.popupType);
    const lastReportStatus = useSelector((state) => state.reportBuilder.lastBatchReportStatus);
    const pollingStatus = useSelector((state) => state.reportBuilder.pollingStatus);
    const columnsPreferences = useSelector((state) => state.user.preferences.screeningEntity);
    const firstName = useSelector((state) => state.user.firstName);
    const lastName = useSelector((state) => state.user.lastName);
    const entityOwner = useSelector((state) => state.user.userPermIdUnhashed);
    const shouldUpdateEntitiesColumnsFromContentTypes = useSelector(
        (state) => state.searchState.shouldUpdateEntitiesColumnsFromContentTypes
    );
    const isShareViewsEnabled = useSelector((state) => state.user.screeningEntityShareViewEnabled);
    const lastSelectedView = useSelector((state) => state.user.preferences.screeningEntity.lastSelectedView);
    const isShareable = useSelector((state) => state.user.preferences.screeningEntity.isShareable);
    const entityUploadLimit = useSelector((state) => state.user.preferences.generalSettings.entityUploadLimit);
    const isMultipleEntitiesTrial = useSelector((state) => state.user.appSettings.isMultipleEntitiesTrial);
    const isSnapshotVisible = useSelector((state) => state.user.preferences.generalSettings.showSnapshot);
    // states used by negative news template
    const personCheck = useSelector((state) => state.user.preferences.personCheck);
    const companyCheck = useSelector((state) => state.user.preferences.companyCheck);
    const investigation = useSelector((state) => state.investigation);
    const isNewResearchSummaryEnabled = useSelector((state) => state.user.useNewResearchSummaryFeature);
    // states used by cell count template
    const searchResults = useSelector((state) => state.searchResults);

    //adhoc search
    const adHocSearch = useSelector((state) => state.adHocSearch);

    // state
    const [sortInfo, setSortInfo] = useState(sortingInfo && sortingInfo.sorting);

    const handleSortClick = (sortBy) => {
        const direction = sortInfo.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC;
        setSortInfo({ sortBy, direction });
    }

    const [columnsWidthInfo, setColumnsWidthInfo] = useState(sortingInfo && sortingInfo.columns);
    const [tableColumns, setTableColumns] = useState(getColumns(sortingInfo && sortingInfo.sorting, columnsWidthInfo));
    const [tableColumnMap, setTableColumnMap] = useState(getColumnMap(shouldDisplayRiskScore, handleSortClick, sortInfo));
    const [tableColumnsPreferences, setTableColumnsPreferences] = useState(
        getDefaultColumnsPreferences(shouldDisplayRiskScore, userPreferencesContentTypes)
    );
    const [tableColumnsOrder, setTableColumnsOrder] = useState(null);
    const [refreshEntities, setRefreshEntities] = useState([]);
    const [tableFocus, setTableFocus] = useState(false);
    const [searchEntity, setSearchEntity, prevSearchEntity] = useStateWithPrev(SEARCH_ENTITY_INITIAL_VALUE);
    const [isPageResetAfterRefresh, setIsPageResetAfterRefresh] = useState(false);
    const [isAnyActionLoading, setIsAnyActionLoading] = useState(false);
    const [lastPerformedAction, setLastPerformedAction] = useState(null);
    const [isRefreshDropdownOpen, setIsRefreshDropdownOpen] = useState(false);
    const [isDownloadDropdownOpen, setIsDownloadDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Comments Section State
    const [commentInput, setCommentInput] = useState('');
    const [commentsList, setCommentsList] = useState([]);
    const [commentSectionPosition, setCommentSectionPosition] = useState({});
    const [isCommentSectionVisible, setCommentSectionVisibility] = useState(false);
    const [activeCommentsSectionRowId, setActiveCommentsSectionRowId] = useState();
    const [activeCommentsSectionEntityId, setActiveCommentsSectionEntityId] = useState();
    const [editCommentId, setEditCommentId] = useState();
    const [commentSectionLoading, setCommentSectionLoading] = useState(false);
    const [deletedCommentId, setDeletedCommentId] = useState();
    const [commentSectionError, setCommentSectionError] = useState('');
    const [numberOfComments, setNumberOfComments] = useState(0);

    //Edit entity names state
    const [updatedEntities, setUpdatedEntities] = useState({});
    const [entityChangesSaved, setEntityChangesSaved] = useState(false);

    const {
        selectedItems,
        setSelectedItems,
        selectAllSetting,
        setSelectAllSetting,
        unselectedItemIds,
        setUnselectedItemIds,
        clearAllSelectedItems,
    } = useEntities();

    const { updateEntityViewPreferences } = useTablePreferences(
        sortInfo,
        tableColumns,
        setTableColumns,
        clearAllSelectedItems
    );

    const { selectedView, setSelectedView, getAllViews, changeView, createView, deleteView, resetView, updateView } =
        useViews(
            views,
            setViews,
            selectedItems,
            setSelectedItems,
            selectAllSetting,
            unselectedItemIds,
            searchEntity,
            sortInfo,
            clearAllSelectedItems,
            updateEntityViewPreferences,
            handleEntityViewApiError,
            setLastPerformedAction,
            setTableFocus,
            ROUTES.SCREENING,
        );

    // Local constants
    const reactGridElement = document.querySelector('#reactgrid-scrollarea');
    const shouldAutoRefresh = !!lastReportStatus.uploadedFinishedStatus;
    const selectedViewId = selectedView.id ?? lastSelectedView;
    const commentDisplayName = `${firstName} ${lastName}`;

    const focusCommentInputArea = () => {
        const inputAreaCollection = document.getElementsByClassName('comment-input-textarea');

        inputAreaCollection.length && inputAreaCollection[0].focus();
    };

    //Comments section handlers
    const handleCommentIconClick = (popoverPosition, rowId, entityId) => {
        setCommentSectionVisibility(true);
        setCommentSectionPosition(popoverPosition);
        setActiveCommentsSectionRowId(rowId);
        setActiveCommentsSectionEntityId(entityId);
        setCommentSectionLoading(false);
        setCommentSectionError('');
        setNumberOfComments(commentsList[rowId].length);
        disableTableScroll();
    };

    const resetCommentsSectionState = () => {
        setCommentSectionVisibility(false);
        setCommentSectionPosition({});
        setActiveCommentsSectionRowId();
        setEditCommentId();
        setCommentInput('');
        setCommentSectionLoading(false);
        setCommentSectionError('');
        setDeletedCommentId();
        setNumberOfComments(0);
        enableTableScroll();
    };

    const handleCloseCommentSectionClose = async () => {
        await submitComment({ key: KEYBOARD_KEYS.ENTER });

        resetCommentsSectionState();
    };

    const resetCommentsSectionStateAfterSave = (updatedCommentsList) => {
        setEditCommentId();
        setCommentsList(updatedCommentsList);
        setCommentInput('');
        setCommentSectionLoading(false);
        setCommentSectionError('');
    };

    const submitComment = async (event) => {
        if ((event.key === KEYBOARD_KEYS.ENTER || event.type === 'click') && commentInput.trim().length > 0) {
            event.preventDefault && event.preventDefault();

            const createdDate = utils.getCurrentTime();

            await saveComment(createdDate);
        }
    };

    const onEditComment = (commentId, commentText) => {
        setCommentSectionError('');
        setCommentInput(commentText);
        setEditCommentId(commentId);
        focusCommentInputArea();
    };

    const saveComment = async (createdOn) => {
        setCommentSectionLoading(true);
        const [comment, error] = editCommentId
            ? await EntityViewApi.editComment(activeCommentsSectionEntityId, editCommentId, commentInput)
            : await EntityViewApi.addComment(
                activeCommentsSectionEntityId,
                commentInput,
                commentDisplayName,
                createdOn
            );

        if (comment) {
            const updatedCommentsList = editCommentId
                ? updateCommentsListOnEdit(commentsList, activeCommentsSectionRowId, editCommentId, comment.text)
                : updateCommentsListOnAdd(commentsList, activeCommentsSectionRowId, comment);

            resetCommentsSectionStateAfterSave(updatedCommentsList);
            setNumberOfComments(updatedCommentsList[activeCommentsSectionRowId].length);
            editCommentId &&
                numberOfComments >= 50 &&
                setCommentSectionError(BATCH_SCREENING_ERRORS.COMMENTS.LIMIT_REACHED);
        }

        if (error) {
            setCommentSectionError(BATCH_SCREENING_ERRORS.COMMENTS.ACTION_FAILED);
            setCommentSectionLoading(false);
        }
    };

    const onDeleteComment = async (commentId) => {
        setDeletedCommentId(commentId);
        const [response, error] = await EntityViewApi.deleteComment(activeCommentsSectionEntityId, commentId);

        if (response) {
            const updatedCommentsList = commentsList.map((commentsListPerEntity, index) =>
                index === activeCommentsSectionRowId
                    ? commentsListPerEntity.filter((comm) => comm.id !== commentId)
                    : commentsListPerEntity
            );

            setCommentsList(updatedCommentsList);
            setNumberOfComments(updatedCommentsList[activeCommentsSectionRowId].length);
            setDeletedCommentId();
            setCommentSectionError('');
        }

        if (error) {
            setCommentSectionError(BATCH_SCREENING_ERRORS.COMMENTS.ACTION_FAILED);
            setDeletedCommentId();
        }
    };

    //This method keeps tableColumnsOrder up to date with the tableColumns state
    const updateTableColumnsOrder = (tableColumns) => {
        const columnIds = [];
        const disabledColumnIds = [];

        tableColumns.forEach((column, index) => {
            const columnPreference = tableColumnsPreferences.find((col) => col.columnId === column.columnId);
            const isEnabledInUserPreferences = columnPreference.enabledInGeneralSettings;
            const isEntityOrCommentsColumn = index === tableColumns.length - 1 || index === 0;

            if ( isEnabledInUserPreferences || isEntityOrCommentsColumn) {
                columnIds.push(column.columnId);
            } else {
                disabledColumnIds.push(column.columnId);
            }
        });

        if (disabledColumnIds.length) {
            columnIds.push(...disabledColumnIds);
        }

        setTableColumnsOrder(columnIds);
    };

    //This method is used to update the table columns when the order has been changed in Manage Columns
    const handleTableColumnsOrderChange = (newTableColumnsOrder) => {
        const updatedTableColumns = [];

        for (const columnId of newTableColumnsOrder) {
            const column = tableColumns.find((col) => col.columnId === columnId);
            if (column){
                updatedTableColumns.push(column);
            }
        }

        handleColumnsOrderChange(updatedTableColumns);
    }

    useEffect(() => {
        const exportEntitiesPayload = {
            sorting: sortInfo,
            columnsOrder: tableColumns.map((column) => column.columnId),
            timezone,
            language,
            displayRiskScores,
            searchTerm: searchEntity,
            viewId: lastSelectedView,
        };

        // this is a special case where if we sort by entity name, we need to send the sortyBy as 'displayName'
        // because if the user has too many entities, the database would throw an error as it's using too much memory
        // BUG - 2031975
        if(sortInfo.sortBy === MULTIPLE_ENTITIES_ENTITY_NAME) {
            exportEntitiesPayload.sorting = {
                sortBy: 'displayName',
                direction: sortInfo.direction
            }
        }

        props.updateExportEntitiesPayload(exportEntitiesPayload);
    }, [timezone, sortInfo, tableColumns, displayRiskScores, searchEntity, lastSelectedView]);

    useEffect(() => {
        // set sortInfo if sortingInfo state changes, to avoid inconsistencies
        setSortInfo((prev) => {
            if (prev.sortBy !== sortingInfo.sorting.sortBy) {
                return sortingInfo.sorting;
            }
            return prev;
        });
    }, [sortingInfo]);

    useEffect(() => {
        if (
            searchEntity?.length ||
            (searchEntity !== prevSearchEntity && !searchEntity.length && prevSearchEntity?.length)
        ) {
            clearAllSelectedItems();
            updateAllEntitiesCount();
            jumpToTop();
            setIsPageResetAfterRefresh(true);
        }
    }, [searchEntity]);

    useEffect(() => {
        updateAllEntitiesCount();
        setSearchEntity(SEARCH_ENTITY_INITIAL_VALUE);
        jumpToTop();
        setIsPageResetAfterRefresh(true);
    }, [lastSelectedView]);

    //toggle between showing or hiding the error message when the maximum nr of comments per entity is reached
    useEffect(() => {
        const isLimitReachedErrorShown = commentSectionError === BATCH_SCREENING_ERRORS.COMMENTS.LIMIT_REACHED;

        if (numberOfComments >= screeningEntityCommentsLimit && !isLimitReachedErrorShown) {
            setCommentSectionError(BATCH_SCREENING_ERRORS.COMMENTS.LIMIT_REACHED);
        } else if (numberOfComments < screeningEntityCommentsLimit && isLimitReachedErrorShown) {
            setCommentSectionError('');
        }
    }, [numberOfComments]);

    useEffect(() => {
        onLoadedEntitiesCountChange();
    }, [props.loadedEntitiesCount]);

    useEffect(() => {
        // only update columns preferences when sorting info, or column preferences change
        updateColumnsPreferencesFromDb();
    }, [columnsPreferences, shouldDisplayRiskScore, sortInfo, sortingInfo]);

    useEffect(() => {
        pollingStatus === POLLING_STATUS.FINISHED && setIsPageResetAfterRefresh(false);
        updateAllEntitiesCount();
    }, [pollingStatus, uploadPollingActive]);

    useEffect(() => {
        let timeout;

        if (entityChangesSaved) {
            timeout = setTimeout(() => {
                setEntityChangesSaved(false);
            }, 3000);
        }

        return () => timeout && clearTimeout(timeout);
    }, [setEntityChangesSaved, entityChangesSaved]);

    // Clear the blocked entities array upon inital page load, as the polling is being retriggered in either case
    useEffect(() => {
        dispatch(searchParamsActions.resetQuery());
        utils.clearBlockedEntities();
        notificationService.pollEntityAlertsCreation({ route: ROUTES.SCREENING});
    }, []);

    const updateColumnsPreferencesFromContentTypes = (userColumnPreferences, userContentTypes) => {
        if (!userContentTypes) {
            return userColumnPreferences;
        }

        const updatedColumns = { ...userColumnPreferences.columns };

        userContentTypes.forEach((userContentType) => {
            const { name, value } = userContentType;

            if (updatedColumns[name]) {
                updatedColumns[name].isHidden = !value;
            }
        });

        const updatedPreferences = {
            ...userColumnPreferences,
            columns: updatedColumns,
        };

        return updatedPreferences;
    };

    const jumpToTop = () => {
        reactGridElement?.scrollTo(0, 0);
    };
    const updateColumnsPreferencesFromDb = () => {
        const defaultColumnMap = getColumnMap(shouldDisplayRiskScore, handleSortClick, sortInfo);

        const defaultColumns = getColumns(sortInfo, columnsWidthInfo);

        let updatedTableColumnsPrefs = [...tableColumnsPreferences];
        let updatedColumnMap = { ...defaultColumnMap };
        let updatedColumns = [...defaultColumns];
        let userColumnPreferences = { ...columnsPreferences };

        const updatedColumnMapKeys = Object.keys(updatedColumnMap);

        if (shouldUpdateEntitiesColumnsFromContentTypes) {
            userColumnPreferences = updateColumnsPreferencesFromContentTypes(
                columnsPreferences,
                userPreferencesContentTypes
            );
        }

        userColumnPreferences?.columns &&
            Object.getOwnPropertyNames(userColumnPreferences.columns).forEach((column) => {
                const columnPrefsIndex = updatedTableColumnsPrefs.findIndex((el) => el.columnId === column);
                if (columnPrefsIndex !== -1) {
                    updatedTableColumnsPrefs[columnPrefsIndex].hidden = userColumnPreferences.columns[column].isHidden;
                    updatedTableColumnsPrefs[columnPrefsIndex].order = userColumnPreferences.columns[column].order;
                }

                updatedColumnMapKeys.forEach((colMapKey) => {
                    if (colMapKey === column) {
                        updatedColumnMap[colMapKey] = React.cloneElement(updatedColumnMap[colMapKey], {
                            order: userColumnPreferences.columns[column].order,
                        });
                    }
                });

                const columnTableIndex = updatedColumns.findIndex((el) => el.columnId === column);
                if (columnTableIndex !== -1)
                    updatedColumns[columnTableIndex].order = userColumnPreferences.columns[column].order;
            });

        setTableColumnsPreferences(updatedTableColumnsPrefs.sort((a, b) => a.order - b.order));

        const hiddenColIds =
            userColumnPreferences?.columns &&
            Object.getOwnPropertyNames(userColumnPreferences?.columns).filter(
                (hiddenCol) =>
                    userColumnPreferences.columns[hiddenCol].isHidden ||
                    userPreferencesContentTypes.some(({ name, value }) => name === hiddenCol && !value)
            );

        updateTableColumnsOrder(updatedColumns.sort((a, b) => a.order - b.order));
        hiddenColIds?.forEach((columnToBeRemoved) => {
            delete updatedColumnMap[columnToBeRemoved];
            updatedColumns = [
                ...updatedColumns.filter((defaultColumn) => defaultColumn.columnId !== columnToBeRemoved),
            ];
        });

        const updatedColumnMapEntries = Object.entries(updatedColumnMap).sort((a, b) => a.order - b.order);
        const sortedUpdatedColumnMap = Object.fromEntries(updatedColumnMapEntries);

        setTableColumns(updatedColumns.sort((a, b) => a.order - b.order));
        setTableColumnMap(sortedUpdatedColumnMap);
        dispatch(mainActions.setUpdateEntitiesColumnsFromContentTypes(false));
        setIsLoading(false);
    };

    const updateColumnsPreferencesAndSave = (columnsPreferencesArray) => {
        setTableColumnsPreferences(columnsPreferencesArray);

        const screeningEntityOptions = {
            columns: {},
            sorting: { ...columnsPreferences.sorting },
        };
        columnsPreferencesArray.forEach((columnObj) => {
            if (columnObj.columnId !== 'entityName') {
                screeningEntityOptions.columns[columnObj.columnId] = {};
                screeningEntityOptions.columns[columnObj.columnId].isHidden = columnObj.hidden;
                screeningEntityOptions.columns[columnObj.columnId].order = columnObj.order;
            }
        });
        setIsLoading(true);
        updateEntityViewPreferences(screeningEntityOptions);
    };

    const updateElementsVisibility = (e) => {
        let updatedArray = [...tableColumnsPreferences];
        let foundIndex = updatedArray.findIndex((el) => el.columnId === e.target.name);
        if (foundIndex) {
            updatedArray[foundIndex] = { ...updatedArray[foundIndex], hidden: !e.target.checked };
            updateColumnsPreferencesAndSave(updatedArray);
        }
    };

    const getSelectedItemsIds = () => {
        return !selectedItems || !selectedItems.length ? [] : selectedItems.map((elem) => elem.id);
    };

    const resetTable = () => {
        setIsLoading(true);
        const screeningEntityOptions = {
            columns: {},
            sorting: {
                sortBy: '',
                direction: SORT_DIRECTION.ASC,
            },
        };

        const columnsFromUserPreferencesGeneralSettings = getColumns().filter(
            (columnObj) =>
                userPreferencesContentTypes.some(
                    (contentType) => contentType.name === columnObj.columnId && contentType.value
                ) ||
                columnObj.columnId === ENTITY_VIEW_CREATED_DATE ||
                columnObj.columnId === ENTITY_VIEW_LAST_UPDATE_DATE
        );

        columnsFromUserPreferencesGeneralSettings.forEach((columnObj, index) => {
            const isValidContentType =
                columnObj.columnId !== MULTIPLE_ENTITIES_COMMENTS && columnObj.id !== MULTIPLE_ENTITIES_ENTITY_NAME;
            if (isValidContentType) {
                screeningEntityOptions.columns[columnObj.columnId] = {};
                screeningEntityOptions.columns[columnObj.columnId].isHidden = false;
                screeningEntityOptions.columns[columnObj.columnId].order = index - 1;
                screeningEntityOptions.columns[columnObj.columnId].width = columnObj.width;
            }
        });

        const defaultColumnMap = getColumnMap(shouldDisplayRiskScore, handleSortClick, sortInfo);
        const filteredColumnKeys = Object.keys(defaultColumnMap).filter((key) =>
            Object.keys(screeningEntityOptions.columns).some((column) => column === key)
        );
        const filteredColumnMap = filteredColumnKeys.reduce((acc, key) => {
            acc[key] = defaultColumnMap[key];
            return acc;
        }, {});

        // reset table column map so there's no delay when columns are unhidden
        // set table columns to default order and unhide hidden columns
        // set table column widths to default
        setColumnsWidthInfo(screeningEntityOptions.columns);
        setTableColumnMap(filteredColumnMap);
        updateEntityViewPreferences(screeningEntityOptions);
    };

    // TODO: check with team members if delete function should be a generic one and moved into TableHandlersProvider.jsx file
    const handleDeleteMultiple = () => {
        const selectedElements = selectedItems || [];

        const count = getSelectedItemsCount(
            selectAllSetting,
            entityViewCount,
            unselectedItemIds.length,
            selectedElements.length
        );

        const modalProps = {
            primaryBtnText: 'General_CoreFunctionality_UIText_general.delete',
            title:
                count === 1 ? (
                    <FormattedMessage id={'BatchScreening.page.table.delete.titleDeleteEntity'} />
                ) : (
                    <FormattedMessage id={'BatchScreening.page.table.delete.titleDeleteEntities'} />
                ),
            description:
                count === 1 ? (
                    <FormattedMessage id={'BatchScreening.page.table.delete.confirmDeleteEntity'} />
                ) : (
                    <FormattedMessage
                        id={'BatchScreening.page.table.delete.confirmDeleteEntities'}
                        values={{ count }}
                    />
                ),
        };
        props.onConfirmModal(modalProps, async () => {
            const urlParams = getParamsForUrl({ lastSelectedView, searchEntity });
            try {
                setIsAnyActionLoading(true);

                const payload = getSelectionPayload(
                    selectAllSetting,
                    getSelectedItemsIds(),
                    unselectedItemIds,
                    sortInfo,
                    displayRiskScores
                );

                const [batchId, error] = await EntityViewApi.deleteBatchEntities(payload, urlParams);

                if (error) {
                    setIsAnyActionLoading(false);
                    handleEntityViewApiError(error, urlParams);
                } else {

                    //set the initial data for the delete notification/snackbar
                    const deleteNotificationData = snackbarUtils.getProcessingStatusInitialData({
                        actionType: ACTION_TYPES.DELETE,
                        totalCount: getSelectedItemsCount(
                            selectAllSetting,
                            entityViewCount,
                            unselectedItemIds.length,
                            selectedItems.length
                        ),
                    });

                    dispatch(
                        mainActions.addNewBatchId({
                            batchId,
                            data: deleteNotificationData,
                            keyInTheStore: NOTIFICATION_STORE_KEYS.DELETE,
                        })
                    );

                    setLastPerformedAction(TOOLBAR_ACTIONS.actionPolling);
                    notificationService.pollDeleteEntityView({
                        batchId,
                        onFinished: () => {
                            setLastPerformedAction(TOOLBAR_ACTIONS.deleteEntity);
                            dispatch(mainActions.setShouldGetAllViews(true));
                            // we need to update views list for the cases when there are views that contain only those entities that have been deleted
                            getAllViews();
                            updateAllEntitiesCount();
                            props.handleTotalEntitiesCountChange();
                            clearAllSelectedItems();
                            setTableFocus(entitiesTableFocus);
                            setIsAnyActionLoading(false);
                        },
                        numberOfEntities: payload.entityIds?.length,
                        route: ROUTES.SCREENING
                    });
                }
            } catch (e) {
                setIsAnyActionLoading(false);
                handleEntityViewApiError(e, urlParams);
            }
        });
    };

    // TODO: check with team members if copy function should be a generic one and moved into TableHandlersProvider.jsx file
    const handleCopyMultiple = async () => {
        setIsAnyActionLoading(true);

        const payload = getSelectionPayload(
            selectAllSetting,
            getSelectedItemsIds(),
            unselectedItemIds,
            sortInfo,
            displayRiskScores
        );
        const urlParams = getParamsForUrl({ lastSelectedView, searchEntity });

        const [batchId, error] = await EntityViewApi.copyBatchEntities(payload, urlParams);

        if (error) {
            handleEntityViewApiError(error, urlParams);
            setIsAnyActionLoading(false);
        } else {
            //set the initial data for the copy notification/snackbar
            const copyNotificationData = snackbarUtils.getProcessingStatusInitialData({
                actionType: ACTION_TYPES.COPY,
                totalCount: getSelectedItemsCount(
                    selectAllSetting,
                    entityViewCount,
                    unselectedItemIds.length,
                    selectedItems.length
                ),
            });

            dispatch(mainActions.addNewBatchId({batchId, data: copyNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.COPY}));

            notificationService.pollUploadEntityView(
                {
                    batchId,
                    route: ROUTES.SCREENING,
                    onFinished: () => onFinishedCopyPolling(),
                },
                true
            );
        }
    };

    const onFinishedCopyPolling = () => {
        updateAllEntitiesCount();
        props.handleTotalEntitiesCountChange();
        clearAllSelectedItems();
        setTableFocus(entitiesTableFocus);
        setIsAnyActionLoading(false);
    };

    const handleRefreshEntity = async (shouldResetToUserPreferences = false) => {
        const entityIds = getSelectedItemsIds();
        const isForAll = isSelectAllChecked(selectAllSetting);

        const payload = {
            ...getSelectionPayload(selectAllSetting, entityIds, unselectedItemIds, sortInfo, displayRiskScores),
            billingId,
            shouldResetToUserPreferences,
        };

        setIsAnyActionLoading(true);

        const paramsForUrl = isForAll
            ? getParamsForUrl({ lastSelectedView, searchEntity })
            : getParamsForUrl({ lastSelectedView });
        const [batchId, error] = await EntityViewApi.refreshBatchEntities(payload, paramsForUrl);

        if (error) {
            handleEntityViewApiError(error, paramsForUrl);
            setIsAnyActionLoading(false);
        } else {
            //set the initial data for the copy notification/snackbar
            const refreshNotificationData = snackbarUtils.getProcessingStatusInitialData({
                actionType: ACTION_TYPES.REFRESH,
                totalCount: getSelectedItemsCount(
                    selectAllSetting,
                    entityViewCount,
                    unselectedItemIds.length,
                    selectedItems.length
                ),
            });

            dispatch(mainActions.addNewBatchId({batchId, data: refreshNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.REFRESH}));

            setLastPerformedAction(TOOLBAR_ACTIONS.refresh);
            clearAllSelectedItems();
            setIsAnyActionLoading(false);
            setRefreshEntities(entityIds);
            dispatch(mainActions.updateEntityViewInformation([]));
            const updatedBlockedEntities = blockedEntities?.filter(
                (blockedEntity) => !entityIds.includes(blockedEntity)
            );
            dispatch(mainActions.updateBlockedEntities(updatedBlockedEntities));

            notificationService.pollRefreshEntityView({
                batchId,
                isForAll,
                onFinished: () => {
                    notificationService.pollInProgressEntitiesData({dispatch, route: ROUTES.SCREENING, handleEntityViewApiError});
                    notificationService.pollEntitiesCounts({ unreadyEntityIds: entityIds, handleEntityViewApiError })
                },
            });

            let newEntitiesIds = [];
            if (entitiesNeedUpdate) {
                newEntitiesIds = differenceBy(entityIds, entitiesNeedUpdate, (item) => item.id);
                setRefreshEntities(newEntitiesIds);
            } else {
                setRefreshEntities([]);
                clearAllSelectedItems();
            }
        }
    };

    // setting up skeleton loader right after refresh button is clicked or on upload
    const refreshEntitiesCustomUseEffect = useCallback(
        (tableData, setTableData) => {
            let newTableData = [];
            if (refreshEntities.length || entitiesNeedUpdate.length) {
                newTableData = tableData.map((entity) => {
                    if (refreshEntities && refreshEntities.includes(entity.id)) {
                        entity.counts = {};
                        entity.isReady = false;
                        entity.failed = false;
                    }
                    if (entitiesNeedUpdate && entitiesNeedUpdate.length) {
                        const entityFound = entitiesNeedUpdate.find((item) => item.id === entity.id);
                        if (entityFound) {
                            entity.counts = entityFound.counts;
                            entity.negativity = entityFound.negativity;
                            entity.isReady = entityFound.isReady;
                            entity.failed = entityFound.failed;
                        }
                    }
                    return entity;
                });
                setTableData(newTableData);
            }

            if (
                refreshEntities.length &&
                entitiesNeedUpdate.length &&
                refreshEntities.length === entitiesNeedUpdate.length
            ) {
                let noDif = true;
                entitiesNeedUpdate.forEach((item) => {
                    if (!refreshEntities.includes(item.id)) noDif = false;
                });
                if (noDif) {
                    //NOTE: removed the clear all selected entities call here to fix DIL-8620, can be added again in future if select/refresh logic needs to change again.
                    setRefreshEntities([]);
                }
                setTableData(newTableData);
            }
        },
        [refreshEntities, entitiesNeedUpdate]
    );

    const handleCreateAlerts = () => {
        const selectedElements = getSelectedItemsIds();
        const selectedSearchQueryTypes = [
            ...new Set(selectedItems.map(({ searchQueryType }) => QUERY_SEARCH_TYPES[searchQueryType])),
        ];

        const enabledCategoryKeys = [...new Set(selectedItems.flatMap((item) => Object.keys(item.counts)))];
        const alertContentTypes = enabledCategoryKeys.length
            ? userPreferencesContentTypes.map((contentType) => ({
                ...contentType,
                value: enabledCategoryKeys.includes(contentType.name),
            }))
            : null;

        const popupModel = new PopupModel.Builder(MODAL_TYPE.CREATE_ALERTS)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('emailAddress', userEmail)
            .setPopupProperty('commentsLabel', 'Alerts.createAlert.comments')
            .setPopupProperty('commentsDescription', 'Alerts.createAlert.tip')
            .setPopupProperty('commentsCharLimit', ALERT_COMMENTS_MAX_CHARS)
            .setPopupProperty('selectedSearchQueryTypes', selectedSearchQueryTypes)
            .setPopupProperty('alertContentTypes', alertContentTypes)
            .setPopupProperty('selectAllSetting', selectAllSetting)
            .setPopupProperty('paramsForUrl', getParamsForUrl({ lastSelectedView, searchEntity }))
            .setPopupProperty('actionCallback', () => {})
            .setPopupProperty('timezone', timezone)
            .setPopupProperty('onDeliveryApiError', handleEntityViewApiError)
            .setPopupProperty('getPayloadCallback', () => {
                return getSelectionPayload(
                    selectAllSetting,
                    selectedElements,
                    unselectedItemIds,
                    sortInfo,
                    displayRiskScores
                );
            })
            .setPopupProperty('onDeliverySuccess', (response) => {
                utils.showNotificationsMessage({
                    messageText: 'BatchScreening.notification.alertCreated',
                    messageType: 'success',
                });
                const batchId = response.text;
                const createAlertsNotificationData = snackbarUtils.getProcessingStatusInitialData({
                        actionType: ACTION_TYPES.CREATE,
                        totalCount: getSelectedItemsCount(
                            selectAllSetting,
                            entityViewCount,
                            unselectedItemIds.length,
                            selectedElements.length
                        ),
                    });

                dispatch(mainActions.addNewBatchId({batchId, data: createAlertsNotificationData, keyInTheStore: NOTIFICATION_STORE_KEYS.CREATE}));
                notificationService.pollEntityAlertsCreation({route: ROUTES.SCREENING, onFinished: () => {
                    clearAllSelectedItems();
                },});
            })
            .build();

        dispatch(popupModelActions.setPopupModel(popupModel));
    };
    const downloadReport = async (modalType) => {
        const selectedElements = selectedItems || [];

        if (!selectedElements.length) return;

        const currentTime = utils.getCurrentTime();
        const entityName =
            selectedElements.length === 1
                ? utils.formatReportFileName(selectedElements[0].entityName)
                : MULTIPLE_ENTITIES_PREFIX_FILENAME;

        const deliveryData = {
            billingId,
            timezone,
            language: language,
            startEachArticleOnNewPage,

            // TODO: Modify categoryOrder after the columns order is implemented for batch screening
            categoryOrder: userPreferencesContentTypes.map((category) => ({
                sourceType: category.name,
                order: category.reportOrder,
            })),
        };

        const dateRangeForCategories = categoryUtils.getDateRangeForCategories({
            adHocNewsDateRange: adHocSearch?.newsDateRange,
            adHocLegalDateRange: adHocSearch?.legalDateRange,
            adHocCompanyDateRange: adHocSearch?.companyDateRange,
            generalSettings: generalSettings,
        });
        const searchQueryType = selectedElements.some(
            (element) => element.searchQueryType === COMPANY_SEARCH_QUERY_TYPE
        )
            ? QUERY_SEARCH_TYPES.SEARCH_FOR_COMPANY
            : QUERY_SEARCH_TYPES.SEARCH_FOR_PERSON;
        let popupModel = new PopupModel.Builder(modalType)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('deliveryData', deliveryData)
            .setPopupProperty('paramsForUrl', getParamsForUrl({ lastSelectedView, searchEntity }))
            .setPopupProperty('onDeliveryApiError', handleEntityViewApiError)
            .setPopupProperty('dateRangeForCategories', dateRangeForCategories)
            .setPopupProperty('getPayloadCallback', () => {
                return getSelectionPayload(
                    selectAllSetting,
                    selectedElements.map((elem) => elem.id),
                    unselectedItemIds,
                    sortInfo,
                    displayRiskScores
                );
            });

        if (modalType === MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT) {
            const fileName = `${DOWNLOAD_DEFAULT_FILENAME}${entityName}_All_${utils.formatReportDateWithtTimezoneOffset(
                currentTime
            )}`;
            popupModel = popupModel
                .setPopupProperty('fileName', fileName)
                .setPopupProperty('contentsOptions', DEFAULT_DELIVERY_OPTIONS.snapshotDeliveries)
                .setPopupProperty('showDeliveryOptions', true)
                .setPopupProperty('snapshotAllResultsDownload', true)
                .setPopupProperty('dateRangeForCategories', dateRangeForCategories)
                .setPopupProperty('reportContentType', REPORT_CONTENT_TYPES.FULL_REPORT)
                .setPopupProperty('searchQueryType', searchQueryType);
        } else if (modalType === MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT) {
            const fileName = `${DOWNLOAD_SNAPSHOT_DEFAULT_FILENAME}${entityName}_${utils.formatReportDateWithtTimezoneOffset(
                currentTime
            )}`;
            popupModel = popupModel
                .setPopupProperty('fileName', fileName)
                .setPopupProperty('reportContentType', REPORT_CONTENT_TYPES.SNAPSHOT)
                .setPopupProperty('contentsOptions', DEFAULT_DELIVERY_OPTIONS.snapshotPageOnlyDelivery);
        }

        popupModel.build();
        dispatch(popupModelActions.setPopupModel(popupModel));
    };

    const handleShareView = () => {
        const popupModel = new PopupModel.Builder(MODAL_TYPE.SHARE_VIEW)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('selectedViewId', selectedViewId);

        popupModel.build();
        dispatch(popupModelActions.setPopupModel(popupModel));
    };

    const getSelectedName = (row) => row.displayName;

    const syncSelectionWithTableData = (selection, tableData) => {
        if (selection.selectedItems.length) {
            selection.selectedItems.forEach((elem, index) => {
                const tableDataIsReady = tableData.find((tableRow) => tableRow.id === elem.id)?.isReady;
                const selectionIsReady = elem.isReady;

                if (tableDataIsReady !== selectionIsReady) {
                    selection.selectedItems[index].isReady = tableDataIsReady;
                }
            });
        }
    };

    const getActionDownloadPopover = () => {
        return {
            isOpen: isDownloadDropdownOpen,
            dropdownClassName: 'download-action-button',
            dropdownRows: [
                {
                    onClick: async () => {
                        setIsDownloadDropdownOpen(prev => !prev);
                        await downloadReport(MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT);
                    },
                    formatMessageId: 'BatchScreening.page.table.download.fullReport',
                    id: 'batch_download_full_report',
                },
                {
                    onClick: async () => {
                        setIsDownloadDropdownOpen(prev => !prev);
                        await downloadReport(MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_SNAPSHOT)
                    },
                    formatMessageId: 'Delivery.options.shouldContainSnapshot',
                    id: 'batch_download_snapshot_report',
                },
            ],
        };
    };

    const actions = (selection, tableData) => {
        const areEntitiesSelected = selection.selectedItems.length;

        const deleteButtonDisabled = !areEntitiesSelected;
        let refreshButtonDisabled = !areEntitiesSelected,
            isCopyButtonDisabled = false,
            actionButtonsDisabled = !areEntitiesSelected,
            isAnyEntityRefreshing = selection.selectedItems.some((item) => !item.isReady);

        const noViewSelected = lastSelectedView === MULTIPLE_ENTITIES_NONE_SELECTED_VIEW;

        const isShareViewButtonDisabled =
            isAnyActionLoading || noViewSelected || !isShareable || isMultipleEntitiesTrial;

        syncSelectionWithTableData(selection, tableData);

        if (!actionButtonsDisabled) {
            actionButtonsDisabled = isAnyEntityRefreshing;
        }

        if (isMultipleEntitiesTrial) {
            isCopyButtonDisabled =
                !entityUploadLimit || selection.selectedItems.length + props.totalNumberOfEntities > entityUploadLimit;
        }

        const disableClassname = isAnyActionLoading || actionButtonsDisabled ? 'disabled' : '';
        const downloadButtonClassname = `la-AddToReport ${disableClassname}`;
        const alertButtonClassname = `la-Alerts ${disableClassname}`;
        const deleteButtonClassname = `la-Delete ${isAnyActionLoading || deleteButtonDisabled ? 'disabled' : ''}`;
        const copyButtonClassname = `la-CopyBatch ${disableClassname || isCopyButtonDisabled ? 'disabled' : ''}`;
        const refreshButtonDropdownClassname = `la-Redo ${isRefreshDropdownOpen ? 'la-TriangleUp' : 'la-TriangleDown'}${isAnyActionLoading || refreshButtonDisabled ? ' disabled' : ''}`;
        const shareViewButtonClassname = `la-ShareView ${isShareViewButtonDisabled ? 'disabled' : ''}`;

        const actionDownloadButtonPopover = getActionDownloadPopover();

        const refreshDropdownMenu = {
            isOpen: isRefreshDropdownOpen,
            dropdownClassName: 'multiple-entities-refresh-dropdown',
            dropdownRows: [
                {
                    onClick: async () => {
                        setIsRefreshDropdownOpen(!isRefreshDropdownOpen);
                        await handleRefreshEntity();
                    },
                    formatMessageId: 'BatchScreening.page.actionButton.refresh.dropdown1',
                    id: 'refresh_entity',
                },
                {
                    onClick: async () => {
                        setIsRefreshDropdownOpen(!isRefreshDropdownOpen);
                        await handleRefreshEntity(true);
                    },
                    formatMessageId: 'BatchScreening.page.actionButton.refresh.dropdown2',
                    id: 'reset_to_user_preferences',
                },
            ],
        };

        return (
            <>
                {(!isRefreshDropdownOpen && !isDownloadDropdownOpen) && (
                    <ReactTooltip
                        id="tooltip-action"
                        type="light"
                        event="mouseenter"
                        eventOff="mouseleave"
                        border={true}
                        effect="solid"
                        className="tooltips"
                        place="bottom"
                    />
                )}
                {isShareViewsEnabled && (
                    <ReactTooltip
                        id="shareview-tooltip"
                        type="light"
                        event="mouseenter"
                        eventOff="mouseleave"
                        border={true}
                        effect="solid"
                        className="tooltips"
                        place="bottom"
                    />
                )}
                <ActionButton
                    disabled={isAnyActionLoading || actionButtonsDisabled}
                    id="qa-batch-screening-action_download"
                    value="qa-batch-screening-action_download"
                    testid="qa-batch-screening-action_download-testId"
                    handleClick={() => setIsDownloadDropdownOpen((prev) => !prev)}
                    className={downloadButtonClassname}
                    dataTip={getTooltipMessage(
                        { areEntitiesRefreshing: isAnyEntityRefreshing, isButtonDisabled: !areEntitiesSelected },
                        TOOLBAR_ACTIONS.generateReport,
                        props.intl,
                        'ArticleAction.generateReport'
                    )}
                    dataFor="tooltip-action"
                    popover={actionDownloadButtonPopover}
                    shouldDisplayPopover={true}
                    callback={() => setIsDownloadDropdownOpen(false)}
                />
                <span className="vertical-divider" />
                <ActionButton
                    disabled={isAnyActionLoading || deleteButtonDisabled}
                    handleClick={handleDeleteMultiple}
                    id="qa-batch-screening-action_delete"
                    testid="qa-batch-screening-action_delete-testId"
                    className={deleteButtonClassname}
                    dataTip={getTooltipMessage(
                        { isButtonDisabled: !areEntitiesSelected },
                        TOOLBAR_ACTIONS.deleteEntity,
                        props.intl,
                        'General_CoreFunctionality_UIText_general.delete'
                    )}
                    dataFor="tooltip-action"
                />
                <span className="vertical-divider" />
                <ActionButton
                    disabled={isAnyActionLoading || actionButtonsDisabled || isCopyButtonDisabled}
                    handleClick={handleCopyMultiple}
                    testid="qa-batch-screening-copy-testId"
                    id="qa-batch-screening-copy"
                    className={copyButtonClassname}
                    dataTip={getTooltipMessage(
                        { areEntitiesRefreshing: isAnyEntityRefreshing, isButtonDisabled: !areEntitiesSelected },
                        TOOLBAR_ACTIONS.copy,
                        props.intl,
                        'BatchScreening.page.actionButton.copy'
                    )}
                    dataFor="tooltip-action"
                />
                <span className="vertical-divider" />
                <ActionButton
                    disabled={isAnyActionLoading || actionButtonsDisabled}
                    handleClick={handleCreateAlerts}
                    id="qa-batch-screening-action_create-alert"
                    testid="qa-batch-screening-action_create-alert-testId"
                    className={alertButtonClassname}
                    dataTip={getTooltipMessage(
                        { areEntitiesRefreshing: isAnyEntityRefreshing, isButtonDisabled: !areEntitiesSelected },
                        TOOLBAR_ACTIONS.alert,
                        props.intl,
                        'BatchScreening.page.actionButton.alerts'
                    )}
                    dataFor="tooltip-action"
                />
                <span className="vertical-divider" />
                <ActionButton
                    disabled={refreshButtonDisabled}
                    className={refreshButtonDropdownClassname}
                    handleClick={() => setIsRefreshDropdownOpen((prev) => !prev)}
                    id="qa-batch-screening-action_refresh"
                    testid="qa-batch-screening-action_refresh-testid"
                    dataTip={getTooltipMessage(
                        { areEntitiesRefreshing: refreshButtonDisabled, isButtonDisabled: !areEntitiesSelected },
                        TOOLBAR_ACTIONS.refresh,
                        props.intl,
                        'BatchScreening.page.actionButton.refresh'
                    )}
                    dataFor={'tooltip-action'}
                    popover={refreshDropdownMenu}
                    shouldDisplayPopover={true}
                    callback={() => setIsRefreshDropdownOpen(false)}
                />
                {isShareViewsEnabled && (
                    <>
                        <span className="vertical-divider" />
                        <ActionButton
                            disabled={isShareViewButtonDisabled}
                            className={shareViewButtonClassname}
                            handleClick={handleShareView}
                            id="qa-batch-screening-action_share_view"
                            testid="qa-batch-screening-action_share_view-testid"
                            dataTip={getTooltipMessage(
                                {
                                    isShareViewButtonDisabled,
                                    noViewSelected,
                                    isTrial: isMultipleEntitiesTrial,
                                },
                                TOOLBAR_ACTIONS.shareView,
                                props.intl
                            )}
                            dataFor="shareview-tooltip"
                        />
                    </>
                )}
            </>
        );
    };

    const handleColumnsOrderChange = (updatedColumns) => {
        const updatedTableColumnsPrefs = [...tableColumnsPreferences];

        updatedColumns.forEach((column, index) => {
            column.order = index - 1;
        });
        // 'entityName' column
        updatedColumns[0].order = -1;

        updatedColumns.forEach((col) => {
            const columnPrefsIndex = updatedTableColumnsPrefs.findIndex((el) => el.columnId === col.columnId);
            if (columnPrefsIndex !== -1) {
                updatedTableColumnsPrefs[columnPrefsIndex].order =
                    col.columnId === 'comments' ? updatedTableColumnsPrefs.length : col.order;
            }
        });
        updateColumnsPreferencesAndSave(updatedTableColumnsPrefs);
    };

    const handleColumnsResizeChange = (columnsUpdatedWidthInfo) => {
        const columnsOptions = {
            columns: { ...columnsPreferences.columns },
            sorting: { ...columnsPreferences.sorting },
        };
        columnsUpdatedWidthInfo.forEach((columnObj) => {
            if (
                columnObj.columnId !== MULTIPLE_ENTITIES_ENTITY_NAME &&
                columnObj.columnId !== MULTIPLE_ENTITIES_COMMENTS
            ) {
                columnsOptions.columns[columnObj.columnId].width = columnObj.width;
            }
        });

        setColumnsWidthInfo(columnsOptions.columns);
        updateEntityViewPreferences(columnsOptions);
    };

    
    const getBatchEntities = useCallback(
        (pageNumber) => {
            const { direction, sortBy } = sortInfo;
            
            const sortInfoServer = Object.assign({}, sortInfo);
            sortInfoServer.sortBy  = (sortBy === 'entityName'? 'displayName' : sortBy); 
            
            const getEntitiesInViewPayload = {
                viewId: selectedView.id || lastSelectedView,
                searchQuery: searchEntity,
                pageNumber: pageNumber,
                pageSize: BATCH_SCREENING_PAGE_SIZE,
                sortBy: sortInfoServer.sortBy,
                sortDirection: direction,
                displayRiskScores,
            };

            if (searchEntity) {
                return {
                    request: EntityViewApi.filterEntities(getEntitiesInViewPayload),
                    payload: getEntitiesInViewPayload,
                };
            } else if (lastSelectedView !== MULTIPLE_ENTITIES_NONE_SELECTED_VIEW) {
                return {
                    request: EntityViewApi.getViewEntities(getEntitiesInViewPayload),
                    payload: getEntitiesInViewPayload,
                };
            }

            return {
                request: EntityViewApi.getBatchEntities(sortInfoServer, displayRiskScores, pageNumber),
                payload: undefined,
            };
        },
        [lastSelectedView, searchEntity, sortInfo]
    );

    const updateAllEntitiesCount = () => {
        const urlParams = getParamsForUrl({ lastSelectedView, searchEntity });
        dispatch(updateAllEntitiesInContextCountAsync(handleEntityViewApiError, urlParams));
    };

    const handleSelectAllEntities = ({ shouldIncludeDB, shouldClear }) => {
        setUnselectedItemIds([]);

        if (shouldClear) {
            setSelectAllSetting({ includeDB: false, limit: 0 });
            return;
        }

        if (shouldIncludeDB) {
            setSelectAllSetting({ includeDB: true, limit: 0 });
        } else if (props.loadedEntitiesCount === entityViewCount) {
            setSelectAllSetting({ includeDB: true, limit: 0 });
        } else {
            setSelectAllSetting({ includeDB: false, limit: props.loadedEntitiesCount });
        }
    };

    const onLoadedEntitiesCountChange = () => {
        if (onlyLoadedEntitiesAreSelected(selectAllSetting)) {
            setSelectAllSetting((prev) => {
                return { ...prev, limit: props.loadedEntitiesCount };
            });

            if (props.loadedEntitiesCount === entityViewCount) {
                setSelectAllSetting({ includeDB: true, limit: 0 });
            }
        }
    };

    const commentsToDisplay = isNumber(activeCommentsSectionRowId) ? commentsList[activeCommentsSectionRowId] : null;
    const nrOfCommentsLimitReached = useMemo(
        () => numberOfComments >= screeningEntityCommentsLimit,
        [numberOfComments]
    );
    const disableCommentsInput = useMemo(
        () => !editCommentId && nrOfCommentsLimitReached,
        [editCommentId, nrOfCommentsLimitReached]
    );

    const updateDisplayName = async (entityId, newDisplayName) => {
        setEntityChangesSaved(entityId);

        setUpdatedEntities((updatedEntities) => ({
            ...updatedEntities,
            [entityId]: newDisplayName,
        }));

        const payload = {
            displayName: newDisplayName,
            viewId: selectedView.id || lastSelectedView,
        };
        const [response, error] = await EntityViewApi.updateEntityDisplayName(entityId, payload);

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'BatchScreening.generalError.message',
                messageType: 'system-error',
            });
        }

        return response;
    };

    const updateEntityData = async (entityId, updates) => {
        const { displayName, entityName, entityType } = updates;
        const { query, prefilterQuery } = utils.extractQueryFromBooleanTerms(entityName);

        setUpdatedEntities((updatedEntities) => ({
            ...updatedEntities,
            [entityId]: displayName,
        }));
        setEntityChangesSaved(entityId);

        const payload = generateEditEntityPayload({
            displayName,
            entityName: query,
            searchQueryType: entityType,
            prefilterQuery:
                (prefilterQuery.length && utils.removeFirstBooleanOperator(prefilterQuery)) || prefilterQuery,
            contentTypes: userPreferencesContentTypes,
            timezone,
            language,
            startEachArticleOnNewPage,
            billingId,
            costCode: costCodeUtils.getCostCode(),
            viewId: selectedView.id || lastSelectedView,
        });

        const [, error] = await EntityViewApi.updateEntitySearchData(entityId, payload);

        if (error) {
            utils.showNotificationsMessage({
                messageText: 'BatchScreening.generalError.message',
                messageType: 'system-error',
            });
        } else {
            dispatch(mainActions.updateEntityViewInformation([]));

            notificationService.pollEntitiesCounts({ unreadyEntityIds: [entityId] })
            notificationService.pollInProgressEntitiesData({ dispatch, route: ROUTES.SCREENING, handleEntityViewApiError });
            
            setRefreshEntities([entityId]);
        }
    };

    const saveEditedSearch = (data) => {
        const { entityId, newData, oldData } = data;
        const changes = utils.getDifferences(newData, oldData);
        const noOfChanges = Object.keys(changes).length;

        if (noOfChanges === 1 && changes.displayName) {
            // only displayName was changed, we should call a separate endpoint
            updateDisplayName(entityId, newData.displayName);
        } else if (noOfChanges > 0) {
            updateEntityData(entityId, newData);
        }
    };

    const openEditSearch = (entityId, displayName, entityName, entityType) => {
        const popupModel = new PopupModel.Builder(MODAL_TYPE.EDIT_SEARCH_MULTIPLE_ENTITIES)
            .setPopupProperty('isVisible', true)
            .setPopupProperty('entityInfo', { entityId, displayName, entityName, entityType })
            .setPopupProperty('acceptAction', saveEditedSearch)
            .build();

        dispatch(popupModelActions.setPopupModel(popupModel));
    };

    const triggerPolling = (unreadyEntityIds) => {
        notificationService.pollEntitiesCounts({ unreadyEntityIds, handleEntityViewApiError })
        notificationService.pollInProgressEntitiesData({ dispatch, route: ROUTES.SCREENING, handleEntityViewApiError });
    };

    const onSearchEntityChanged = (entity) => {
        setSearchEntity(() => {
            setLastPerformedAction(TOOLBAR_ACTIONS.searchEntity);
            return entity;
          });
    };

    // props for editing entity names
    const updateEntityNameProps = {
        updatedEntities,
        openEditSearch,
        entityChangesSaved,
    };

    //extraProps should contain all the props that are particular to the batch screening
    const extraProps = {
        comments: {
            commentsList,
            commentDisplayName,
            activeCommentsSectionRowId,
            handleCommentIconClick,
            setCommentsList,
        },
        isSnapshotVisible,
        searchResults,
        personCheck,
        companyCheck,
        investigation,
        isNewResearchSummaryEnabled,
        dispatch,
        updateEntityNameProps,
        tableHasData,
        blockedEntities,
        searchEntity,
        language,
        entityOwner
    };

    const createViewComponentProps = {
        selectedView,
        setSelectedView,
        views,
        createView,
        deleteView,
        changeView,
        resetView,
        updateView,
        lastSelectedView,
        searchEntity,
        setSearchEntity,
        selectAllSetting,
    };

    return (
        <>
            {showPopupModal && (
                <PopupModal 
                    changeView={changeView} 
                    resetView={resetView} 
                    createView={createView} 
                    selectedView={selectedView} 
                    handleEntityViewApiError={handleEntityViewApiError} 
                />
            )}
            <GridTable
                onApiGet={getBatchEntities}
                onApiGetError={handleEntityViewApiError}
                errorMessage={BATCH_SCREENING_ERROR_MESSAGE}
                triggerPolling={triggerPolling}
                emptyTableComponent={EmptyTableTemplate}
                isCustomView={Object.keys(selectedView).length > 0}
                tableColumns={tableColumns}
                columnMap={tableColumnMap}
                getRowStructure={getRowStructure}
                autoRefresh={shouldAutoRefresh || false}
                shouldDisplay={shouldDisplayRiskScore}
                isSnapshotVisible={isSnapshotVisible}
                withSelectionProps={{
                    selectedItems,
                    setSelectedItems,
                    setSelectAllSetting,
                    setUnselectedItemIds,
                    selectAllSetting,
                }}
                customUseEffectFunction={refreshEntitiesCustomUseEffect}
                customUseEffectDependencies={{
                    refreshEntities,
                    entitiesNeedUpdate,
                    isPageResetAfterRefresh,
                    setIsPageResetAfterRefresh,
                }}
                actions={actions}
                dropdownMessages={BATCH_SCREENING_DROPDOWN_MESSAGES}
                onGetSelectedName={getSelectedName}
                handleColumnsOrderChange={handleColumnsOrderChange}
                handleColumnsResizeChange={handleColumnsResizeChange}
                onSelectAllEntities={handleSelectAllEntities}
                sortInfo={sortInfo}
                customFocusObject={tableFocus}
                extraProps={extraProps}
                entityViewCount={entityViewCount}
                updateTableHasData={props.updateTableHasData}
                updateTableData={props.updateTableData}
                filteringArea={(selection) => (
                    <div className="custom-filtering-area-container">
                        <CreateViewFilter selectedItems={selection} {...createViewComponentProps} />
                        <ManageColumns
                            elementsVisibility={tableColumnsPreferences}
                            updateElementsVisibility={updateElementsVisibility}
                            resetTable={resetTable}
                            isLoading={isLoading}
                            tableColumnsOrder={tableColumnsOrder}
                            handleTableColumnsOrderChange={handleTableColumnsOrderChange}
                        />
                    </div>
                )}
                lastSelectedView={lastSelectedView}
                searchEntity={searchEntity}
                searchingArea={(extraProps) => (
                    <SearchInput
                        placeholderIntlId="BatchScreening.page.searchInput.placeholder"
                        searchEntity={searchEntity}
                        setSearchEntity={onSearchEntityChanged}
                        disabled={false}
                        handleSelectAllEntities={clearAllSelectedItems}
                        extraProps={extraProps}
                    />
                )}
                lastPerformedAction={lastPerformedAction}
                setLastPerformedAction={setLastPerformedAction}
                resetView={resetView}
                uploadPollingActive={uploadPollingActive}
                prevUploadPollingActive={prevUploadPollingActive}
                popupModalType={popupModalType}
                showPopupModal={showPopupModal}
            />

            {isCommentSectionVisible && (
                <Dropdown
                    className="multiple-entities-comment-section"
                    {...commentSectionPosition}
                    onClose={handleCloseCommentSectionClose}
                    closeOnMouseLeave={false}
                    shouldUseFocusTrap={true}
                    shouldExitOnEscape={false}
                    positionAuto={true}
                >
                    <CommentsSection
                        username={commentDisplayName}
                        value={commentInput}
                        maxLength={ENTITIES_COMMENTS_MAX_CHARS}
                        onChange={setCommentInput}
                        onEdit={onEditComment}
                        onDelete={onDeleteComment}
                        onSubmit={submitComment}
                        onClose={handleCloseCommentSectionClose}
                        commentsList={commentsToDisplay}
                        isLoading={commentSectionLoading}
                        deletedCommentId={deletedCommentId}
                        errorMessage={commentSectionError}
                        isInputDisabled={disableCommentsInput}
                    />
                </Dropdown>
            )}
        </>
    );
};

export const TestEntityViewTable = EntityViewTable;

export default withRouter(withConfirmationModal(EntityViewTable));
