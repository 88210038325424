import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import MainActions from "@Main/Main.actions";

const useNotificationDisplayOrder = (
    createNotificationHidden,
    copyNotificationHidden,
    deleteNotificationHidden,
    refreshNotificationHidden,
    uploadNotificationHidden,
    downloadNotificationHidden,
    uploadEntitiesFromAlertsNotificationHidden,
) => {

    const notificationDisplayOrder = useSelector((state => state.notificationDisplayOrder.order));
    const dispatch = useDispatch();

    const handleAddedNotification = (addedNotification) => {
        const filteredArray = notificationDisplayOrder.filter((notification) => notification !== addedNotification);
        const updatedArray = [addedNotification, ...filteredArray];
        dispatch(MainActions.updateNotificationDisplayOrder(updatedArray));
    }

    //These useEffect hooks update the notificationDisplayOrder list based on the latest notification that was added
    useEffect(() => {handleAddedNotification( 'createAlertsStatus');} , [createNotificationHidden]);
    useEffect(() => {handleAddedNotification('copyEntitiesStatus');}, [copyNotificationHidden]);
    useEffect(() => {handleAddedNotification('deleteEntitiesStatus');}, [deleteNotificationHidden]);
    useEffect(() => {handleAddedNotification('refreshEntitiesStatus');}, [refreshNotificationHidden]);
    useEffect(() => {handleAddedNotification('uploadEntitiesStatus');}, [uploadNotificationHidden]);
    useEffect(() => {handleAddedNotification('downloadEntitiesStatus');}, [downloadNotificationHidden]);
    useEffect(() => {handleAddedNotification('uploadEntitiesFromAlertsStatus');}, [uploadEntitiesFromAlertsNotificationHidden]);
};

export default useNotificationDisplayOrder;