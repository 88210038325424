import { isEqual } from 'lodash';

export const USER_GET_PREFERENCES = 'USER_GET_PREFERENCES';
export const ADMIN_GET_PREFERENCES = 'ADMIN_GET_PREFERENCES';
export const USER_UPDATE_SEARCH_TYPE = 'USER_UPDATE_SEARCH_TYPE';
export const USER_UPDATE_SORT = 'USER_UPDATE_SORT';
export const ADMIN_DISCARD_CHANGES = 'ADMIN_DISCARD_CHANGES';
export const USER_PREFERENCES_ADD_CONTENT_TYPE = 'USER_PREFERENCES_ADD_CONTENT_TYPE';
export const ADMIN_PREFERENCES_ADD_CONTENT_TYPE = 'ADMIN_PREFERENCES_ADD_CONTENT_TYPE';
export const USER_PREFERENCES_REMOVE_CONTENT_TYPE = 'USER_PREFERENCES_REMOVE_CONTENT_TYPE';
export const ADMIN_PREFERENCES_REMOVE_CONTENT_TYPE = 'ADMIN_PREFERENCES_REMOVE_CONTENT_TYPE';
export const PREFERENCES_ACTIVE_TAB = 'PREFERENCES_ACTIVE_TAB';
export const PREFERENCES_UPDATE_SHOW_SNAPSHOT = 'PREFERENCES_UPDATE_SHOW_SNAPSHOT';
export const ADMIN_PREFERENCES_UPDATE_SHOW_SNAPSHOT = 'ADMIN_PREFERENCES_UPDATE_SHOW_SNAPSHOT';
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';
export const CHANGE_ADMIN_DATE_RANGE = 'CHANGE_ADMIN_DATE_RANGE';
export const CHANGE_LEGAL_SOURCE = 'CHANGE_LEGAL_SOURCE';
export const CHANGE_ADMIN_LEGAL_SOURCE = 'CHANGE_ADMIN_LEGAL_SOURCE';
export const USER_IS_FUZZY_ENABLED = 'USER_IS_FUZZY_ENABLED';
export const ADMIN_IS_FUZZY_ENABLED = 'ADMIN_IS_FUZZY_ENABLED';
export const USER_GET_FUZZY_MATCH = 'USER_GET_FUZZY_MATCH';
export const ADMIN_GET_FUZZY_MATCH = 'ADMIN_GET_FUZZY_MATCH';
export const UPDATE_FUZZY_NAMES = 'UPDATE_FUZZY_NAMES';
export const REMOVE_FUZZY_NAME = 'REMOVE_FUZZY_NAME';
export const USER_IS_FUZZY_LOADED = 'USER_IS_FUZZY_LOADED';
export const UPDATE_GENERAL_PAGE_SIZE = 'UPDATE_GENERAL_PAGE_SIZE';
export const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
export const RESET_FUZZY_NAMES = 'RESET_FUZZY_NAMES';
export const CHANGE_PROXIMITY = 'CHANGE_PROXIMITY';
export const CHANGE_ADMIN_PROXIMITY = 'CHANGE_ADMIN_PROXIMITY';
export const CHANGE_QUERY_PERSON = 'CHANGE_QUERY_PERSON';
export const CHANGE_ADMIN_QUERY_PERSON = 'CHANGE_ADMIN_QUERY_PERSON';
export const CHANGE_QUERY_COMPANY = 'CHANGE_QUERY_COMPANY';
export const CHANGE_ADMIN_QUERY_COMPANY = 'CHANGE_ADMIN_QUERY_COMPANY';
export const UPDATE_PREFS_NOTICE = 'UPDATE_PREFS_NOTICE';
export const UPDATE_LOCKS = 'UPDATE_LOCKS';
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
export const UPDATE_PERSON_NEGATIVITY_LEVEL = 'UPDATE_PERSON_NEGATIVITY_LEVEL';
export const UPDATE_COMPANY_NEGATIVITY_LEVEL = 'UPDATE_COMPANY_NEGATIVITY_LEVEL';
export const UPDATE_CHECKED_LANGUAGE = 'UPDATE_CHECKED_LANGUAGE';
export const UPDATE_CHECKED_LANGUAGES_UPON_KEY = 'UPDATE_CHECKED_LANGUAGES_UPON_KEY';
export const UPDATE_NEWS_SOURCES_SELECTION = 'UPDATE_NEWS_SOURCES_SELECTION';
export const UPDATE_USER_PREFERENCES_CUSTOM_QUERY = 'UPDATE_USER_PREFERENCES_CUSTOM_QUERY';
export const UPDATE_ADMIN_NEWS_SOURCES_SELECTION = 'UPDATE_ADMIN_NEWS_SOURCES_SELECTION';
export const UPDATE_ADMIN_CHECKED_LANGUAGES_UPON_KEY = 'UPDATE_ADMIN_CHECKED_LANGUAGES_UPON_KEY';
export const UPDATE_ADMIN_CHECKED_LANGUAGE = 'UPDATE_ADMIN_CHECKED_LANGUAGE';
export const UPDATE_ADMIN_PREFERENCES_CUSTOM_QUERY = 'UPDATE_ADMIN_PREFERENCES_CUSTOM_QUERY';
export const UPDATE_ADMIN_PERSON_NEGATIVITY_LEVEL = 'UPDATE_ADMIN_PERSON_NEGATIVITY_LEVEL';
export const UPDATE_ADMIN_COMPANY_NEGATIVITY_LEVEL = 'UPDATE_ADMIN_COMPANY_NEGATIVITY_LEVEL';
export const UPDATE_ADMIN_COST_CODE_ONLY_FROM_COST_CODE_LIST = 'UPDATE_ADMIN_COST_CODE_ONLY_FROM_COST_CODE_LIST';
export const UPDATE_ADMIN_COST_CODE_IS_REQUIRED = 'UPDATE_ADMIN_COST_CODE_IS_REQUIRED';
export const ADD_COST_CODE_TO_ADMIN_LIST = 'ADD_COST_CODE_TO_ADMIN_LIST';
export const DELETE_COST_CODE_FROM_ADMIN_LIST = 'DELETE_COST_CODE_FROM_ADMIN_LIST';
export const UPDATE_USER_COST_CODE = 'UPDATE_USER_COST_CODE';
export const HIDE_LOGIN_HISTORY_BANNER = 'HIDE_LOGIN_HISTORY_BANNER';
export const UPDATE_CONTENT_TYPES = 'UPDATE_CONTENT_TYPES';
export const UPDATE_ADMIN_CONTENT_TYPES = 'UPDATE_ADMIN_CONTENT_TYPES';
export const UPDATE_USER_PREFS_IS_DIRTY = 'UPDATE_USER_PREFS_IS_DIRTY';
export const UPDATE_CUSTOM_NEWS_QUERY_CHECKED_STATE = 'UPDATE_CUSTOM_NEWS_QUERY_CHECKED_STATE';
export const UPDATE_LN_CUSTOM_NEWS_QUERY_CHECKED_STATE = 'UPDATE_LN_CUSTOM_NEWS_QUERY_CHECKED_STATE';
export const DELETE_CUSTOM_NEWS_QUERY = 'DELETE_CUSTOM_NEWS_QUERY';
export const DELETE_ADMIN_CUSTOM_NEWS_QUERY = 'DELETE_ADMIN_CUSTOM_NEWS_QUERY';
export const ADD_USER_PREFS_CUSTOM_NEWS_QUERY = 'ADD_USER_PREFS_CUSTOM_NEWS_QUERY';
export const ADD_ADMIN_PREFS_CUSTOM_NEWS_QUERY = 'ADD_ADMIN_PREFS_CUSTOM_NEWS_QUERY';
export const EDIT_USER_PREFS_CUSTOM_NEWS_QUERY = 'EDIT_USER_PREFS_CUSTOM_NEWS_QUERY';
export const EDIT_USER_PREFS_LN_CUSTOM_NEWS_QUERY = 'EDIT_USER_PREFS_LN_CUSTOM_NEWS_QUERY';
export const EDIT_ADMIN_PREFS_CUSTOM_NEWS_QUERY = 'EDIT_ADMIN_PREFS_CUSTOM_NEWS_QUERY';
export const UPDATE_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE = 'UPDATE_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE';
export const TOGGLE_FUZZY_SELECTION = 'TOGGLE_FUZZY_SELECTION';
export const TOGGLE_ALL_FUZZY_SELECTION = 'TOGGLE_ALL_FUZZY_SELECTION';
export const UPDATE_USER_PREFERENCES_PAYLOAD = 'UPDATE_USER_PREFERENCES_PAYLOAD';
export const UPDATE_FROM_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE = 'UPDATE_FROM_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE';
export const USER_UPDATE_LANGUAGE = 'USER_UPDATE_LANGUAGE';
export const UPDATE_ADMIN_COMPANY_SOURCES_SEARCH_OPTION = 'UPDATE_ADMIN_COMPANY_SOURCES_SEARCH_OPTION';
export const UPDATE_COMPANY_SOURCES_SEARCH_OPTION = 'UPDATE_COMPANY_SOURCES_SEARCH_OPTION';
export const UPDATE_ADMIN_LEGAL_SEARCH_NAME_PARTY_ENTITY = 'UPDATE_ADMIN_LEGAL_SEARCH_NAME_PARTY_ENTITY';
export const UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY = 'UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY';
export const USER_SET_SOURCES_INFORMATION = 'USER_SET_SOURCES_INFORMATION';
export const SET_USER_OFFERINGS = 'SET_USER_OFFERINGS';
export const USER_UPDATE_PREFERENCE = 'USER_UPDATE_PREFERENCE';
export const TOGGLE_DELIVERY_OPTIONS_DISPLAY_PREFERENCE = 'TOGGLE_DELIVERY_OPTIONS_DISPLAY_PREFERENCE';
export const TOGGLE_MODIFIED_BY_USER = 'TOGGLE_MODIFIED_BY_USER';
export const ADMIN_UPDATE_CONTENT_OPTIONS_REPORT_BUILDER = 'ADMIN_UPDATE_CONTENT_OPTIONS_REPORT_BUILDER';
export const UPDATE_CONTENT_OPTIONS_REPORT_BUILDER = 'UPDATE_CONTENT_OPTIONS_REPORT_BUILDER';
export const ADMIN_UPDATE_CONTENT_OPTIONS_RESULTS = 'ADMIN_UPDATE_CONTENT_OPTIONS_RESULTS';
export const UPDATE_CONTENT_OPTIONS_RESULTS = 'UPDATE_CONTENT_OPTIONS_RESULTS';
export const USER_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE = 'USER_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE';
export const ADMIN_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE = 'ADMIN_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE';
export const USER_UPDATE_ALERTS_SORTING = 'USER_UPDATE_ALERTS_SORTING';
export const TOGGLE_USE_NEW_RESEARCH_SUMMARY = 'TOGGLE_USE_NEW_RESEARCH_SUMMARY';
export const USER_UPDATE_FUZZY_THRESHOLD = 'USER_UPDATE_FUZZY_THRESHOLD';
export const ADMIN_UPDATE_FUZZY_THRESHOLD = 'ADMIN_UPDATE_FUZZY_THRESHOLD';
export const USER_IS_FUZZY_ON = 'USER_IS_FUZZY_ON';
export const USER_IS_SUGGESTED_NAMES_ON = 'USER_IS_SUGGESTED_NAMES_ON';
export const ADMIN_IS_FUZZY_ON = 'ADMIN_IS_FUZZY_ON';
export const ADMIN_IS_SUGGESTED_NAMES_ON = 'ADMIN_IS_SUGGESTED_NAMES_ON';
export const UPDATE_EXCLUDE_NON_BUSINESS_NEWS = 'UPDATE_EXCLUDE_NON_BUSINESS_NEWS';
export const ADMIN_UPDATE_EXCLUDE_NON_BUSINESS_NEWS = 'ADMIN_UPDATE_EXCLUDE_NON_BUSINESS_NEWS';
export const UPDATE_EXCLUDE_NEWS_WIRES = 'UPDATE_EXCLUDE_NEWS_WIRES';
export const ADMIN_UPDATE_EXCLUDE_NEWS_WIRES = 'ADMIN_UPDATE_EXCLUDE_NEWS_WIRES';
export const SHOULD_UPDATE_TIMESTAMP_INFO = 'SHOULD_UPDATE_TIMESTAMP_INFO';
export const UPDATE_LAST_PREFERENCES_RETRIEVAL_TIMESTAMP = 'UPDATE_LAST_PREFERENCES_RETRIEVAL_TIMESTAMP';
export const USER_UPDATE_TOGGLE_NEGATIVE_NEWS_VISUALISATION = 'USER_UPDATE_TOGGLE_NEGATIVE_NEWS_VISUALISATION';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
export const UPDATE_FILTER_SECTION_VISIBILITY = 'UPDATE_FILTER_SECTION_VISIBILITY';
export const UPDATE_STYLING_THEME = 'UPDATE_STYLING_THEME';
export const UPDATE_RISK_SCORES = 'UPDATE_RISK_SCORES';
export const UPDATE_BATCH_CONTENT_TYPES_VISIBILITY = 'UPDATE_BATCH_CONTENT_TYPES_VISIBILITY';
export const UPDATE_BATCH_SCREEN_PREFS = 'UPDATE_BATCH_SCREEN_PREFS';
export const UPDATE_SANCTIONS_AUTHORITIES_LIST = 'UPDATE_SANCTIONS_AUTHORITIES_LIST';
export const ADMIN_UPDATE_SANCTIONS_AUTHORITIES_LIST = 'ADMIN_UPDATE_SANCTIONS_AUTHORITIES_LIST';
export const UPDATE_SANCTIONS_RISK_DATE_RANGE = 'UPDATE_SANCTIONS_RISK_DATE_RANGE';
export const ADMIN_UPDATE_SANCTIONS_RISK_DATE_RANGE = 'ADMIN_UPDATE_SANCTIONS_RISK_DATE_RANGE';
export const UPDATE_SANCTIONS_KEYWORDS = 'UPDATE_SANCTIONS_KEYWORDS';
export const ADMIN_UPDATE_SANCTIONS_KEYWORDS = 'ADMIN_UPDATE_SANCTIONS_KEYWORDS';
export const UPDATE_ADMIN_PREFS_IS_DIRTY = 'UPDATE_ADMIN_PREFS_IS_DIRTY';
export const ADMIN_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT = 'ADMIN_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT';
export const USER_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT = 'USER_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT';
export const UPDATE_EXPANDED_FILTER_SECTION = 'UPDATE_EXPANDED_FILTER_SECTION';
export const UPDATE_SANCTIONS_SOURCES_RISKS = 'UPDATE_SANCTIONS_SOURCES_RISKS';
export const ADMIN_UPDATE_SANCTIONS_SOURCES_RISKS = 'ADMIN_UPDATE_SANCTIONS_SOURCES_RISKS';
export const PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS = 'PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS';
export const ADMIN_PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS = 'ADMIN_PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS';

let actions = {
    updateLanguage: function (language) {
        return {
            type: USER_UPDATE_LANGUAGE,
            payload: language,
        };
    },

    updateSearchType: function (searchType) {
        return {
            type: USER_UPDATE_SEARCH_TYPE,
            payload: searchType,
        };
    },

    updateSort: function (sort) {
        return {
            type: USER_UPDATE_SORT,
            payload: sort,
        };
    },

    updateUserAlertsSorting: function (sort) {
        return {
            type: USER_UPDATE_ALERTS_SORTING,
            payload: sort,
        };
    },

    updateFuzzyFunctionality: function (isFuzzyEnabled) {
        return {
            type: USER_IS_FUZZY_ENABLED,
            payload: isFuzzyEnabled,
        };
    },

    updateFuzzyToggle: function (isFuzzyEnabled) {
        return {
            type: USER_IS_FUZZY_ON,
            payload: isFuzzyEnabled,
        };
    },

    updateSuggestedNamesToggle: function (isSuggestedNamesEnabled) {
        return {
            type: USER_IS_SUGGESTED_NAMES_ON,
            payload: isSuggestedNamesEnabled,
        };
    },

    updateAdminFuzzyFunctionality: function (isFuzzyEnabled) {
        return {
            type: ADMIN_IS_FUZZY_ENABLED,
            payload: isFuzzyEnabled,
        };
    },

    updateAdminFuzzyToggle: function (isFuzzyEnabled) {
        return {
            type: ADMIN_IS_FUZZY_ON,
            payload: isFuzzyEnabled,
        };
    },

    updateAdminSuggestedNamesToggle: function (isSuggestedNamesEnabled) {
        return {
            type: ADMIN_IS_SUGGESTED_NAMES_ON,
            payload: isSuggestedNamesEnabled,
        };
    },

    updateFuzzyLoadingStatus: function (isFuzzyLoaded) {
        return {
            type: USER_IS_FUZZY_LOADED,
            payload: isFuzzyLoaded,
        };
    },

    removeFuzzyName: function (fuzzyName) {
        return {
            type: REMOVE_FUZZY_NAME,
            payload: fuzzyName,
        };
    },

    toggleFuzzySelection: function (fuzzyName, clickedIndex, selected) {
        return {
            type: TOGGLE_FUZZY_SELECTION,
            payload: { fuzzyName, clickedIndex, selected },
        };
    },

    toggleAllFuzzySelection: function (visibleFuzzyNames, selected) {
        return {
            type: TOGGLE_ALL_FUZZY_SELECTION,
            payload: { visibleFuzzyNames, selected },
        };
    },

    updateFuzzyMatch: function (fuzzyMatch) {
        return {
            type: USER_GET_FUZZY_MATCH,
            payload: fuzzyMatch,
        };
    },

    updateFuzzyThreshold: function (fuzzyMatch) {
        return {
            type: USER_UPDATE_FUZZY_THRESHOLD,
            payload: fuzzyMatch,
        };
    },

    updateAdminFuzzyMatch: function (fuzzyMatch) {
        return {
            type: ADMIN_GET_FUZZY_MATCH,
            payload: fuzzyMatch,
        };
    },

    updateAdminFuzzyThreshold: function (fuzzyMatch) {
        return {
            type: ADMIN_UPDATE_FUZZY_THRESHOLD,
            payload: fuzzyMatch,
        };
    },

    updatePrefsNotice: function (prefsNotice) {
        return {
            type: UPDATE_PREFS_NOTICE,
            payload: prefsNotice,
        };
    },

    setUserPreferences: function (prefs) {
        return {
            type: SET_USER_PREFERENCES,
            payload: prefs,
        };
    },

    updateLocks: function (userLock) {
        return {
            type: UPDATE_LOCKS,
            payload: userLock,
        };
    },

    updateFuzzyNames: function (fuzzyNames, query) {
        return {
            type: UPDATE_FUZZY_NAMES,
            payload: { list: fuzzyNames, query },
        };
    },

    resetFuzzyNames: function () {
        return {
            type: RESET_FUZZY_NAMES,
        };
    },

    updateDateRange: function (dateRange, section) {
        return {
            type: CHANGE_DATE_RANGE,
            payload: { dateRange, section },
        };
    },

    updateAdminDateRange: function (dateRange, section) {
        return {
            type: CHANGE_ADMIN_DATE_RANGE,
            payload: { dateRange, section },
        };
    },

    updateProximity: function (proximity) {
        return {
            type: CHANGE_PROXIMITY,
            payload: proximity,
        };
    },

    updateAdminProximity: function (proximity) {
        return {
            type: CHANGE_ADMIN_PROXIMITY,
            payload: proximity,
        };
    },

    updateQueryPerson: function (queryArr) {
        return {
            type: CHANGE_QUERY_PERSON,
            payload: queryArr,
        };
    },

    updateAdminQueryPerson: function (queryArr) {
        return {
            type: CHANGE_ADMIN_QUERY_PERSON,
            payload: queryArr,
        };
    },

    updateQueryCompany: function (queryArr) {
        return {
            type: CHANGE_QUERY_COMPANY,
            payload: queryArr,
        };
    },

    updateAdminQueryCompany: function (queryArr) {
        return {
            type: CHANGE_ADMIN_QUERY_COMPANY,
            payload: queryArr,
        };
    },

    updateLegalSource: function (source, section) {
        return {
            type: CHANGE_LEGAL_SOURCE,
            payload: { source, section },
        };
    },

    updateAdminLegalSource: function (source, section) {
        return {
            type: CHANGE_ADMIN_LEGAL_SOURCE,
            payload: { source, section },
        };
    },

    updateSnapshotVisibility: function (showSnapshot) {
        return {
            type: PREFERENCES_UPDATE_SHOW_SNAPSHOT,
            payload: showSnapshot,
        };
    },

    updateAdminSnapshotVisibility: function (showSnapshot) {
        return {
            type: ADMIN_PREFERENCES_UPDATE_SHOW_SNAPSHOT,
            payload: showSnapshot,
        };
    },

    addUserPreferencesContentType: function (contentType) {
        return {
            type: USER_PREFERENCES_ADD_CONTENT_TYPE,
            payload: contentType,
        };
    },

    addAdminPreferencesContentType: function (contentType) {
        return {
            type: ADMIN_PREFERENCES_ADD_CONTENT_TYPE,
            payload: contentType,
        };
    },

    deleteUserPreferencesContentType: function (contentType) {
        return {
            type: USER_PREFERENCES_REMOVE_CONTENT_TYPE,
            payload: contentType,
        };
    },

    deleteAdminPreferencesContentType: function (contentType) {
        return {
            type: ADMIN_PREFERENCES_REMOVE_CONTENT_TYPE,
            payload: contentType,
        };
    },

    shouldUpdateTimestampInfo: function (data) {
        return {
            type: SHOULD_UPDATE_TIMESTAMP_INFO,
            payload: data,
        };
    },

    updateLastPreferencesRetrievalTimestamp: function (timestamp) {
        return {
            type: UPDATE_LAST_PREFERENCES_RETRIEVAL_TIMESTAMP,
            payload: timestamp,
        };
    },

    getUserPrefencesValues: function (preferences) {
        return {
            type: USER_GET_PREFERENCES,
            payload: preferences,
        };
    },

    getAdminPreferencesValues: function (preferences) {
        return {
            type: ADMIN_GET_PREFERENCES,
            payload: preferences,
        };
    },

    setUserSourcesInformation: function (sourcesInformation) {
        return {
            type: USER_SET_SOURCES_INFORMATION,
            payload: sourcesInformation,
        };
    },

    setUserOfferings: function (offerings) {
        return {
            type: SET_USER_OFFERINGS,
            payload: offerings,
        };
    },

    adminDiscardPrefsChanges: function (initialPrefs) {
        return {
            type: ADMIN_DISCARD_CHANGES,
            payload: initialPrefs,
        };
    },

    updateUserPreferencesCustomQuery: function (customQuery, segment) {
        return {
            type: UPDATE_USER_PREFERENCES_CUSTOM_QUERY,
            payload: { customQuery, segment },
        };
    },

    updateAdminPreferencesCustomQuery: function (customQuery, segment) {
        return {
            type: UPDATE_ADMIN_PREFERENCES_CUSTOM_QUERY,
            payload: { customQuery, segment },
        };
    },

    setActiveTab: function (activeTab) {
        return {
            type: PREFERENCES_ACTIVE_TAB,
            payload: activeTab,
        };
    },

    updateGeneralPageSize: function (pageSize) {
        return {
            type: UPDATE_GENERAL_PAGE_SIZE,
            payload: parseInt(pageSize),
        };
    },

    updatePageSize: function (pageSize) {
        return {
            type: UPDATE_PAGE_SIZE,
            payload: pageSize,
        };
    },

    updatePersonNegativityLevel: function (negativityLevel) {
        return {
            type: UPDATE_PERSON_NEGATIVITY_LEVEL,
            payload: negativityLevel,
        };
    },

    updateAdminPersonNegativityLevel: function (negativityLevel) {
        return {
            type: UPDATE_ADMIN_PERSON_NEGATIVITY_LEVEL,
            payload: negativityLevel,
        };
    },

    updateCompanyNegativityLevel: function (negativityLevel) {
        return {
            type: UPDATE_COMPANY_NEGATIVITY_LEVEL,
            payload: negativityLevel,
        };
    },

    updateAdminCompanyNegativityLevel: function (negativityLevel) {
        return {
            type: UPDATE_ADMIN_COMPANY_NEGATIVITY_LEVEL,
            payload: negativityLevel,
        };
    },

    updateCheckedLanguage: function (type, language, isChecked) {
        return {
            type: UPDATE_CHECKED_LANGUAGE,
            payload: { type, language, isChecked },
        };
    },

    updateAdminCheckedLanguage: function (type, language, isChecked) {
        return {
            type: UPDATE_ADMIN_CHECKED_LANGUAGE,
            payload: { type, language, isChecked },
        };
    },

    updateCheckedLanguagesUponKey: function (type, languages) {
        return {
            type: UPDATE_CHECKED_LANGUAGES_UPON_KEY,
            payload: { type, languages },
        };
    },

    updateCustomNewsQueryCheckedState: function (segment, queryName, checked) {
        return {
            type: UPDATE_CUSTOM_NEWS_QUERY_CHECKED_STATE,
            payload: { segment, queryName, checked },
        };
    },
    updateLnCustomNewsQueryCheckedState: function (segment, queryName, checked) {
        return {
            type: UPDATE_LN_CUSTOM_NEWS_QUERY_CHECKED_STATE,
            payload: { segment, queryName, checked },
        };
    },

    updateAdminCreatedCustomNewsQueryCheckedState: function (segment, queryName, checked) {
        return {
            type: UPDATE_FROM_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE,
            payload: { segment, queryName, checked },
        };
    },

    updateAdminCustomNewsQueryCheckedState: function (segment, queryName, checked) {
        return {
            type: UPDATE_ADMIN_CUSTOM_NEWS_QUERY_CHECKED_STATE,
            payload: { segment, queryName, checked },
        };
    },
    deleteCustomNewsQuery: function (segment, queryName) {
        return {
            type: DELETE_CUSTOM_NEWS_QUERY,
            payload: { segment, queryName },
        };
    },

    deleteAdminCustomNewsQuery: function (segment, queryName) {
        return {
            type: DELETE_ADMIN_CUSTOM_NEWS_QUERY,
            payload: { segment, queryName },
        };
    },

    deleteAndUnlockAdminCustomNewsQuery: (segment, queryName, userLock) => (dispatch) => {
        dispatch(actions.deleteAdminCustomNewsQuery(segment, queryName));
        dispatch(actions.updateLocks(userLock));
    },

    updateAdminCheckedLanguagesUponKey: function (type, languages) {
        return {
            type: UPDATE_ADMIN_CHECKED_LANGUAGES_UPON_KEY,
            payload: { type, languages },
        };
    },

    updateNewsSourcesSelection: function (type, selection) {
        return {
            type: UPDATE_NEWS_SOURCES_SELECTION,
            payload: { type, selection },
        };
    },

    updateAdminNewsSourcesSelection: function (type, selection) {
        return {
            type: UPDATE_ADMIN_NEWS_SOURCES_SELECTION,
            payload: { type, selection },
        };
    },

    updateAdminCostCodeIsRequired: function (value) {
        return {
            type: UPDATE_ADMIN_COST_CODE_IS_REQUIRED,
            payload: value,
        };
    },

    updateAdminCostCodeOnlyFromCostCodeList: function (value) {
        return {
            type: UPDATE_ADMIN_COST_CODE_ONLY_FROM_COST_CODE_LIST,
            payload: value,
        };
    },

    addCostCodeToList: function (value) {
        return {
            type: ADD_COST_CODE_TO_ADMIN_LIST,
            payload: value,
        };
    },

    deleteCostCodeFromList: function (value) {
        return {
            type: DELETE_COST_CODE_FROM_ADMIN_LIST,
            payload: value,
        };
    },

    changeCostCode: function (selectedCostCode) {
        return {
            type: UPDATE_USER_COST_CODE,
            payload: selectedCostCode,
        };
    },

    hideLoginHistoryBanner: function () {
        return {
            type: HIDE_LOGIN_HISTORY_BANNER,
        };
    },

    updateContentTypes: function (contentTypes) {
        return {
            type: UPDATE_CONTENT_TYPES,
            payload: contentTypes,
        };
    },

    updateAdminContentTypes: function (contentTypes) {
        return {
            type: UPDATE_ADMIN_CONTENT_TYPES,
            payload: contentTypes,
        };
    },

    updateIsDirty: function (isDirty) {
        return {
            type: UPDATE_USER_PREFS_IS_DIRTY,
            payload: isDirty,
        };
    },

    updateAdminIsDirty: function (isDirty) {
        return {
            type: UPDATE_ADMIN_PREFS_IS_DIRTY,
            payload: isDirty,
        };
    },

    checkIsDirty: (areSectionsModified, props, prefsUpdatedByAdmin) => (dispatch, getState) => {
        dispatch(
            actions.updateIsDirty(
                areSectionsModified && !isEqual(props, getState().user.preferences) && !prefsUpdatedByAdmin
            )
        );
    },

    updateUserPreferencesCustomNewsQuery: (segment, query, queryName, queryTitle) => (dispatch) => {
        queryName
            ? dispatch(actions.editUserPreferencesCustomNewsQuery(segment, query, queryName, queryTitle))
            : dispatch(actions.addAndUnlockUserPreferencesCustomNewsQuery(segment, query, queryTitle));
    },

    updateUserPreferencesLnCustomNewsQuery: (segment, query, queryName, queryTitle) => (dispatch) => {
        dispatch(actions.editUserPreferencesLNCustomNewsQuery(segment, query, queryName, queryTitle));
    },

    updateAdminUserPreferencesCustomNewsQuery: (segment, query, queryName, queryTitle, userLocks) => (dispatch) => {
        queryName
            ? dispatch(actions.editAdminUserPreferencesCustomNewsQuery(segment, query, queryName, queryTitle))
            : dispatch(actions.addAndUnlockAdminUserPreferencesCustomNewsQuery(segment, query, queryTitle, userLocks));
    },

    addAndUnlockAdminUserPreferencesCustomNewsQuery: (segment, query, queryTitle, userLocks) => (dispatch) => {
        dispatch(actions.addAdminUserPreferencesCustomNewsQuery(segment, query, queryTitle));
        dispatch(actions.updateLocks(userLocks));
    },

    addAndUnlockUserPreferencesCustomNewsQuery: (segment, query, queryTitle, userLocks) => (dispatch) => {
        dispatch(actions.addUserPreferencesCustomNewsQuery(segment, query, queryTitle));
        dispatch(actions.updateLocks(userLocks));
    },

    addUserPreferencesCustomNewsQuery: function (segment, query, queryTitle) {
        return {
            type: ADD_USER_PREFS_CUSTOM_NEWS_QUERY,
            payload: { segment, query, queryTitle },
        };
    },

    addAdminUserPreferencesCustomNewsQuery: function (segment, query, queryTitle) {
        return {
            type: ADD_ADMIN_PREFS_CUSTOM_NEWS_QUERY,
            payload: { segment, query, queryTitle },
        };
    },

    editUserPreferencesCustomNewsQuery: function (segment, query, queryName, queryTitle) {
        return {
            type: EDIT_USER_PREFS_CUSTOM_NEWS_QUERY,
            payload: { segment, query, queryTitle, queryName },
        };
    },

    editUserPreferencesLNCustomNewsQuery: function (segment, query, queryName, queryTitle) {
        return {
            type: EDIT_USER_PREFS_LN_CUSTOM_NEWS_QUERY,
            payload: { segment, query, queryTitle, queryName },
        };
    },

    editAdminUserPreferencesCustomNewsQuery: function (segment, query, queryName, queryTitle) {
        return {
            type: EDIT_ADMIN_PREFS_CUSTOM_NEWS_QUERY,
            payload: { segment, query, queryTitle, queryName },
        };
    },

    updateUserPreferencesPayload: (newPayload) => ({
        type: UPDATE_USER_PREFERENCES_PAYLOAD,
        payload: newPayload,
    }),

    updateUserPreferencesPayloadAndRetrievalTimestamp: (newPayload) => (dispatch) => {
        dispatch(actions.updateUserPreferencesPayload(newPayload));
        dispatch(actions.updateLastPreferencesRetrievalTimestamp(Date.now()));
    },

    updateCompanySourcesSearchOption: function (option, isAdminUser) {
        let type =
            isAdminUser !== undefined
                ? UPDATE_ADMIN_COMPANY_SOURCES_SEARCH_OPTION
                : UPDATE_COMPANY_SOURCES_SEARCH_OPTION;

        return {
            type: type,
            payload: { option },
        };
    },

    updateSearchNamePartyEntity: function (option, isAdminUser) {
        let type =
            isAdminUser !== undefined
                ? UPDATE_ADMIN_LEGAL_SEARCH_NAME_PARTY_ENTITY
                : UPDATE_LEGAL_SEARCH_NAME_PARTY_ENTITY;

        return {
            type: type,
            payload: { option },
        };
    },

    updateUserPreference: function (preference, value) {
        return {
            type: USER_UPDATE_PREFERENCE,
            payload: { preference, value },
        };
    },

    toggleDeliveryOptionsDisplayPreference: function (status, key) {
        return {
            type: TOGGLE_DELIVERY_OPTIONS_DISPLAY_PREFERENCE,
            payload: { status, key },
        };
    },

    updateModifiedByUser: function (status, key) {
        return {
            type: TOGGLE_MODIFIED_BY_USER,
            payload: { status, key },
        };
    },

    updateShowDocumentOnNewPage: function (key, checked) {
        return {
            type: USER_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE,
            payload: { key, checked },
        };
    },

    adminUpdateShowDocumentOnNewPage: function (key, checked) {
        return {
            type: ADMIN_UPDATE_SHOW_DOCUMENT_ON_NEW_PAGE,
            payload: { key, checked },
        };
    },

    adminUpdateContentOptions: (key, checked) => (dispatch) => {
        dispatch({
            type: ADMIN_UPDATE_CONTENT_OPTIONS_REPORT_BUILDER,
            payload: {
                key,
                checked,
            },
        });

        dispatch({
            type: ADMIN_UPDATE_CONTENT_OPTIONS_RESULTS,
            payload: {
                key,
                checked,
            },
        });
    },

    updateContentOptions: (key, checked) => (dispatch) => {
        dispatch({
            type: UPDATE_CONTENT_OPTIONS_REPORT_BUILDER,
            payload: {
                key,
                checked,
            },
        });

        dispatch({
            type: UPDATE_CONTENT_OPTIONS_RESULTS,
            payload: {
                key,
                checked,
            },
        });
    },

    updateExcludeNonBusinessNews: function (isExcludeNonBusinessNews) {
        return {
            type: UPDATE_EXCLUDE_NON_BUSINESS_NEWS,
            payload: isExcludeNonBusinessNews,
        };
    },

    adminUpdateExcludeNonBusinessNews: function (isExcludeNonBusinessNews) {
        return {
            type: ADMIN_UPDATE_EXCLUDE_NON_BUSINESS_NEWS,
            payload: isExcludeNonBusinessNews,
        };
    },

    updateExcludeNewsWires: function (isExcludeNewsWires) {
        return {
            type: UPDATE_EXCLUDE_NEWS_WIRES,
            payload: isExcludeNewsWires,
        };
    },

    adminUpdateExcludeNewsWires: function (isExcludeNewsWires) {
        return {
            type: ADMIN_UPDATE_EXCLUDE_NEWS_WIRES,
            payload: isExcludeNewsWires,
        };
    },

    updateUseNewResearchSummary: (status) => {
        return {
            type: TOGGLE_USE_NEW_RESEARCH_SUMMARY,
            payload: status,
        };
    },

    updateToggleNegativeNewsVisualisation: function (isTrendsExpanded) {
        return {
            type: USER_UPDATE_TOGGLE_NEGATIVE_NEWS_VISUALISATION,
            payload: isTrendsExpanded,
        };
    },

    updateFilterSectionVisibility: (isFilterSectionCollapsed) => ({
        type: UPDATE_FILTER_SECTION_VISIBILITY,
        payload: isFilterSectionCollapsed,
    }),

    updateStylingTheme: function (isDarkMode) {
        return {
            type: UPDATE_STYLING_THEME,
            payload: isDarkMode,
        };
    },

    updateRiskScores: function (riskScore) {
        return {
            type: UPDATE_RISK_SCORES,
            payload: riskScore,
        };
    },

    updateBatchContentTypesVisibility: (isBatchContentTypesCollapsed) => {
        return {
            type: UPDATE_BATCH_CONTENT_TYPES_VISIBILITY,
            payload: isBatchContentTypesCollapsed,
        };
    },

    updateEntityViewPreferences: (screeningEntity) => {
        return {
            type: UPDATE_BATCH_SCREEN_PREFS,
            payload: screeningEntity,
        };
    },

    updateMaxDocumentsPerCategoryInReport: function (value) {
        return {
            type: USER_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT,
            payload: value,
        };
    },

    adminUpdateMaxDocumentsPerCategoryInReport: function (value) {
        return {
            type: ADMIN_UPDATE_MAX_DOCUMENTS_PER_CATEGORY_IN_REPORT,
            payload: value,
        };
    },

    updateExpandedFiltersSection: (expandedFiltersSection) => {
        return {
            type: UPDATE_EXPANDED_FILTER_SECTION,
            payload: expandedFiltersSection
        };
    },

    updateSanctionsSourcesRisks: (sanctionsSourcesRisks) => {
        return {
            type: UPDATE_SANCTIONS_SOURCES_RISKS,
            payload: sanctionsSourcesRisks
        };
    },

    adminUpdateSanctionsSourcesRisks: (sanctionsSourcesRisks) => {
        return {
            type: ADMIN_UPDATE_SANCTIONS_SOURCES_RISKS,
            payload: sanctionsSourcesRisks
        };
    },

    preferencesUpdateExcludeLowQualityDocuments: (excludeLowQualityDocuments) => {
        return {
            type: PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS,
            payload: excludeLowQualityDocuments
        };
    },

    adminPreferencesUpdateExcludeLowQualityDocuments: (excludeLowQualityDocuments) => {
        return {
            type: ADMIN_PREFERENCES_UPDATE_EXCLUDE_LOW_QUALITY_DOCUMENTS,
            payload: excludeLowQualityDocuments
        };
    },
};

export default actions;
