import React, { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import NotificationsContainerLayout from '@scripts/reusable/NotificationsContainerLayout/NotificationsContainerLayout';
import Notification from '@scripts/reusable/Notification/Notification';
import mainActions from '@scripts/pages/Main/Main.actions';
import { ROUTES } from '@scripts/constants';

/*
!!! CONFIGURATION
    ** here we mappped the notification type to the route
    ** the polling function will receive the route where the notification will be displayed to know when polling is done
       to reset the notification status if the route is different
*/
const MapNotificationsReduxKeyToPage = {
    [ROUTES.MANAGE_ALERTS]: ['deleteAlertsStatus', 'uploadEntitiesFromAlertsStatus'],
    [ROUTES.SCREENING]: ['createAlertsStatus', 'copyEntitiesStatus', 'deleteEntitiesStatus', 'refreshEntitiesStatus', 'uploadEntitiesStatus', 'downloadEntitiesStatus', 'uploadEntitiesFromAlertsStatus'],
};

const NotificationsContainer = memo(({ route = ROUTES.SCREENING, notificationsNames=[] }) => {
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        //filter the notifications based on the route
        const pageNotifications = notificationsNames.filter((notificationType) => MapNotificationsReduxKeyToPage[route].includes(notificationType));
        setNotifications(pageNotifications);
    }, [route, notificationsNames.length]);

    const clearAll = (route) => dispatch(mainActions.closeAllNotifications({ notificationsToClose: MapNotificationsReduxKeyToPage[route] }));

    return (
        <div data-testid="notification-container"> 
            {notifications?.length ? (
                <NotificationsContainerLayout
                    clearAll={() => clearAll(route)}
                    isSingleNotification={notificationsNames.length === 1}
                >
                    {
                        notifications.map(notificationKeyStore => (
                            <Notification
                                    key={`notification-${notificationKeyStore}-container`}
                                    id={`notification-${notificationKeyStore}-container`}
                                    connectionToStore={notificationKeyStore}
                                />
                        ))
                    }
                </NotificationsContainerLayout>
            ) : <></>}
        </div>
    )
});

export default NotificationsContainer;