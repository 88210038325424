/**
 *  THE REDUX STORE
 *
 *  The store also has the thunk and logger middleware applied. The thunk
 *  middleware allows action generators to return functions that have a dispatch
 *  parameter sent by Redux.
 *
 *  @version 0.1
 */

import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';

import baseReducer from './pages/Main/Main.reducers.js';
import {
    ALERT_TYPE,
    CATEGORY_NAMES,
    CATEGORY_TYPE_REGULAR,
    CATEGORY_TYPE_UBO,
    CATEGORY_TYPE_UBO_CHILD,
    COMPANY_SEARCH,
    DOC_TYPE,
    FILTER_INFO,
    FILTER_TYPES_CONSTANTS,
    PERSON_SEARCH,
    POLLING_MAX_CONSECUTIVE_UNCHANGED_RESPONSES,
    POLLING_STATUS,
    POST_FILTERS_DEFAULT_SORT,
    POSTFILTER_COMPONENTS,
    SORT_OPTIONS,
    UBO_DEFAULT_SORTING_AND_FILTERING,
    UBO_POST_FILTERS,
} from './constants';
import { GENERAL_SETTINGS } from './pages/Main/Main.reducers';
import { union } from 'lodash';
import applyAllPolyfills from './utils/polyfills';
import { getUboCategoryType } from './sagas/helpers/uboHelper';
import utils from './utils/utilities';
import categoryUtils from './utils/categoryUtils';

// Apply all necessary polyfills for the app to work correctly
applyAllPolyfills();

export function resetCategory(
    categoryName,
    enabled = true,
    available = true,
    sortOption = SORT_OPTIONS.DEFAULT_SORT_OPTION
) {
    let type = getUboCategoryType(categoryName) || CATEGORY_TYPE_REGULAR;

    // TODO set "loaded" back to false
    // Currently set it to true as default, for ESG Ratings
    return {
        articles: [],
        count: 0,
        loadedArticles: [],
        currentPageIndex: 0,
        hasMore: true,
        loaded: false,
        enabled: enabled,
        available: available,
        name: categoryName,
        markedInHistory: false,
        lastSort: sortOption,
        categoryLastUpdated: null,
        type,
        postFilters: {
            searchQuery: '',
            searchQueryType: '',
            includeTerms: [],
            excludeTerms: [],
            sort: sortOption,
        },
    };
}

export function resetUboCategory(categoryName, enabled = true, isChild = false) {
    return {
        ...resetCategory(categoryName, enabled),
        type: isChild ? CATEGORY_TYPE_UBO_CHILD : CATEGORY_TYPE_UBO,
    };
}

function buildPostFilterTemplate(settings) {
    let postFilterType = settings.type;
    let postFilterSort = POST_FILTERS_DEFAULT_SORT[postFilterType];
    let defaultSettings = {
        visibleItems: 20,
        defaultValue: '',
        values: [],
        collapsed: true,
        displayInQueryType: '',
        sort: postFilterSort ? postFilterSort : null,
    };

    let postFilter = Object.assign({}, settings, defaultSettings);
    return postFilter;
}

//update postFilter filter field used by search;
//depending on each content type field used for postFilter differ, exp: for news, the filter field used for SOURCE is publicationName
function setFilterInfoForPostFilterComponent(postFilterType, searchFilterName) {
    postFilterType.searchFieldName = searchFilterName;
    return postFilterType;
}

function buildPostFilterComponents(searchQueryType, category, componentsSettingsList) {
    let components = {};
    if (!Array.isArray(componentsSettingsList)) {
        return components;
    }

    componentsSettingsList.forEach((settings) => {
        if (shouldBuildPostFilter(searchQueryType, category, settings)) {
            components[settings.type] = buildPostFilterTemplate(settings);
        }
    });
    return components;
}
function shouldBuildPostFilter(searchQueryType, category, settings) {
    if (
        settings.specialDisplaySettings &&
        settings.specialDisplaySettings.category === category &&
        settings.specialDisplaySettings.displayInSearchType !== searchQueryType
    ) {
        return false;
    }

    if (searchQueryType === COMPANY_SEARCH && utils.isPersonSearch(settings.displayInQueryType)) {
        return false;
    }

    if (searchQueryType === PERSON_SEARCH && settings.displayInQueryType === COMPANY_SEARCH) {
        return false;
    }

    return true;
}

function buildNewsPostFilterComponents(
    searchQueryType,
    category,
    subjectAndIndustryAvailable,
    newFuzzyEnabled,
    suggestedNamesEnabled
) {
    let subjectAndIndustryPostFilters = subjectAndIndustryAvailable
        ? [
              setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SUBJECT, FILTER_INFO.SUBJECT),
              setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.INDUSTRY, FILTER_INFO.INDUSTRY),
          ]
        : [];
    let fuzzySearchPostFilter =
        newFuzzyEnabled && utils.isPersonSearch(searchQueryType)
            ? [setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.FUZZY_SEARCH, FILTER_INFO.FUZZY_SEARCH)]
            : [];

    let suggestedNamesPostFilter =
        suggestedNamesEnabled && utils.isPersonSearch(searchQueryType)
            ? [setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SUGGESTED_NAMES, FILTER_INFO.SUGGESTED_NAMES)]
            : [];
    const negativityLevelPostFilter =
        typeof categoryUtils !== 'undefined' && categoryUtils.isChildOf(CATEGORY_NAMES.NEGATIVE_NEWS, category)
            ? [
                  setFilterInfoForPostFilterComponent(
                      POSTFILTER_COMPONENTS.NEGATIVITY_LEVELS,
                      FILTER_INFO.NEGATIVITY_LEVELS
                  ),
              ]
            : [];

    let componentsSettingsList = [
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.ESG_FACTORS, FILTER_INFO.ESG_FACTORS),
        ...fuzzySearchPostFilter,
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
        ...suggestedNamesPostFilter,
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.COMPANY_MENTIONS, FILTER_INFO.COMPANY_MENTIONS),
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.PERSON_MENTIONS, FILTER_INFO.PERSON_MENTIONS),
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.COMPANY, FILTER_INFO.COMPANY),
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.PROXIMITY_NEGATIVE_TERMS, FILTER_INFO.PROXIMITY),
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.PUBLICATION_NAME),
        //@TODO merge 109 and 110 when changing publicationName to sourceName for NegativeNews for all Languages into setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.SOURCE_NAME), and remove all instances of the POSTFILTER_TYPE.SOURCE_NAME
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE_NAME, FILTER_INFO.SOURCE_NAME),
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.LANGUAGE, FILTER_INFO.LANGUAGE),
        setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.GEOGRAPHY, FILTER_INFO.GEOGRAPHY),
        ...subjectAndIndustryPostFilters,
        ...negativityLevelPostFilter,
    ];

    return buildPostFilterComponents(searchQueryType, category, componentsSettingsList);
}

function buildUboPostFilterComponents(searchType) {
    const postFilterList = union(...Object.values(UBO_POST_FILTERS));

    const componentList = postFilterList
        .filter((item) => item.searchFieldName)
        .map((postFilter) => setFilterInfoForPostFilterComponent(postFilter, postFilter.searchFieldName));

    return buildPostFilterComponents(searchType, CATEGORY_NAMES.DNB, componentList);
}

function buildLegalPostFilterComponents(
    searchQueryType,
    category,
    subjectAndIndustryAvailable,
    newFuzzyEnabled,
    suggestedNamesEnabled
) {
    let componentsSettingsList = [];
    let subjectAndIndustryPostFilters = subjectAndIndustryAvailable
        ? [
              setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SUBJECT, FILTER_INFO.SUBJECT),
              setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.INDUSTRY, FILTER_INFO.INDUSTRY),
          ]
        : [];
    let fuzzySearchPostFilter =
        newFuzzyEnabled && utils.isPersonSearch(searchQueryType)
            ? [setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.FUZZY_SEARCH, FILTER_INFO.FUZZY_SEARCH)]
            : [];

    let suggestedNamesPostFilter =
        suggestedNamesEnabled && utils.isPersonSearch(searchQueryType)
            ? [setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SUGGESTED_NAMES, FILTER_INFO.SUGGESTED_NAMES)]
            : [];

    if (category === CATEGORY_NAMES.LAW_REVIEWS) {
        componentsSettingsList = [
            ...fuzzySearchPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
            ...suggestedNamesPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.COMPANY, FILTER_INFO.COMPANY),
            POSTFILTER_COMPONENTS.GEOGRAPHY,
            ...subjectAndIndustryPostFilters,
        ];
    } else {
        componentsSettingsList = [
            ...fuzzySearchPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
            ...suggestedNamesPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.SOURCE_NAME),
        ];

        if (category === CATEGORY_NAMES.FEDERAL_DOCKETS || category === CATEGORY_NAMES.STATE_DOCKETS) {
            componentsSettingsList = [
                ...componentsSettingsList,
                setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DOCKET_STATUS, FILTER_INFO.DOCKET_STATUS),
            ];
        }

        componentsSettingsList = [...componentsSettingsList, POSTFILTER_COMPONENTS.GEOGRAPHY];

        if (category === CATEGORY_NAMES.AGENCY_DECISION) {
            componentsSettingsList = [...componentsSettingsList, ...subjectAndIndustryPostFilters];
        }
    }

    return buildPostFilterComponents(searchQueryType, category, componentsSettingsList);
}

export function generateDefaultPostFilterConfig(
    searchType,
    subjectAndIndustryAvailable = false,
    newFuzzyEnabled = false,
    suggestedNamesEnabled = false
) {
    let subjectAndIndustryPostFilters = subjectAndIndustryAvailable
        ? [
              setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SUBJECT, FILTER_INFO.SUBJECT),
              setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.INDUSTRY, FILTER_INFO.INDUSTRY),
          ]
        : [];
    let fuzzySearchPostFilter =
        newFuzzyEnabled && utils.isPersonSearch(searchType)
            ? [setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.FUZZY_SEARCH, FILTER_INFO.FUZZY_SEARCH)]
            : [];

    let suggestedNamesPostFilter =
        suggestedNamesEnabled && utils.isPersonSearch(searchType)
            ? [setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SUGGESTED_NAMES, FILTER_INFO.SUGGESTED_NAMES)]
            : [];

    return {
        sanctions: buildPostFilterComponents(searchType, CATEGORY_NAMES.SANCTIONS_WATCHLIST, [
            ...fuzzySearchPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
            ...suggestedNamesPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.CONTENT_TYPE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.LANGUAGE, FILTER_INFO.LANGUAGE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
        ]),
        ubo: buildUboPostFilterComponents(),
        peps: buildPostFilterComponents(searchType, CATEGORY_NAMES.PEPS, [
            ...fuzzySearchPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
            ...suggestedNamesPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.CONTENT_TYPE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
            ...subjectAndIndustryPostFilters,
        ]),
        biographical: buildPostFilterComponents(searchType, CATEGORY_NAMES.BIOGRAPHICAL, [
            ...fuzzySearchPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
            ...suggestedNamesPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.PUBLICATION_NAME),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.COMPANY, FILTER_INFO.COMPANY),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.LANGUAGE, FILTER_INFO.LANGUAGE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
            ...subjectAndIndustryPostFilters,
        ]),
        directors: buildPostFilterComponents(searchType, CATEGORY_NAMES.COMPANY_RESOURCES, [
            ...fuzzySearchPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
            ...suggestedNamesPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.COMPANY, FILTER_INFO.COMPANY),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE_TYPE, FILTER_INFO.PUBLICATION_TYPE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.PUBLICATION_NAME),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.LANGUAGE, FILTER_INFO.LANGUAGE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.GEOGRAPHY, FILTER_INFO.GEOGRAPHY),
            ...subjectAndIndustryPostFilters,
        ]),
        agencyDecision: buildLegalPostFilterComponents(
            searchType,
            CATEGORY_NAMES.AGENCY_DECISION,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        dockets: buildLegalPostFilterComponents(
            searchType,
            CATEGORY_NAMES.STATE_DOCKETS,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        federalDockets: buildLegalPostFilterComponents(
            searchType,
            CATEGORY_NAMES.FEDERAL_DOCKETS,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        lawReviews: buildLegalPostFilterComponents(
            searchType,
            CATEGORY_NAMES.LAW_REVIEWS,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        legal: buildLegalPostFilterComponents(
            searchType,
            CATEGORY_NAMES.CASES,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        verdicts: buildLegalPostFilterComponents(
            searchType,
            CATEGORY_NAMES.VERDICTS,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        financialReports:
            searchType === 'company'
                ? buildPostFilterComponents(searchType, CATEGORY_NAMES.FINANCIAL_REPORT, [
                      setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.PUBLICATION_NAME),
                      setFilterInfoForPostFilterComponent(
                          POSTFILTER_COMPONENTS.DATE_RANGE,
                          FILTER_INFO.PUBLICATION_DATE
                      ),
                      ...subjectAndIndustryPostFilters,
                  ])
                : buildPostFilterComponents(searchType, CATEGORY_NAMES.FINANCIAL_REPORT, [
                      setFilterInfoForPostFilterComponent(
                          POSTFILTER_COMPONENTS.DATE_RANGE,
                          FILTER_INFO.PUBLICATION_DATE
                      ),
                      ...fuzzySearchPostFilter,
                      ...suggestedNamesPostFilter,
                      ...subjectAndIndustryPostFilters,
                  ]),
        news: buildPostFilterComponents(searchType, CATEGORY_NAMES.NEWS, [
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.ESG_FACTORS, FILTER_INFO.ESG_FACTORS),
            ...fuzzySearchPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SIMILAR_NAMES, FILTER_INFO.FUZZY_NAMES),
            ...suggestedNamesPostFilter,
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.DATE_RANGE, FILTER_INFO.PUBLICATION_DATE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.COMPANY_MENTIONS, FILTER_INFO.COMPANY_MENTIONS),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.PERSON_MENTIONS, FILTER_INFO.PERSON_MENTIONS),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.COMPANY, FILTER_INFO.COMPANY),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE, FILTER_INFO.PUBLICATION_NAME),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.SOURCE_NAME, FILTER_INFO.SOURCE_NAME),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.LANGUAGE, FILTER_INFO.LANGUAGE),
            setFilterInfoForPostFilterComponent(POSTFILTER_COMPONENTS.GEOGRAPHY, FILTER_INFO.GEOGRAPHY),
            ...subjectAndIndustryPostFilters,
        ]),
        customNews: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.CUSTOM_NEWS,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        customNews1: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.CUSTOM_NEWS1,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        customNews2: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.CUSTOM_NEWS2,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        customNews3: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.CUSTOM_NEWS3,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        lnCustomNews1: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.LN_CUSTOM_NEWS1,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        adminCustomNews1: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        adminCustomNews2: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        adminCustomNews3: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNews: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsEn: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_EN,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsFr: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_FR,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsNl: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_NL,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsDe: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_DE,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsEs: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_ES,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsTr: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_TR,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsSv: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_SV,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsMs: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_MS,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsPt: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_PT,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsJa: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_JA,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsZh: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_ZH,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsIt: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_IT,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsAr: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_AR,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsRu: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_RU,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
        negativeNewsPl: buildNewsPostFilterComponents(
            searchType,
            CATEGORY_NAMES.NEGATIVE_NEWS_PL,
            subjectAndIndustryAvailable,
            newFuzzyEnabled,
            suggestedNamesEnabled
        ),
    };
}

let initialState = {
    //TODO: remove mock data from general settings
    user: {
        isLoggedIn: false,
        helpUrlRoot: '',
        preferences: {
            language: '',
            languageLastUpdated: null,
            metadataLanguage: '',
            googleTranslateStatus: false,
            screeningEntity: {
                columns: {},
                sorting: {},
                lastSelectedView: 'none',
                isShareable: true,
            },
            maxDocumentsInReportCategory: 200,
            resultListPage: {
                expandedFiltersSection: FILTER_TYPES_CONSTANTS.CONTENT_TYPES
            },
            generalSettings: {
                searchType: 'company',
                generalPageSize: 10,
                alertsOptions: {
                    sorting: {
                        sortBy: '',
                        direction: '',
                    },
                },
                contentTypes: [],
                isFuzzyEnabled: false,
                isFuzzyLoaded: false,
                fuzzyMatch: 'close',
                isFilterSectionCollapsed: false,
                displayRiskScores: 'noChoice',
                legalSources: {},
                showSnapshot: true,
                isBatchContentTypesCollapsed: false,
                activeAlertsLimit: null,
                entityUploadLimit: 0,
                sanctionsSources: {},
                excludeLowQualityDocuments: true,
            },
            contentsOptions: {},
            modifiedByUser: {
                resultsListShowDeliveryOptions: false,
                showDeliveryOptions: false,
            },
            resultsListDeliveryOptions: {},
            resultsListShowDeliveryOptions: true,
            showDeliveryOptions: true,
            personCheck: {
                fuzzyOn: true,
                fuzzyThreshold: 'moderate',
                negativityLevel: ['low'],
            },
            companyCheck: {
                negativityLevel: ['low'],
            },
            payload: {
                userPreferences: {
                    generalSettings: {
                        isTrendsExpanded: false,
                        isDarkMode: false,
                    },
                },
            },
        },
        adminPreferences: {
            isDirty: false,
        },
        appSettings: {
            uboEnabled: false,
            authenticationInformation: {
                wamLogin: '',
            },
            isBatchReportsEnabled: false,
            isMultipleEntitiesTrial: false,
            isSpecifiedCustomer: false,
            shouldDisplayEntityViewMarketingInfo: false,
        },
        useNewResearchSummary: false,
        userOfferings: [],
        useOptimizedSearch: true,
        showEsgRatings: false,
        enforceAlertsLimit: false,
        sanctionsAndWatchListsPreferences: false,
        sanctionsAndWatchListsSnapshot: false,
        screeningEntityShareViewEnabled: false,
        screeningEntityCommentsLimit: 50,
        diligencePlusMarketingOverlay: false,
        useReactRouterVersion6: false,
        maxNumberOfConsecutiveCallsForPolling: POLLING_MAX_CONSECUTIVE_UNCHANGED_RESPONSES,
    },

    header: {
        menuStateClass: '',
        subMenuMessage: '',
        helpUrl: '',
        version: '-',
    },

    spinners: {
        /* The main spinner is the only one whose value is a number
         * (a counter of how many main spinner calls have been made).
         */
        mainSpinner: { count: 0 },
        fullPageSpinner: false,
    },

    popupMessages: {
        popupTitle: null,
        popupCount: 0,
        messages: [],
    },

    searchStatus: {
        isSearchComplete: true,
        areDocumentsLoading: false, //at the moment, this property is updated only for the first article load on results list
        isSavingSearch: false,
        isAddingToEntityView: false,
        showSaveSearchModalBeforeLeave: false,
        isMultipleEntitiesPageSpinnerVisible: false,
        arePostFiltersChanged: false,
    },

    searchState: {
        launchedFrom: null,
        entityId: null,
        allEntitiesSelected: false,
        shouldUpdateEntitiesColumnsFromContentTypes: false,
        snapshotDelivery: null,
    },

    breadcrumbs: {
        prevPath: '/',
        items: [],
        query: {},
    },

    backgroundMessages: {
        isVisible: false,
        title: null,
        message: null,
        icon: null,
        timeout: null,
        close: false,
        shrink: false,
        link: null,
        buttons: false,
        messageParameters: '',
        actionLink: '',
        type: '',
    },

    searchParams: {
        query: '',
        searchType: PERSON_SEARCH,
        prefilterQuery: '',
        isCustomFuzzy: false,
        category: '',
        shardPreference: {
            shardPreference: '',
            lastCategoryGenerated: '',
        },
    },

    searchResults: {
        sanctions: resetCategory(CATEGORY_NAMES.SANCTIONS_WATCHLIST),
        peps: resetCategory(CATEGORY_NAMES.PEPS),
        agencyDecision: resetCategory(CATEGORY_NAMES.AGENCY_DECISION),
        biographical: resetCategory(CATEGORY_NAMES.BIOGRAPHICAL),
        directors: resetCategory(CATEGORY_NAMES.COMPANY_RESOURCES),
        dockets: resetCategory(CATEGORY_NAMES.STATE_DOCKETS),
        federalDockets: resetCategory(CATEGORY_NAMES.FEDERAL_DOCKETS),
        lawReviews: resetCategory(CATEGORY_NAMES.LAW_REVIEWS),
        legal: resetCategory(CATEGORY_NAMES.CASES),
        verdicts: resetCategory(CATEGORY_NAMES.VERDICTS),
        news: resetCategory(CATEGORY_NAMES.NEWS),
        financialReports: resetCategory(CATEGORY_NAMES.FINANCIAL_REPORT),
        negativeNews: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS),
        negativeNewsEn: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_EN),
        negativeNewsFr: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_FR),
        negativeNewsPt: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_PT),
        negativeNewsDe: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_DE),
        negativeNewsNl: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_NL),
        negativeNewsIt: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_IT),
        negativeNewsEs: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_ES),
        negativeNewsTr: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_TR),
        negativeNewsSv: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_SV),
        negativeNewsMs: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_MS),
        negativeNewsZh: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_ZH),
        negativeNewsAr: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_AR),
        negativeNewsRu: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_RU),
        negativeNewsPl: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_PL),
        negativeNewsJa: resetCategory(CATEGORY_NAMES.NEGATIVE_NEWS_JA),
        lawSources: resetCategory(CATEGORY_NAMES.LAW_SOURCES),
        publicRecords: resetCategory(CATEGORY_NAMES.PUBLIC_RECORDS),
        customNews: resetCategory(CATEGORY_NAMES.CUSTOM_NEWS),
        customNews1: resetCategory(CATEGORY_NAMES.CUSTOM_NEWS1),
        customNews2: resetCategory(CATEGORY_NAMES.CUSTOM_NEWS2),
        customNews3: resetCategory(CATEGORY_NAMES.CUSTOM_NEWS3),
        lnCustomNews1: resetCategory(CATEGORY_NAMES.LN_CUSTOM_NEWS1),
        adminCustomNews1: resetCategory(CATEGORY_NAMES.ADMIN_CUSTOM_NEWS1),
        adminCustomNews2: resetCategory(CATEGORY_NAMES.ADMIN_CUSTOM_NEWS2),
        adminCustomNews3: resetCategory(CATEGORY_NAMES.ADMIN_CUSTOM_NEWS3),
        ubo: resetCategory(CATEGORY_NAMES.DNB),
        esgRatings: resetCategory(CATEGORY_NAMES.ESG_RATINGS),
    },

    editSearch: {},

    currentReport: {
        reportId: null,
        investigationId: null,
        articlesSnippets: [],
    },

    reportBuilder: {
        reports: [],
        singleArticle: {},
        loadingStatus: false,
        selectedReport: {},
        totalReportsCount: 0,
        currentPage: 0,
        batchReportsPooling: [],
        lastBatchReportStatus: {},
        pollingStatus: POLLING_STATUS.NOT_STARTED,
    },

    articlesManager: {
        selectedArticle: {},
        selectedArticles: {},
        uploadingDocuments: {},
        selectedArticlesInfo: [],
    },

    userPreferences: {
        activeTab: GENERAL_SETTINGS,
    },

    popupModel: {
        isVisible: false,
        title: '',
        buttons: [],
        comments: '',
        docType: DOC_TYPE.PDF,
    },

    scrollingPage: {
        searchPage: 0,
    },

    fuzzyNames: { query: '', list: [] },

    suggestedNames: {
        query: '',
        list: [],
        isSuggestedNamesLoaded: false,
        hasExceededSuggestedNames: false,
        loadedSuggestionsForTerms: {},
    },

    //TODO: add shared alerts here
    alerts: {
        [ALERT_TYPE.MY_ALERT]: {
            count: 0,
            list: [],
            currentPageIndex: 0,
            generalPageSize: 50,
            filterAlertName: '',
            isNewAlert: false, // used for custom breadcrumbs on manage-alerts when user creates an alert successfully.
            alertsAreLoaded: false,
        },

        [ALERT_TYPE.PUBLIC_RECORD_ALERT]: {
            count: 0,
            list: [],
            generalPageSize: 50,
            currentPageIndex: 0,
            filterAlertName: '',
            alertsAreLoaded: false,
        },
        alertsCount: 0,
        activeAlertsCount: 0,
        publicRecordsCount: 0,
        isDownloadAlertsButtonDisabled: false,
        pollingStatus: POLLING_STATUS.NOT_STARTED,
    },

    postFilterConfiguration: generateDefaultPostFilterConfig(PERSON_SEARCH),

    adHocSearch: {
        newsSearchSources: 'All News, All Languages',
    },

    searchBar: { sourcesDropdown: { sources: [] } },

    selectedAlerts: {
        [ALERT_TYPE.MY_ALERT]: [],
        [ALERT_TYPE.PUBLIC_RECORD_ALERT]: [],
        [ALERT_TYPE.SHARED_ALERT]: [],
    },

    historyCategory: '',

    investigation: {
        billingId: null,
        id: null,
        visitedContentTypes: {},
        //@TODO this part of the structure will be covered in a future ticket
        // report: {}
    },

    metrics: {
        timeStart: null,
        timeEnd: {},
    },

    articleNavigation: {
        index: null,
        list: [],
        startPage: null,
        pageSize: null,
        totalCount: null,
        params: {},
        isShowMoreActive: false,
    },

    ubo: {
        docViewDeliveryInfo: UBO_DEFAULT_SORTING_AND_FILTERING,
        company: {
            list: [],
            isLoading: false,
            count: 0,
            selected: [],
            query: '',
            memo: {},
            searchTerm: '',
            postFilters: {},
        },
    },

    negativeNewsVisualisation: {
        negativityRisk: {
            overallRiskLevel: '',
            documentsCount: {
                high: 0,
                medium: 0,
                low: 0,
            },
        },
        topNegativeTerms: [],
    },

    sanctionsRisk: {
        count: 0,
        riskScore: null,
        documents: [],
        sendFeatureFunctionAccessEvent: true,
    },

    entityViewInformation: {
        entitiesCountNeedsUpdate: [],
        entities: [],
        entitiesCount: 0,
        currentEntity: {},
        uploadPollingActive: false,
        downloadReportsPollingActive: false,
        tableHasData: false,
        batchQueue: [],
        blockedEntities: [],
        shouldGetAllViews: false,
        entitiesProcessingStatus: {},
        entitiesIdQueue: []
    },
    notificationStatus: {
        copyEntitiesStatus: {
            batchIdsList:{},
            hidden: true,
        },
        createAlertsStatus: {
            batchIdsList:{},
            hidden: true,
        },
        deleteAlertsStatus: {
            batchIdsList:{},
            hidden: true,
        },
        deleteEntitiesStatus: {
            batchIdsList:{},
            hidden: true,
        },
        refreshEntitiesStatus: {
            batchIdsList:{},
            hidden: true,
        },
        uploadEntitiesStatus: {
            batchIdsList:{},
            hidden: true,
        },
        uploadEntitiesFromAlertsStatus: {
            batchIdsList:{},
            hidden: true,
        },
        downloadEntitiesStatus: {
            batchIdsList:{},
            hidden: true,
        },
    },
    notificationDisplayOrder: {
        order: [
            'createAlertsStatus',
            'copyEntitiesStatus',
            'deleteEntitiesStatus',
            'uploadEntitiesStatus',
            'refreshEntitiesStatus',
            'uploadEntitiesFromAlertsStatus',
            'deleteAlertsStatus',
        ]
    },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const reduxStore = createStore(
    baseReducer,
    initialState,

    composeEnhancers(applyMiddleware(thunk), applyMiddleware(sagaMiddleware))
);
export const action = (type) => reduxStore.dispatch({ type });

sagaMiddleware.run(rootSaga);

export default reduxStore;
